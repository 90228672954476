import React, { useState, useEffect } from 'react';
import axios from '../../util/Axios/Axios';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import { TableContainer, Table, TableRow, TableCell, Paper, TableHead, TableBody, Select, TextField, makeStyles, FormControl, InputLabel, MenuItem, Grid, Button, TablePagination } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import CandidatesAttendanceTestRow from './CandidatesAttendanceTestRow';
import CandidatesExportButton from '../../components/buttons/CandidatesExportButton';
import { OPEN_SURVEY_PAGE } from '../../store/actions/generalActions';

function CandidatesAttendanceTestView(props) {

  const useStyles = makeStyles(theme => ({
    filterInput : {
      width: "80%",
      margin: "auto",
      marginTop : '16px',
      marginBottom : '16px',
      height: "45px",
      textAlign : 'center',
    },
    gridCell : {
      display : "flex",
      alignItems : "center",
      justifyContent : "center"
    },
  }));

  const classes = useStyles();


  const filterCandidates = (name, phone, status, slot, email) => {
    axios.post("/candidates/attendance-test", {
      name : name === "" ? null : name,
      phone : phone === "" ? null : phone,
      season_id : props.season,
      attendance_slot_id : slot,
      email : email === "" ? null : email,
      status
    })
    .then(response => {
      setCandidates(response.data.data);
      setPage(0);
    })
  }

  const [candidates, setCandidates] = useState([]);
  const [filteredName, setFilteredName] = useState("");
  const [filteredPhone, setFilteredPhone] = useState("");
  const [filteredStatus, setFilteredStatus] = useState("");
  const [filteredSlot, setFilteredSlot] = useState("");
  const [filteredEmail, setFilteredEmail] = useState("");
  const [slots, setSlots] = useState([]);
  const statuses = useSelector(state => state.loginReducer.candidatesStatuses);
  const [season, setSeason] = useState(null); 
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 50));
    setPage(0);
  };

  useEffect(()=> {
      filterCandidates(filteredName, filteredPhone, filteredStatus, filteredSlot, filteredEmail);
      axios.get('/seasons/'+props.season+'/attendance/slots').then ( response => setSlots(response.data.data));
      axios.get("/seasons/"+props.season).then(response => setSeason(response.data.data));
      //eslint-disable-next-line
  }, [props.season])

  const handleGrading = () => {
    axios.post("/candidates/attendance-test/grade")
    .then(response => {
      filterCandidates(filteredName, filteredPhone, filteredStatus, filteredSlot, filteredEmail);
      axios.get("/seasons/"+props.season).then(response => setSeason(response.data.data));
    })
  }

  const handleQualifying = () => {
    axios.post("/candidates/attendance-test/qualify")
    .then(response => {
      filterCandidates(filteredName, filteredPhone, filteredStatus, filteredSlot, filteredEmail);
      axios.get("/seasons/"+props.season).then(response => setSeason(response.data.data));
    })
  }

  return (
    <Aux>
    <Paper>
      <Grid container>
        <Grid item xs={12} className={classes.gridCell} >
          <Button
                variant="contained" 
                color="primary"
                onClick={()=>dispatch({type : OPEN_SURVEY_PAGE})}>
                Преглед на начален въпросник за кандидатите
          </Button>
        </Grid>  
        <Grid item xs={3} className={classes.gridCell} >
          <Button
                variant="contained" 
                color="primary"
                onClick={handleGrading}>
                Изчисли класиране от теста
          </Button>
        </Grid>
        <Grid item xs={3} className={classes.gridCell} >
        <h4>Завършили теста: {season ? season.candidates_attendance_test : 'N/A'} кандидата</h4>
        </Grid>    
        <Grid item xs={3} className={classes.gridCell} >
        <h4>Места за интервю: {season ? season.attendance_test_qualifiers : 'N/A'}</h4>
        </Grid> 
        <Grid item xs={3} className={classes.gridCell} >
          <Button
                variant="contained" 
                color="primary"
                onClick={handleQualifying}>
                Допусни до интервю
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <hr></hr>
        </Grid>
        <Grid item xs={4} className={classes.gridCell} >
          <TextField
              label="Име"
              className = {classes.filterInput}
              value={filteredName}
              onChange={(event)=> {setFilteredName(event.target.value); filterCandidates(event.target.value, filteredPhone, filteredStatus, filteredSlot, filteredEmail)}}
              />
        </Grid>
        <Grid item xs={4} className={classes.gridCell} >
          <TextField
              label="Имейл"
              className = {classes.filterInput}
              value={filteredEmail}
              onChange={(event)=> {setFilteredEmail(event.target.value); filterCandidates(filteredName, filteredPhone, filteredStatus, filteredSlot, event.target.value)}}
              />
        </Grid>
        <Grid item xs={4} className={classes.gridCell} >
          <TextField
              label="Телефон"
              className = {classes.filterInput}
              value={filteredPhone}
              onChange={(event)=> {setFilteredPhone(event.target.value); filterCandidates(filteredName, event.target.value, filteredStatus, filteredSlot, filteredEmail)}}
              />
        </Grid>
        <Grid item xs={4} className={classes.gridCell} >
          <FormControl className={classes.filterInput}>
            <InputLabel>Статус</InputLabel>
            <Select
                value={filteredStatus}
                onChange={(event)=>{setFilteredStatus(event.target.value); filterCandidates(filteredName, filteredPhone, event.target.value, filteredSlot, filteredEmail)}}
            >
              <MenuItem value={""}>Всички</MenuItem>
              {
                statuses.map((status, key) => (
                  key === 4 || key === 5 ?
                  <MenuItem key={status} value={status}>{status}</MenuItem>
                  : null
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} className={classes.gridCell} >
          <FormControl className={classes.filterInput}>
            <InputLabel>Слот</InputLabel>
            <Select
                value={filteredSlot}
                onChange={(event)=>{setFilteredSlot(event.target.value); filterCandidates(filteredName, filteredPhone, filteredStatus, event.target.value, filteredEmail)}}
            >
              <MenuItem value={""}>Всички</MenuItem>
              <MenuItem value={-1}>Без избран слот</MenuItem>
              {
                slots.map((slot) => (
                  <MenuItem key={slot.id} value={slot.id}>{slot.date} (общо {slot.max_slots} места)</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} className={classes.gridCell} >
          <FormControl className={classes.filterInput}>
            <CandidatesExportButton
              candidates = {candidates}
              fileName = {"Присъствен_тест_"+ new Date().toLocaleDateString("en-GB")}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
    <TableContainer component={Paper}>
        <Table aria-label="simple table" size="small">
            <TableHead>
            <TableRow>
                <TableCell style={{fontWeight : "bold"}}>Общо: {candidates.length} кандидата</TableCell>
                <TableCell>Имена</TableCell>
                <TableCell>Имейл</TableCell>
                <TableCell>Възраст</TableCell>
                <TableCell>Телефон</TableCell>
                <TableCell>Резултат онлайн тест</TableCell>
                <TableCell>Резултат присъствен тест</TableCell>
                <TableCell>Статус</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {candidates.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((cand, index) => (
                <CandidatesAttendanceTestRow key={cand.id} candidate={cand} order={(page * rowsPerPage) + index+1} />
            ))}
            </TableBody>
        </Table>
    </TableContainer>
    <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={candidates.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
    </Aux>
  );
}

export default CandidatesAttendanceTestView;
