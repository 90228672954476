import React, { useState } from 'react';
import { Typography, makeStyles, Grid, FormControlLabel, Checkbox, TextField } from '@material-ui/core';
import axios from '../../util/Axios/Axios'
import SimpleModal from '../../components/modals/SimpleModal';
import { GreenButton } from '../../components/buttons/GreenButton';
import Scrollbars from 'react-custom-scrollbars';
import { BASE_URL } from '../../util/Constants';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

function AcceptContractDocumentsModal(props) {

    const useStyles = makeStyles(theme => ({
        root: {
          width: "100%",
          minWidth : "700px",
          margin: "auto",
          display: "flex",
          textAlign : 'center',
          "flex-direction": "column",
          "justify-content" : "flex-start",
    
        },
        formInput: {
          width: "100%",
          margin: "auto",
          marginTop : '16px',
          marginBottom : '16px',
          height: "45px",
          textAlign : 'center',
        },
        gridCell : {
            textAlign :"center" 
        }
    }));
    
    const classes = useStyles();
    const [acceptConrtact, setAcceptContract] = useState(false);
    const [acceptDeclaration, setAcceptDeclaration] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [address, setAddress] = useState("");
    const [licenseId, setLicenseId] = useState("");
    const [licenseDate, setLicenseDate] = useState(new Date(new Date().getFullYear()-10, new Date().getMonth(), new Date().getDate()));

    const acceptDocumentsHandler = () => {
        axios.post("candidates/contracts/documents", {
            first_name : firstName,
            middle_name : middleName,
            last_name : lastName,
            address,
            license_id : licenseId,
            license_date : licenseDate
        }).then(response => props.onClose(true));
    }

    return (
        <SimpleModal open={props.open} onClose={() => props.onClose(false)} >              
            <div className={classes.root}>
                <Scrollbars style={{ height: "80vh" }} renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}> 
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <Typography variant="h5">
                                Документи за прием
                            </Typography>
                        </Grid>
                            <Grid item xs={12} className={classes.gridCell}>
                                <a href={BASE_URL + '/uploads/Declaratsia_dogovor_S12.pdf'} target="_blank" rel="noopener noreferrer">Декларация лични данни</a>
                            </Grid>
                            <Grid item xs={12} className={classes.gridCell}>
                                <FormControlLabel className={classes.checkbox}
                                    control={
                                    <Checkbox
                                        checked={acceptDeclaration}
                                        onChange={(event) => setAcceptDeclaration(event.target.checked)}
                                        color="primary"
                                    />
                                    }
                                    label={<p className={classes.checkboxLabel}>Потвъждавам, че се запознах с декларацията и я приемам </p>}
                                />
                                <hr></hr>
                            </Grid>
                            <Grid item xs={12} className={classes.gridCell}>
                                <a href={BASE_URL + '/uploads/Contract12.pdf'} target="_blank" rel="noopener noreferrer">Договор за обучение</a>
                            </Grid>
                            <Grid item xs={12} className={classes.gridCell}>
                                <FormControlLabel className={classes.checkbox}
                                    control={
                                    <Checkbox
                                        checked={acceptConrtact}
                                        onChange={(event) => setAcceptContract(event.target.checked)}
                                        color="primary"
                                    />
                                    }
                                    label={<p className={classes.checkboxLabel}>Потвъждавам, че се запознах с договора и го приемам </p>}
                                />
                                <hr></hr>
                            </Grid>
                            <Grid item xs={12} >
                                <Typography variant="h5">
                                    Данни от лична карта
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.gridCell}>
                                <TextField
                                    required
                                    value={firstName}
                                    className={classes.formInput}
                                    label="Име"
                                    type = "text"
                                    onChange={(event) => setFirstName(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.gridCell}>
                                <TextField
                                    required
                                    value={middleName}
                                    className={classes.formInput}
                                    label="Презиме"
                                    type = "text"
                                    onChange={(event) => setMiddleName(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.gridCell}>
                                <TextField
                                    required
                                    value={lastName}
                                    className={classes.formInput}
                                    label="Фамилия"
                                    type = "text"
                                    onChange={(event) => setLastName(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.gridCell}>
                                <TextField
                                    required
                                    value={address}
                                    className={classes.formInput}
                                    label="Адрес"
                                    type = "text"
                                    onChange={(event) => setAddress(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.gridCell}>
                                <TextField
                                    required
                                    value={licenseId}
                                    className={classes.formInput}
                                    label="Номер на лична карта"
                                    type = "number"
                                    onChange={(event) => setLicenseId(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.gridCell}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableFuture
                                        openTo="year"
                                        views={["year", "month", "date"]}
                                        margin="normal"
                                        className={classes.formInput}
                                        label="Дата на издаване"
                                        format="dd/MM/yyyy"
                                        onChange={date => setLicenseDate(date)}
                                        value={licenseDate}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        <Grid item xs={12}>
                        <GreenButton
                            disabled = {!acceptConrtact && !acceptDeclaration}
                            className={classes.formInput} 
                            variant="contained" 
                            color="primary"
                            onClick={()=> acceptDocumentsHandler()}
                        >
                            Запази
                        </GreenButton>
                        </Grid>
                    </Grid>
                </Scrollbars>
            </div>
        </SimpleModal>
    );

}

export default AcceptContractDocumentsModal;