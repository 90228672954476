import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';

function DateField(props) {

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <KeyboardDatePicker
        {...props}
        className={props.className}
        margin="normal"
        label={props.label}
        format="dd/MM/yyyy"
        onChange={props.onChange}
        value={props.value}
        KeyboardButtonProps={{
            'aria-label': 'change date',
        }}
        />
  </MuiPickersUtilsProvider>
  )
}

DateField.propTypes = {
    className : PropTypes.any,
    label :     PropTypes.string.isRequired,
    onChange :  PropTypes.func.isRequired,
    value :     PropTypes.any.isRequired
}

export default DateField;
