import React, { useState, useEffect } from 'react';
import axios from '../../util/Axios/Axios';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import { TableContainer, Table, TableRow, TableCell, Paper, TableHead, TableBody, Select, TextField, makeStyles, FormControl, InputLabel, MenuItem, Grid, Button, TablePagination } from '@material-ui/core';
import { useSelector } from 'react-redux';
import CandidatesOnlineTestRow from './CandidatesOnlineTestRow';

function CandidatesOnlineTestView(props) {

  const useStyles = makeStyles(theme => ({
    filterInput : {
      width: "80%",
      margin: "auto",
      marginBottom : '16px',
      height: "45px",
      textAlign : 'center',
    },
    gridCell : {
      display : "flex",
      alignItems : "center",
      justifyContent : "center"
    },
  }));

  const classes = useStyles();


  const filterCandidates = (name, phone, status, email) => {
    axios.post("/candidates/online-test", {
      name : name === "" ? null : name,
      phone : phone === "" ? null : phone,
      season_id : props.season,
      email : email === "" ? null : email,
      status
    })
    .then(response => {
      setCandidates(response.data.data);
      setPage(0);
    })
  }

  const [candidates, setCandidates] = useState([]);
  const [filteredName, setFilteredName] = useState("");
  const [filteredPhone, setFilteredPhone] = useState("");
  const [filteredStatus, setFilteredStatus] = useState("");
  const [filteredEmail, setFilteredEmail] = useState("");
  const statuses = useSelector(state => state.loginReducer.candidatesStatuses);
  const [season, setSeason] = useState(null); 

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 50));
    setPage(0);
  };

  useEffect(()=> {
      filterCandidates(filteredName, filteredPhone, filteredStatus, filteredEmail);
      axios.get("/seasons/"+props.season).then(response => setSeason(response.data.data));
      //eslint-disable-next-line
  }, [props.season])

  const handleGrading = () => {
    axios.post("/candidates/online-test/grade")
    .then(response => {
      filterCandidates(filteredName, filteredPhone, filteredStatus, filteredEmail);
      axios.get("/seasons/"+props.season).then(response => setSeason(response.data.data));
    })
  }

  const handleQualifying = () => {
    axios.post("/candidates/online-test/qualify")
    .then(response => {
      filterCandidates(filteredName, filteredPhone, filteredStatus, filteredEmail);
      axios.get("/seasons/"+props.season).then(response => setSeason(response.data.data));
    })
  }

  return (
    <Aux>
    <Paper>
      <Grid container>
        <Grid item xs={3} className={classes.gridCell} >
          <Button
                //disabled //TODO enable for next season
                variant="contained" 
                color="primary"
                onClick={handleGrading}>
                Изчисли класиране от теста
          </Button>
        </Grid>
        <Grid item xs={3} className={classes.gridCell} >
        <h4>Завършили теста: {season ? season.candidates_online_test : 'N/A'} кандидата</h4>
        </Grid>    
        <Grid item xs={3} className={classes.gridCell} >
        <h4>Места за присъствен тест: {season ? season.online_test_qualifiers : 'N/A'}</h4>
        </Grid> 
        <Grid item xs={3} className={classes.gridCell} >
          <Button
                //disabled //TODO enable for next season
                variant="contained" 
                color="primary"
                onClick={handleQualifying}>
                Допусни до присъствен тест
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <hr></hr>
        </Grid>
        <Grid item xs={3} className={classes.gridCell} >
          <TextField
              label="Име"
              className = {classes.filterInput}
              value={filteredName}
              onChange={(event)=> {setFilteredName(event.target.value); filterCandidates(event.target.value, filteredPhone, filteredStatus, filteredEmail)}}
              />
        </Grid>
        <Grid item xs={3} className={classes.gridCell} >
          <TextField
              label="Имейл"
              className = {classes.filterInput}
              value={filteredEmail}
              onChange={(event)=> {setFilteredEmail(event.target.value); filterCandidates(filteredName, filteredPhone, filteredStatus, event.target.value)}}
              />
        </Grid>
        <Grid item xs={3} className={classes.gridCell} >
          <TextField
              label="Телефон"
              className = {classes.filterInput}
              value={filteredPhone}
              onChange={(event)=> {setFilteredPhone(event.target.value); filterCandidates(filteredName, event.target.value, filteredStatus, filteredEmail)}}
              />
        </Grid>
        <Grid item xs={3} className={classes.gridCell} >
          <FormControl className={classes.filterInput}>
            <InputLabel>Статус</InputLabel>
            <Select
                value={filteredStatus}
                onChange={(event)=>{setFilteredStatus(event.target.value); filterCandidates(filteredName, filteredPhone, event.target.value, filteredEmail)}}
            >
              <MenuItem value={""}>Всички</MenuItem>
              {
                statuses.map((status, key) => (
                  key === 2 || key === 3 ?
                  <MenuItem key={status} value={status}>{status}</MenuItem>
                  : null
                ))
              }
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
    <TableContainer component={Paper}>
        <Table aria-label="simple table" size="small">
            <TableHead>
            <TableRow>
                <TableCell style={{fontWeight : "bold"}}>Общо: {candidates.length} кандидата</TableCell>
                <TableCell>Имена</TableCell>
                <TableCell>Имейл</TableCell>
                <TableCell>Възраст</TableCell>
                <TableCell>Телефон</TableCell>
                <TableCell>Резултат онлайн тест</TableCell>
                <TableCell>Статус</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {candidates.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((cand, index) => (
                <CandidatesOnlineTestRow key={cand.id} candidate={cand} order={(page * rowsPerPage) + index+1} />
            ))}
            </TableBody>
        </Table>
    </TableContainer>
    <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={candidates.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
    </Aux>
  );
}

export default CandidatesOnlineTestView;
