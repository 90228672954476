import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Popover, Typography } from '@material-ui/core';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import InfoIcon from '@material-ui/icons/Help';
import CandidateActionsRow from './CandidateActionsRow';

function CandidatesPreliminaryRow(props) {

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    }
  },
  popover: {
    pointerEvents: 'none',
  },
  rejectionNote : {
    margin : "20px",
  },
  textCentered : {
    display : "flex"
  },
  rejectionNoteRed : {
    margin : "20px",
    color : "red"
  }
});

  useEffect(()=>{
    setCandidate(props.candidate);//every time this component receives new props, we update the state
  },[props.candidate])

  const [candidate, setCandidate] = useState({});
  const [expandRow, setExpandRow] = useState(false);
  const classes = useRowStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const popoverOpenHandler = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const popoverCloseHandler = () => {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);


  return (
    <Aux>
      <TableRow className={classes.root} key={candidate.id} onClick={() => setExpandRow(!expandRow)}>
        <TableCell>
          <IconButton aria-label="expand candidate" size="small" onClick={() => setExpandRow(!expandRow)}>
            {expandRow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            {props.order}
          </IconButton>
        </TableCell>
        <TableCell >{candidate.full_name}</TableCell>
        <TableCell >{candidate.email}</TableCell>
        <TableCell >{candidate.age + ' год.'}</TableCell>
        <TableCell >{candidate.phone_number}</TableCell>
        <TableCell className={classes.textCentered}>
          <Typography component="span">{candidate.recruitment_state}</Typography>
          {
            candidate.rejection_notes &&
            (
              <Aux>
                <InfoIcon
                  aria-owns={open ? 'mouse-over-popover' : undefined}
                  aria-haspopup="true"
                  onMouseEnter={popoverOpenHandler}
                  onMouseLeave={popoverCloseHandler}
                />
                <Popover
                  id="mouse-over-popover"
                  className={classes.popover}
                  open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  onClose={popoverCloseHandler}
                  disableRestoreFocus
                >
                  <Typography className={classes.rejectionNote}>{candidate.rejection_notes}</Typography>
                  {
                    candidate.banned_for_future_seasons && (
                      <Typography className={classes.rejectionNoteRed}>Не допускай до бъдещи сезони</Typography>
                    )
                  }
                  
                </Popover>
              </Aux>
            )
          }
        </TableCell>
      </TableRow>
      <CandidateActionsRow
        colSpan = {6}
        expandRow = {expandRow}
        candidate = {candidate}
        updateCandidate = {setCandidate}
      ></CandidateActionsRow>
    </Aux>
  );
}

export default CandidatesPreliminaryRow;