import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography } from '@material-ui/core';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import {CANDIDATE_STATUS_ONLINE_TEST_ADMITTED, CANDIDATE_STATUS_ONLINE_TEST_DROPPED, CANDIDATE_STATUS_ATTENDANCE_TEST_ADMITTED, CANDIDATE_STATUS_ATTENDANCE_TEST_DROPPED, CANDIDATE_STATUS_INTERVIEW_ADMITTED, CANDIDATE_STATUS_INTERVIEW_DROPPED, CANDIDATE_STATUS_CONTRACT_ADMITTED, USER_ROLE_ADMINISTRATOR_ID} from '../../util/Constants';
import ChooseInterviewSlotModal from './ChooseInterviewSlotModal';
import axios from '../../util/Axios/Axios'
import ChooseAttendanceTestSlotModal from './ChooseAttendanceTestSlotModal';
import ChooseContractSlotModal from './ChooseContractSlotModal';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import SubmitSurveyModal from './SubmitSurveyModal';
import AcceptContractDocumentsModal from './AcceptContractDocumentsModal';

function ApplicationEntry(props) {

    const info = props.info;
    const userRoleId = useSelector(state => state.loginReducer.userRoleId);

    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
        },
        gridCellLeft : {
            textAlign : 'left'
        },
        formInput: {
          width: "80%",
          margin: "auto",
          height: "25px",
          textAlign : 'center',
        },
        red : {
            color : "red",
        },
        green : {
            color : "green",
        },
        heading: {
          fontSize: theme.typography.pxToRem(15),
          fontWeight: theme.typography.fontWeightRegular,
        },
        hidden : {
            display : 'none',
        },
    }));

    const classes = useStyles();
    const [chooseInterviewSlotOpen, setChooseInterviewSlotOpen] = useState(false);
    const [chosenInterviewSlot, setChosenInterviewSlot] = useState(info.interview_slot);
    const [acceptedContractDocuments, setAcceptedContractDocuments] = useState(info.contract_documents_accepted);
    const [chooseAttendanceTestSlotOpen, setChooseAttendanceTestSlotOpen] = useState(false);
    const [chosenAttendanceTestSlot, setChosenAttendanceTestSlot] = useState(info.attendance_test_slot);
    const [chooseContractSlotOpen, setChooseContractSlotOpen] = useState(false);
    const [chosenContractSlot, setChosenContractSlot] = useState(info.contract_slot);
    const [surveyModalOpen, setSurveyModalOpen] = useState(false);
    const [surveyFilledAt, setSurveyFilledAt] = useState(info.survey_filled_at);
    const [acceptContractDocumentsOpen, setAcceptContractDocumentsOpen] = useState(false);

    const chooseInterviewSlotCloseHandler = (chosenSlot) => {
        axios.post('/candidates/interviews/slot', {
            slot_id : chosenSlot.id,
            candidate_id : info.id
        })
        .then(response => {
            setChosenInterviewSlot(chosenSlot);
            setChooseInterviewSlotOpen(false);
        })
    }

    const clearInterviewSlotCloseHandler = () => {
        axios.delete('/candidates/interviews/slot', {
            data : {
                candidate_id : info.id
            }
        })
        .then(response => {
            setChosenInterviewSlot(null);
            setChooseInterviewSlotOpen(false);
        })
    }

    const closeInterviewSlotsHandler = () => {
        setChooseInterviewSlotOpen(false);
    }

    const chooseAttendanceTestSlotCloseHandler = (chosenSlot) => {
        axios.post('/candidates/attendance-test/slot', {
            slot_id : chosenSlot.id,
            candidate_id : info.id
        })
        .then(response => {
            setChosenAttendanceTestSlot(chosenSlot);
            setChooseAttendanceTestSlotOpen(false);
        })
    }

    const clearAttendanceTestSlotHandler = () => {
        axios.delete('/candidates/attendance-test/slot', {
            data : {
                candidate_id : info.id
            }
        })
        .then(response => {
            setChosenAttendanceTestSlot(null);
            setChooseAttendanceTestSlotOpen(false);
        })
    }

    const chooseContractSlotCloseHandler = (chosenSlot) => {
        axios.post('/candidates/contracts/slot', {
            slot_id : chosenSlot.id,
            candidate_id : info.id
        })
        .then(response => {
            setChosenContractSlot(chosenSlot);
            setChooseContractSlotOpen(false);
        })
    }

    const clearContractSlotHandler = () => {
        axios.delete('/candidates/contracts/slot', {
            data : {
                candidate_id : info.id
            }
        })
        .then(response => {
            setChosenContractSlot(null);
            setChooseContractSlotOpen(false);
        })
    }

    const handleStartAttendanceTest = () => {
        axios.get('/candidates/'+info.id+'/attendance-test')
        .then(response => {
            const url = response.data.data;
            window.open(url, '_blank');
        })
    }

    const handleStartOnlineTest = () => {
        axios.get('/candidates/'+info.id+'/online-test')
        .then(response => {
            const url = response.data.data;
            window.open(url, '_blank');
        })
    }

    const closeAttendanceTestSlotsHandler = () => {
        setChooseAttendanceTestSlotOpen(false);
    }

    const closeContractSlotsHandler = () => {
        setChooseContractSlotOpen(false);
    }

    const submitSurveyHandler = (filled) => {
        if(filled){
            setSurveyFilledAt(new Date());
        }
        setSurveyModalOpen(false);
    }

    const acceptContractDocumentsHandler = (accepted) => {
        if(accepted){
            setAcceptedContractDocuments(true);
        }
        setAcceptContractDocumentsOpen(false)
    }

    const renderOnlineTestInfo = (info) => {
        return (
            <Aux>
                <Grid item xs={6}>
                    Дата на онлайн тест: 
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={4}>
                            
                            <strong>{info.season.online_test_date == null ? "Очаквайте скоро" : (
                                <Moment element="span" format="DD.MM.YYYY [от] HH:mm">
                                    {info.season.online_test_date}
                                </Moment>
                            )}</strong>
                        </Grid>
                        <Grid item xs={8}>
                            <Button
                                className={info.can_start_online_test ? classes.formInput : classes.hidden} 
                                variant="contained" 
                                color="primary"
                                onClick={()=> handleStartOnlineTest()}
                            >
                                Към теста
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Aux>
        )
    }


    const renderAttendanceTestInfo = (info) => {
        return (
            <Aux>
                {userRoleId === USER_ROLE_ADMINISTRATOR_ID ? renderOnlineTestInfo(info) : ""}
                <Grid item xs={6}>
                    Дата на присъствен тест: 
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        {
                            chosenAttendanceTestSlot ? (
                                <>
                                    <Grid item xs={4}>
                                        <strong className={classes.green}>
                                            <Moment element="span" format="DD.MM.YYYY [от] HH:mm">
                                                {chosenAttendanceTestSlot.date}
                                            </Moment>
                                        </strong>
                                    </Grid>
                                    <Grid item xs={4}>
                                        {
                                            !info.forbid_attendance_test ? (
                                                <Button
                                                    className={info.can_start_attendance_test ? classes.formInput : classes.hidden} 
                                                    variant="contained" 
                                                    color="primary"
                                                    onClick={()=> handleStartAttendanceTest()}
                                                >
                                                    Към теста
                                                </Button>
                                            ) : (
                                                <Typography className={classes.red}>Трябва да присъствате в офиса на ИТ Таланти!</Typography>
                                            )
                                        }
                                    </Grid>
                                </>
                            ) : ""
                        }
                        {
                            //TODO we hide the button temporary, should be uncommented for next season
                            (!chosenAttendanceTestSlot && info.recruitment_state === CANDIDATE_STATUS_ATTENDANCE_TEST_ADMITTED) || 
                             userRoleId === USER_ROLE_ADMINISTRATOR_ID ? (
                                <Grid item xs={4}>
                                    <Button
                                        className={classes.formInput} 
                                        variant="contained" 
                                        color="primary"
                                        onClick={()=>setChooseAttendanceTestSlotOpen(true)}
                                        >
                                        Избери
                                    </Button>
                                </Grid>
                            ) : ""
                        }
                    </Grid>
                </Grid>
                {
                    renderSurveyInfo(info)
                }
            </Aux>
        )
    }

    const renderSurveyInfo = (info) => {
        return (
            <Aux>
                <Grid item xs={6}>
                    Начален въпросник: 
                </Grid>
                <Grid item xs={6}>
                    {
                        surveyFilledAt === null ? (
                            <Button
                                className={classes.formInput} 
                                variant="contained" 
                                color="primary"
                                onClick={()=>setSurveyModalOpen(true)}
                                >
                                Попълни
                            </Button>
                        ) : (
                            <Typography className={classes.green}>
                                {"Въпросникът бе попълнен на "} 
                                <Moment element="span" format="DD.MM.YYYY [от] HH:mm">
                                    {surveyFilledAt}
                                </Moment>
                            </Typography>
                        )
                    }
                </Grid>
            </Aux>
        )
    }

    const renderInterviewInfo = (info) => {
        return (
            <Aux>
                {userRoleId === USER_ROLE_ADMINISTRATOR_ID ? renderAttendanceTestInfo(info) : ""}
                <Grid item xs={6}>
                    Дата на интервю: 
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        {
                            chosenInterviewSlot ? (
                                <Grid item xs={8}>
                                    <strong className={classes.green}>
                                        <Moment element="span" format="DD.MM.YYYY [от] HH:mm">
                                            {chosenInterviewSlot.start_time}
                                        </Moment>
                                        {" до "}
                                        <Moment element="span" format="HH:mm">
                                            {chosenInterviewSlot.end_time}
                                        </Moment>
                                    </strong>
                                </Grid>
                            ) : ""
                        }
                        {   
                            //TODO we hide the button temporary, should be uncommented for next season
                            // (!chosenInterviewSlot && info.recruitment_state === CANDIDATE_STATUS_INTERVIEW_ADMITTED) || 
                            userRoleId === USER_ROLE_ADMINISTRATOR_ID ? (
                                <Grid item xs={4}>
                                    <Button
                                        className={classes.formInput} 
                                        variant="contained" 
                                        color="primary"
                                        onClick={()=>setChooseInterviewSlotOpen(true)}
                                        >
                                        Избери
                                    </Button>
                                </Grid>
                            ) : ""
                        }
                    </Grid>
                </Grid>
                {
                    renderSurveyInfo(info)
                }
            </Aux>
        )
    }

    const renderContractInfo = (info) => {
        return (
            <Aux>
                {userRoleId === USER_ROLE_ADMINISTRATOR_ID ? renderInterviewInfo(info) : ""}
                {//FOR NOW manually disable contract slot choosing. Activate for the next season.
                /* <Grid item xs={6}>
                    Дата на подписване на договор: 
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        {
                            chosenContractSlot ? (
                                <Grid item xs={8}>
                                    <strong className={classes.green}>
                                        <Moment element="span" format="DD.MM.YYYY [от] HH:mm">
                                            {chosenContractSlot.date}
                                        </Moment>
                                    </strong>
                                </Grid>
                            ) : ""
                        }
                        {
                            (!chosenContractSlot && info.recruitment_state === CANDIDATE_STATUS_CONTRACT_ADMITTED) || 
                            userRoleId === USER_ROLE_ADMINISTRATOR_ID ? (
                                <Grid item xs={4}>
                                    {info.recruitment_state === CANDIDATE_STATUS_CONTRACT_ADMITTED ? (
                                        <Button
                                            className={classes.formInput} 
                                            variant="contained" 
                                            color="primary"
                                            onClick={()=>setChooseContractSlotOpen(true)}
                                        >
                                        Избери
                                        </Button>
                                    ) : ""}
                                </Grid>
                            ) : ""
                        }
                    </Grid>
                </Grid> */}
                {
                //FOR NOW manually disable contract signing. Also check and probably disable for the next season
                /* <Grid item xs={6}>
                    Документи за прием
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        {
                            acceptedContractDocuments ? (
                                <Grid item xs={8}>
                                    <strong className={classes.green}>
                                        Вашите документи са успешно приети
                                    </strong>
                                </Grid>
                            ) : ""
                        }
                        {
                            (!acceptedContractDocuments && info.recruitment_state === CANDIDATE_STATUS_CONTRACT_ADMITTED) || 
                            userRoleId === USER_ROLE_ADMINISTRATOR_ID ? (
                                <Grid item xs={4}>
                                    <Button
                                        className={classes.formInput} 
                                        variant="contained" 
                                        color="primary"
                                        onClick={()=>setAcceptContractDocumentsOpen(true)}
                                        >
                                        Приеми
                                    </Button>
                                </Grid>
                            ) : ""
                        }
                    </Grid>
                </Grid> */}
            </Aux>
        )
    }

    const renderRecruitmentInfo = (info) => {
        switch (info.recruitment_state) {
            case CANDIDATE_STATUS_ONLINE_TEST_ADMITTED:
            case CANDIDATE_STATUS_ONLINE_TEST_DROPPED:
                return renderOnlineTestInfo(info);
            case CANDIDATE_STATUS_ATTENDANCE_TEST_ADMITTED:
            case CANDIDATE_STATUS_ATTENDANCE_TEST_DROPPED:
                return renderAttendanceTestInfo(info);
            case CANDIDATE_STATUS_INTERVIEW_ADMITTED:
            case CANDIDATE_STATUS_INTERVIEW_DROPPED:
                return renderInterviewInfo(info);
            case CANDIDATE_STATUS_CONTRACT_ADMITTED:
                return renderContractInfo(info);
            default:
                return "";
        }
    }

    return (
        <Aux>
            <Grid container spacing={2}  className={classes.gridCellLeft}>
                {
                    renderRecruitmentInfo(info)
                }
            </Grid>
            <ChooseInterviewSlotModal 
                open={chooseInterviewSlotOpen}
                chosen={chosenInterviewSlot}
                onClose={closeInterviewSlotsHandler}
                onChooseSlot={chooseInterviewSlotCloseHandler}
                onClearSlot={clearInterviewSlotCloseHandler}
                seasonId={info.season_id}>
            </ChooseInterviewSlotModal>
            <ChooseAttendanceTestSlotModal
                open={chooseAttendanceTestSlotOpen}
                chosen={chosenAttendanceTestSlot}
                onClose={closeAttendanceTestSlotsHandler}
                onChooseSlot={chooseAttendanceTestSlotCloseHandler}
                onClearSlot={clearAttendanceTestSlotHandler}
                seasonId={info.season_id}>
            </ChooseAttendanceTestSlotModal >
            <ChooseContractSlotModal
                open={chooseContractSlotOpen}
                chosen={chosenContractSlot}
                onClose={closeContractSlotsHandler}
                onChooseSlot={chooseContractSlotCloseHandler}
                onClearSlot={clearContractSlotHandler}
                seasonId={info.season_id}>
            </ChooseContractSlotModal >
            <SubmitSurveyModal
                candidate_id={info.id}
                open={surveyModalOpen}
                onClose={submitSurveyHandler}>
            </SubmitSurveyModal>
            <AcceptContractDocumentsModal
                candidate_id={info.id}
                open={acceptContractDocumentsOpen}
                onClose={acceptContractDocumentsHandler}>
            </AcceptContractDocumentsModal>
        </Aux>
    );

}

export default ApplicationEntry;