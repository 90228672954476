import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../assets/address-logo.png';
import { BASE_URL, MENU_WIDTH, USER_ROLE_ADMINISTRATOR_ID, USER_ROLE_HR_ID } from '../util/Constants';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Grid, useMediaQuery } from '@material-ui/core';

function Footer(props) {
    
  const open = useSelector(state => state.generalReducer.showMenu); 
  const userRoleId = useSelector(state => state.loginReducer.userRoleId);
  const onMobile = !useMediaQuery('(min-width:600px)');
  const drawerWidth = MENU_WIDTH;

  const useStyles = makeStyles(theme => ({
    footer : {
        backgroundColor : theme.palette.primary.main,
        height : '100px',
        position : "fixed",
        bottom : "0",
        width : "100%",
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
    },
    logo: {
        height : '92px',
        margin : 'auto',
        marginTop : '4px',
        marginBottom : '4px',
    },
    footerShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    hidden : {
        display : 'none',
    },
    gridCell : {
        display : 'flex',
        justifyContent : 'center',
        flexDirection : 'column',
    },
    imgGridCell : {
        textAlign : 'center'
    },
    white : {
        color : 'white',
    },
    blue : {
        color : '#1D9DD1'
    }
  }));

  const classes = useStyles();

    return (
        <footer 
            className={clsx(classes.footer, {
                [classes.footerShift]: open,
                [classes.hidden] : onMobile || userRoleId === USER_ROLE_ADMINISTRATOR_ID || userRoleId === USER_ROLE_HR_ID
            })}
        >
            <Grid container>
                <Grid item xs={3} className={classes.imgGridCell}>
                    <img className={classes.logo} src={logo} alt="ittalents logo"></img>
                </Grid>
                <Grid item xs={3} className={classes.gridCell}>
                        <div className={classes.white}>гр.София, South Side Business Center</div>
                        <div className={classes.white}>ул. Майстор Алекси Рилец №38, ет. 1</div>
                </Grid>
                <Grid item xs={3} className={classes.gridCell}>
                        <div className={classes.white}>Email</div>
                        <div className={classes.blue}>info@ittalents.bg</div>
                </Grid>
                <Grid item xs={3} className={classes.gridCell}>
                        <div className={classes.white}>© 2019-2020 “ИТ Таланти”. Всички права запазени.</div>
                        <div ><a className={classes.blue} href={BASE_URL + '/uploads/TermsAndConditionsFooter.pdf'} target="_blank" rel="noopener noreferrer">Общи Условия</a></div>
                </Grid>
            </Grid>
        </footer>
    );

}

export default Footer;

