import React, { useState, useEffect } from 'react';
import axios from '../../util/Axios/Axios';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import { TableContainer, Table, TableRow, TableCell, Paper, TableHead, TableBody, Select, TextField, makeStyles, FormControl, InputLabel, MenuItem, Grid, TableSortLabel, TablePagination } from '@material-ui/core';
import { useSelector } from 'react-redux';
import CandidatesExportButton from '../../components/buttons/CandidatesExportButton';
import CandidatesContractRow from './CandidatesContractRow';

function CandidatesContractView(props) {

  const useStyles = makeStyles(theme => ({
    filterInput : {
      width: "80%",
      margin: "auto",
      marginTop : '16px',
      marginBottom : '16px',
      height: "45px",
      textAlign : 'center',
    },
    gridCell : {
      textAlign : 'center'
    },
  }));

  const classes = useStyles();


  const filterCandidates = (name, phone, status, slot, email, course) => {
    axios.post("/candidates/contracts", {
      name : name === "" ? null : name,
      phone : phone === "" ? null : phone,
      season_id : props.season,
      contract_slot_id : slot,
      course_id : course,
      email : email === "" ? null : email,
      status
    })
    .then(response => {
      setCandidates(response.data.data);
    })
  }

  const [candidates, setCandidates] = useState([]);
  const [filteredName, setFilteredName] = useState("");
  const [filteredPhone, setFilteredPhone] = useState("");
  const [filteredStatus, setFilteredStatus] = useState("");
  const [filteredSlot, setFilteredSlot] = useState("");
  const [filteredEmail, setFilteredEmail] = useState("");
  const [filteredCourse, setFilteredCourse] = useState("");
  const [slots, setSlots] = useState([]);
  const statuses = useSelector(state => state.loginReducer.candidatesStatuses);
  const [orderBy, setOrderBy] = useState('interview_score');
  const [order, setOrder] = useState('asc');
  const courses = useSelector(state => state.loginReducer.nextSeasonCourses);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 50));
    setPage(0);
  };

  useEffect(()=> {
      filterCandidates(filteredName, filteredPhone, filteredStatus, filteredSlot, filteredEmail, filteredCourse);
      axios.get('/seasons/'+props.season+'/contract/slots').then ( response => setSlots(response.data.data));
      //eslint-disable-next-line
  }, [props.season])

  
  const handleCandidatesSort = (property) => {
    setOrderBy(property);
    setOrder(order === 'asc' ? 'desc' : 'asc');
    candidates.sort(function (a, b){
      return order === 'desc'
        ? descendingComparator(a, b, property)
        : -descendingComparator(a, b, property);
    });
    setCandidates(candidates);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  return (
    <Aux>
    <Paper>
      <Grid container>
        <Grid item xs={4} className={classes.gridCell} >
          <TextField
              label="Име"
              className = {classes.filterInput}
              value={filteredName}
              onChange={(event)=> {setFilteredName(event.target.value); filterCandidates(event.target.value, filteredPhone, filteredStatus, filteredSlot, filteredEmail, filteredCourse)}}
              />
        </Grid>
        <Grid item xs={4} className={classes.gridCell} >
          <TextField
              label="Имейл"
              className = {classes.filterInput}
              value={filteredEmail}
              onChange={(event)=> {setFilteredEmail(event.target.value); filterCandidates(filteredName, filteredPhone, filteredStatus, filteredSlot, event.target.value, filteredCourse)}}
              />
        </Grid>
        <Grid item xs={4} className={classes.gridCell} >
          <TextField
              label="Телефон"
              className = {classes.filterInput}
              value={filteredPhone}
              onChange={(event)=> {setFilteredPhone(event.target.value); filterCandidates(filteredName, event.target.value, filteredStatus, filteredSlot, filteredEmail, filteredCourse)}}
              />
        </Grid>
        <Grid item xs={4} className={classes.gridCell} >
          <FormControl className={classes.filterInput}>
            <InputLabel>Статус</InputLabel>
            <Select
                value={filteredStatus}
                onChange={(event)=>{setFilteredStatus(event.target.value); filterCandidates(filteredName, filteredPhone, event.target.value, filteredSlot, filteredEmail, filteredCourse)}}
            >
              <MenuItem value={""}>Всички</MenuItem>
              {
                statuses.map((status, key) => (
                  key === 4 || key === 5 ?
                  <MenuItem key={status} value={status}>{status}</MenuItem>
                  : null
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} className={classes.gridCell} >
          <FormControl className={classes.filterInput}>
            <InputLabel>Слот</InputLabel>
            <Select
                value={filteredSlot}
                onChange={(event)=>{setFilteredSlot(event.target.value); filterCandidates(filteredName, filteredPhone, filteredStatus, event.target.value, filteredEmail, filteredCourse)}}
            >
              <MenuItem value={""}>Всички</MenuItem>
              <MenuItem value={-1}>Без слот</MenuItem>
              {
                slots.map((slot) => (
                  <MenuItem key={slot.id} value={slot.id}>{slot.date} (общо {slot.max_slots} места)</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} className={classes.gridCell} >
          <FormControl className={classes.filterInput}>
            <InputLabel>Група (курс)</InputLabel>
            <Select
                value={filteredCourse}
                onChange={(event)=>{setFilteredCourse(event.target.value); filterCandidates(filteredName, filteredPhone, filteredStatus, filteredSlot, filteredEmail, event.target.value)}}
            >
              <MenuItem value={""}>Всички</MenuItem>
              {
                courses.map((course) => (
                  <MenuItem key={course.id} value={course.id}>{course.name}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} className={classes.gridCell} >
          <FormControl className={classes.filterInput}>
            <CandidatesExportButton
              candidates = {candidates}
              fileName = {"Договори_"+ new Date().toLocaleDateString("en-GB")}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
    <TableContainer component={Paper}>
        <Table aria-label="simple table" size="small">
            <TableHead>
            <TableRow>
            <TableCell style={{fontWeight : "bold"}}>Общо: {candidates.length} кандидата</TableCell>
                <TableCell>Имена</TableCell>
                <TableCell>Имейл</TableCell>
                <TableCell>Телефон</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'interviewer_name'}
                    direction={orderBy === 'interviewer_name' ? order : 'asc'}
                    onClick={()=>handleCandidatesSort('interviewer_name')}
                  >
                    Интервюиращ
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'course_name'}
                    direction={orderBy === 'course_name' ? order : 'asc'}
                    onClick={()=>handleCandidatesSort('course_name')}
                  >
                    Група (курс)
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'interview_desired_tech_1'}
                    direction={orderBy === 'interview_desired_tech_1' ? order : 'asc'}
                    onClick={()=>handleCandidatesSort('interview_desired_tech_1')}
                  >
                    Желани технологии
                  </TableSortLabel>
                </TableCell>
                <TableCell>Статус</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {candidates.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((cand, index) => (
                <CandidatesContractRow key={cand.id} candidate={cand} order={(page * rowsPerPage) + index+1}/>
            ))}
            </TableBody>
        </Table>
    </TableContainer>
    <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={candidates.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
    </Aux>
  );
}

export default CandidatesContractView;
