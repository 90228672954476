import axios from '../../../util/Axios/Axios'
import { SHOW_MENU, HIDE_MENU, MENU_OPEN_MY_HOME, OPEN_LOGIN_PAGE } from '../generalActions';

export const ACTION_LOG_IN = 'LOG_IN';
export const ACTION_LOG_OUT = 'LOG_OUT';

export const logIn = (email, password, onMobile) => {

    return dispatch =>{
        axios.post('auth/login', {
            email : email,
            password : password
        })
        .then(response => {
            const auth = response.headers['authorization'];
            const userId = response.data.data.id;
            const userRoleId = response.data.data.roles[0].id;
            const nextSeason = response.data.data.next_season;
            localStorage['auth'] = auth;
            localStorage['user_id'] = userId;
            localStorage['user_role_id'] = userRoleId;
            localStorage['next_season'] = JSON.stringify(nextSeason);

            const getStatuses =    axios.get("/candidates/statuses");
            const getCourses =     axios.get("seasons/"+nextSeason.id+"/courses");
            Promise.all([getStatuses, getCourses])
            .then( responses => {
                const statuses = responses[0];
                const courses = responses[1];
                dispatch({
                    type: ACTION_LOG_IN,
                    userId : userId,
                    userRoleId : userRoleId,
                    nextSeason : nextSeason,
                    candidatesStatuses : statuses.data.data,
                    nextSeasonCourses : courses.data.data
                });
                if(!onMobile){
                    dispatch({type: SHOW_MENU});
                }
                dispatch({type: MENU_OPEN_MY_HOME})
            })
        })
    }
}

export const logOut = () => {
    return dispatch => {
        localStorage.clear();
        dispatch({type : HIDE_MENU})
        dispatch({type : ACTION_LOG_OUT})
        dispatch({type : OPEN_LOGIN_PAGE})
        window.history.pushState('state', 'title', '/');
    }
}