import React, { useEffect, useState } from 'react';
import { Typography, makeStyles, Grid, Box, RadioGroup, Radio, FormControlLabel, FormControl } from '@material-ui/core';
import axios from '../../util/Axios/Axios'
import SimpleModal from '../../components/modals/SimpleModal';
import { GreenButton } from '../../components/buttons/GreenButton';
import Scrollbars from 'react-custom-scrollbars';

function SubmitSurveyModal(props) {

    const useStyles = makeStyles(theme => ({
        root: {
          width: "100%",
          minWidth : "700px",
          margin: "auto",
          display: "flex",
          textAlign : 'center',
          "flex-direction": "column",
          "justify-content" : "flex-start",
    
        },
        formInput: {
          width: "100%",
          margin: "auto",
          marginTop : '16px',
          marginBottom : '16px',
          height: "45px",
          textAlign : 'center',
        },
        gridCell : {
            textAlign :"center" 
        }
    }));
    
    useEffect(()=>{
        axios.get('/survey')
        .then(response => {
            let questions = response.data.data;
            questions = questions.map(question => {
                if(question.is_open){
                    question.answer_text="";
                }
                else{
                    question.answer_id="-1";
                }
                question.question_id = question.id;
                return question;
            })
            setQuestions(questions);
        })
    },
        //eslint-disable-next-line
    [])

    const classes = useStyles();
    const [questions, setQuestions] = useState([]);

    const submitSurveyHandler = () => {
        axios.post("survey", questions).then(response => props.onClose(true));
    }

    const handleAnswerOpenQuestion = (question_id, answer_text ) => {
        const copiedQuestions = [...questions];
        const question = copiedQuestions.find(question => question.id === question_id);
        question.answer_text = answer_text;
        setQuestions(copiedQuestions);
    }

    const handleAnswerClosedQuestion = (question_id, answer_id) =>{
        const copiedQuestions = [...questions];
        const question = copiedQuestions.find(question => question.id === question_id);
        question.answer_id = answer_id;
        setQuestions(copiedQuestions);
    }

    return (
        <SimpleModal open={props.open} onClose={() => props.onClose(false)} >              
            <div className={classes.root}>
                <Scrollbars style={{ height: "70vh" }} renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}> 
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <Typography variant="h5">
                                Начален въпросник
                            </Typography>
                        </Grid>
                        {
                            questions.map(question => (

                                <Grid key={question.id} item xs={12} className={classes.gridCell} >
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold" m={1}>
                                            {question.question}
                                            </Box>
                                        </Typography>
                                        {
                                            question.is_open ? (
                                                <textarea 
                                                    rows="5"
                                                    cols="75"
                                                    value={question.answer_text}
                                                    onChange={(event)=> handleAnswerOpenQuestion(question.id, event.target.value)}></textarea>
                                            ) : 
                                            (
                                                <FormControl component="fieldset">
                                                        <RadioGroup aria-label="answers" name={"answers_for_"+question.id} value={question.answer_id} onChange={(event) => handleAnswerClosedQuestion(question.id, event.target.value)}>
                                                            {
                                                                question.answers.map(answer => (
                                                                     // eslint-disable-next-line
                                                                    <FormControlLabel key={answer.id} value={answer.id} control={<Radio color="primary" checked={answer.id == question.answer_id} />} label={answer.answer} />
                                                                ))
                                                            }
                                                        </RadioGroup>
                                                </FormControl>
                                            )
                                            
                                        }
                                        <hr></hr>
                                </Grid>
                            ))
                        }
                        <Grid item xs={12} >
                        <GreenButton
                            className={classes.formInput} 
                            variant="contained" 
                            color="primary"
                            onClick={()=> submitSurveyHandler()}
                        >
                            Запази
                        </GreenButton>
                        </Grid>
                    </Grid>
                </Scrollbars>
            </div>
        </SimpleModal>
    );

}

export default SubmitSurveyModal;