import React, { useEffect, useState } from 'react';
import { Paper, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, makeStyles } from '@material-ui/core';
import axios from '../../util/Axios/Axios'
import SimpleModal from '../../components/modals/SimpleModal';
import { GreenButton } from '../../components/buttons/GreenButton';
import { GreenCheckbox } from '../../components/checkboxes/GreenCheckBox';
import { useSelector } from 'react-redux';
import { USER_ROLE_ADMINISTRATOR_ID } from '../../util/Constants';
import { RedButton } from '../../components/buttons/RedButton';

function ChooseContractSlotModal(props) {

    const useStyles = makeStyles(theme => ({
        root: {
          width: "100%",
          margin: "auto",
          display: "flex",
          textAlign : 'center',
          "flex-direction": "column",
          "justify-content" : "flex-start",
    
        },
        formInput: {
          width: "100%",
          margin: "auto",
          marginTop : '16px',
          marginBottom : '16px',
          height: "45px",
          textAlign : 'center',
        },
        red : {
            color : 'red',
        },
        hidden : {
            display : "none"
        }
    }));
    
    useEffect(()=>{
        axios.get('/seasons/'+props.seasonId+'/contract/slots')
        .then(response => {
            setSlots(response.data.data);
        })
    },
        //eslint-disable-next-line
    [props.chosen])

    const classes = useStyles();
    const [slots, setSlots] = useState([]);
    const [selected, setSelected] = useState(props.chosen);

    const userRoleId = useSelector(state => state.loginReducer.userRoleId);

    const isSelected = (id) => selected && selected.id === id;

    const chooseSlotHandler = () => {
        setSelected(selected);
        props.onChooseSlot(selected);
    }
    
    const clearSlotHandler = () => {
        setSelected(null);
        props.onClearSlot();
    }

    const onCloseHandler = () => {
        setSelected(props.chosen);
        props.onClose();
    }

    return (
        <SimpleModal open={props.open} onClose={onCloseHandler} >
            <div className={classes.root}>
                <Typography variant="h5">
                    Изберете слот за подписване на договор
                </Typography>
                <p className={classes.red}>
                   ВАЖНО! Веднъж избрана, датата не може да бъде променена!
                </p>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                        <TableRow>
                            <TableCell>Начало</TableCell>
                            <TableCell className={userRoleId !== USER_ROLE_ADMINISTRATOR_ID ? classes.hidden : ""}>Общо места</TableCell>
                            <TableCell className={userRoleId !== USER_ROLE_ADMINISTRATOR_ID ? classes.hidden : ""}>Заети места</TableCell>
                            <TableCell>Свободни места</TableCell>
                            <TableCell>Избери</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {slots.map(slot => {
                            const isItemSelected = isSelected(slot.id);

                            return (
                            <TableRow 
                            key={slot.id}
                            onClick={() => setSelected(slot)}
                            selected={isItemSelected}
                            className={(slot.max_slots - slot.slots_taken <= 0) && (userRoleId !== USER_ROLE_ADMINISTRATOR_ID) ? classes.hidden : ""}
                            >
                                <TableCell >{slot.date}</TableCell>
                                <TableCell className={userRoleId !== USER_ROLE_ADMINISTRATOR_ID ? classes.hidden : ""}>{slot.max_slots}</TableCell>
                                <TableCell className={userRoleId !== USER_ROLE_ADMINISTRATOR_ID ? classes.hidden : ""}>{slot.slots_taken}</TableCell>
                                <TableCell >{slot.max_slots - slot.slots_taken}</TableCell>
                                <TableCell >
                                {
                                    (slot.max_slots - slot.slots_taken > 0) ? (
                                        <GreenCheckbox
                                            checked={isItemSelected}
                                            onChange={()=>{
                                                if(!selected || selected.id !== slot.id){
                                                    setSelected(slot);
                                                }
                                                else{
                                                    setSelected(null);
                                                }
                                            }}
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        />
                                    ) : (
                                        <Typography className={classes.red}>Няма места</Typography>
                                    )
                                }
                                </TableCell>
                            </TableRow>
                            )
                        })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <GreenButton
                    className={classes.formInput} 
                    variant="contained" 
                    color="primary"
                    disabled={selected === null}
                    onClick={()=> chooseSlotHandler()}
                >
                    Запази дата за присъствен тест
                </GreenButton>  
                {
                    userRoleId === USER_ROLE_ADMINISTRATOR_ID ? (
                        <RedButton
                            className={classes.formInput} 
                            variant="contained" 
                            color="primary"
                            disabled={selected === null}
                            onClick={()=> clearSlotHandler()}
                        >
                            Отпиши кандидата от слот
                        </RedButton> 
                    ) : ""
                }
            </div>
        </SimpleModal>
    );

}

export default ChooseContractSlotModal;