import React, { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import * as actionCreators from '../../store/actions/generalActions'
import {TOAST_SHOW_DURATION} from '../../util/Constants'
import { Slide } from '@material-ui/core';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

function SuccessToast(props) {
  const classes = useStyles();
  const open = useSelector(state => state.generalReducer.successToastOpen)
  const msg  = useSelector(state => state.generalReducer.successMessage)
  const [vertical] = useState('top');
  const [horizontal] = useState('center');
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    dispatch({type : actionCreators.HIDE_SUCCESS_TOAST});
  };

  return (
    <div className={classes.root}>
      <Snackbar anchorOrigin={{ vertical, horizontal }} open={open} autoHideDuration={TOAST_SHOW_DURATION} onClose={handleClose}>
        <Slide direction="down" in={true} >
          <div>
            <Alert onClose={handleClose} severity="success">
              {msg}
            </Alert>
          </div>
        </Slide>
      </Snackbar>
    </div>
  );
}

export default SuccessToast;