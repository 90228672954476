import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../components/TabPanel';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Tabs, Grid, Button, FormControl, InputLabel, Select, MenuItem, Input, Typography } from '@material-ui/core';
import axios from '../../util/Axios/Axios';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import { CANDIDATE_STATUS_PENDING, CANDIDATE_STATUS_REJECTED, CANDIDATE_STATUS_INTERVIEW_ADMITTED, CANDIDATE_STATUS_ONLINE_TEST_ADMITTED, CANDIDATE_STATUS_ATTENDANCE_TEST_ADMITTED, CANDIDATE_STATUS_CONTRACT_ADMITTED, CANDIDATE_STATUS_INTERVIEW_DROPPED } from '../../util/Constants';
import { GreenButton } from '../../components/buttons/GreenButton';
import { RedButton } from '../../components/buttons/RedButton';
import SimpleModal from '../../components/modals/SimpleModal';
import ProfilePage from '../home/ProfilePage';
import RejectCandidateNoteModal from './RejectCandidateNoteModal';
import { useSelector } from 'react-redux';
import ApplicationEntry from '../home/ApplicationEntry';



function CandidateActionsRow(props) {

    function a11yProps(index) {
        return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const useRowStyles = makeStyles({
    gridCell: {
        textAlign : "center",
    },
    rejectionNote : {
        margin : "20px",
    },
    modalContainer : {
      width : '100%',
      display : 'flex',
      'flex-direction' : 'column',
      'justify-content' : 'center',
      'align-items' : 'center',
    },
    filterInput : {
        width: "80%",
        margin: "auto",
        height: "45px",
        textAlign : 'center',
      },
      formInput: {
          width: "90%",
          minWidth : '250px',
          margin: "auto",
          marginBottom : '16px',
          height: "45px",
          textAlign : 'center',
      },
      textarea: {
          width: "90%",
          minWidth : '250px',
          minHeight : '250px',
          margin: "auto",
          marginBottom : '16px',
          height: "45px",
          textAlign : 'center',
      },
    });

    useEffect(()=>{
        setCandidate(props.candidate);//every time this component receives new props, we update the state
    },[props.candidate])

    const [chosenTabId, setChosenTabId] = useState(0);
    
    const handleChange = (event, newValue) => {
        setChosenTabId(newValue);
    };

    const candidateForPastSeason = () => {
        return candidate.season_id != nextSeason.id;
    };

    const [candidate, setCandidate] = useState({});
    const [viewProfile, setViewProfile] = useState(false);
    const [viewInterview, setViewInterview] = useState(false);
    const [viewContractData, setViewContractData] = useState(false);
    const [openModalReject, setOpenModalReject] = useState(false);
    const [openModalReturn, setOpenModalReturn] = useState(false);
    const [rejectionNote, setRejectionNote] = useState("");
    const [bannedForFutureSeasons, setBannedForFutureSeasons] = useState(false);
    const [viewDummyURL, setViewDummyURL] = useState(false);
    const [dummyURL, setDummyURL] = useState("");
    
    const [recruitmentInfoList, setRecruitmentInfoList] = useState([]);
    
    const [interviewDesiredTech1, setInterviewDesiredTech1] = useState(null);
    const [interviewDesiredTech2, setInterviewDesiredTech2] = useState(null);
    const [interviewNotes, setInterviewNotes] = useState(null);
    const [interviewScore, setInterviewScore] = useState(null);
    const [interviewLastEdit, setInterviewLastEdit] = useState(null);
    const [allTechnologies, setAllTechnologies] = useState([]);
    const scores = ['ДА', 'НЕ', 'МОЖЕ БИ', 'ОТКАЗАЛ СЕ'];

    const classes = useRowStyles();
    const courses = useSelector(state => state.loginReducer.nextSeasonCourses);
    const nextSeason = useSelector(state => state.loginReducer.nextSeason);

    const handleGenerateDummyForOnlineTest = () => {
        axios.post("candidates/"+candidate.id+"/online-test/dummy")
        .then(response => {
            setDummyURL(response.data.data.url);
            setViewDummyURL(true);
        });
    }

    const openProfile = () => {
        axios.get('users/'+props.candidate.user_id)
        .then(response => {
            setRecruitmentInfoList(response.data.data.recruitment_info);
            setChosenTabId(response.data.data.recruitment_info.length-1);
            setViewProfile(true);
        });
    }

    const handleGenerateDummyForAttendanceTest = () => {
        axios.post("candidates/"+candidate.id+"/attendance-test/dummy")
        .then(response => {
            setDummyURL(response.data.data.url);
            setViewDummyURL(true);
        });
    }

    const handleApproveCandidate = () => {
        axios.put("candidates/"+candidate.id+"/approve")
        .then(response => {
            setCandidate(response.data.data);
            props.updateCandidate(response.data.data);
        });
    }

    const handleAcceptForContractCandidate = () => {
        axios.put("candidates/"+candidate.id+"/acceptForContract")
        .then(response => {
            setCandidate(response.data.data);
            props.updateCandidate(response.data.data);
        });
    }

    const handleChooseCourse = (course_id) => {
        axios.put("candidates/"+candidate.id+"/course", {course_id})
        .then(response => {
            setCandidate(response.data.data);
            props.updateCandidate(response.data.data);
        });
    }

    const handleReturnCandidate = () => {
        axios.put("candidates/"+candidate.id+"/return")
        .then(response => {
            setCandidate(response.data.data);
            props.updateCandidate(response.data.data);
            setOpenModalReturn(false);
        });
    }

    const handleRejectCandidate = () => {
        axios.put("candidates/"+candidate.id+"/reject", 
        {
            notes : rejectionNote,
            banned : bannedForFutureSeasons
        })
        .then(response => {
        setCandidate(response.data.data);
        props.updateCandidate(response.data.data);
        setOpenModalReject(false);
        });
    }

    const handleRejectFromInterview = () => {
        axios.put("candidates/"+candidate.id+"/interviews/reject")
        .then(response => {
        setCandidate(response.data.data);
        props.updateCandidate(response.data.data);
        });
    }

    const handleRestoreToInterview = () => {
        axios.put("candidates/"+candidate.id+"/interviews/restore")
        .then(response => {
        setCandidate(response.data.data);
        props.updateCandidate(response.data.data);
        });
    }


    const handleAllowAttendanceTest = () => {
        axios.put("candidates/"+candidate.id+"/attendance-test/allow")
        .then(response => {
        setCandidate(response.data.data);
        props.updateCandidate(response.data.data);
        });
    }


    const handleForbidAttendanceTest = () => {
        axios.put("candidates/"+candidate.id+"/attendance-test/forbid")
        .then(response => {
        setCandidate(response.data.data);
        props.updateCandidate(response.data.data);
        });
    }
    

    const refreshCandidate = () => {
        axios.get("candidates/" + candidate.id)
        .then(response => {
            setCandidate(response.data.data);
            props.updateCandidate(response.data.data);})
    }
    
    const handleSaveInterview = () => {
        axios.post('interviews', {
            id : candidate.interview.id,
            candidate_id : props.candidate.id,
            slot_id : candidate.interview.interview_slot.id,
            score : interviewScore,
            notes : interviewNotes,
            desired_tech_1_id : interviewDesiredTech1,
            desired_tech_2_id : interviewDesiredTech2
        })
        .then(response => {
            refreshCandidate()
            setViewInterview(false);
        })
    }

    const viewInterviewData = () => {
        axios.get('seasons/technologies').then(response => { 
            setAllTechnologies(response.data.data)
            setInterviewNotes(candidate.interview.notes);
            setInterviewDesiredTech1(candidate.interview.desired_tech_1);
            setInterviewDesiredTech2(candidate.interview.desired_tech_2);
            setInterviewScore(candidate.interview.score);
            setInterviewLastEdit(candidate.interview.edit_log);
            setViewInterview(true);
        });
    }
    
    const modalCloseHandlerReject = (type) => {
        setOpenModalReject(false)
    };

    const modalOpenHandlerReject = (type) => {
        setOpenModalReject(true)
    };
    
    const modalCloseHandlerReturn = (type) => {
        setOpenModalReturn(false)
    };

    const modalOpenHandlerReturn = (type) => {
        setOpenModalReturn(true)
    };

    return (
        <Aux>
        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={props.colSpan}>
            <Collapse in={props.expandRow} timeout="auto" unmountOnExit>
                <Box margin={1}>
                    <Grid container spacing={2}>
                        <Grid className={classes.gridCell} item xs={3}>
                            <Button
                                variant="contained" 
                                color="primary"
                                onClick={()=>openProfile()}
                                >
                                Пълен профил
                            </Button>
                        </Grid>
                        <Grid className={classes.gridCell} item xs={6}>
                            {
                                candidate.recruitment_state === CANDIDATE_STATUS_PENDING ? (
                                    <GreenButton
                                        variant="contained" 
                                        onClick={handleApproveCandidate}
                                        disabled={candidateForPastSeason()}
                                        >
                                        Одобри
                                    </GreenButton>
                                ) : 
                                candidate.recruitment_state === CANDIDATE_STATUS_ONLINE_TEST_ADMITTED ? (
                                    <Button
                                        variant="contained" 
                                        onClick={handleGenerateDummyForOnlineTest}
                                        disabled={candidateForPastSeason()}
                                        >
                                        Нов демо потребител за теста
                                    </Button>
                                ) : 
                                candidate.recruitment_state === CANDIDATE_STATUS_ATTENDANCE_TEST_ADMITTED ? (
                                    <Grid container spacing={1}>
                                        <Grid className={classes.gridCell} item xs={8}>
                                            <Button
                                                variant="contained" 
                                                onClick={handleGenerateDummyForAttendanceTest}
                                                disabled={candidateForPastSeason()}
                                                >
                                                Нов демо потребител за теста
                                            </Button>
                                        </Grid>
                                        
                                        <Grid className={classes.gridCell} item xs={4}>
                                        {
                                            candidate.forbid_attendance_test ? (
                                                <GreenButton
                                                    variant="contained" 
                                                    color="primary"
                                                    onClick={()=>handleAllowAttendanceTest()}
                                                    disabled={candidateForPastSeason()}
                                                    >
                                                    Активирай тест
                                                </GreenButton>
                                            ) : (
                                            <RedButton
                                                variant="contained" 
                                                color="primary"
                                                onClick={()=>handleForbidAttendanceTest()}
                                                disabled={candidateForPastSeason()}
                                                >
                                                Забрани тест
                                            </RedButton>
                                            )
                                        }
                                        </Grid>
                                    </Grid>
                                ) : 
                                candidate.recruitment_state === CANDIDATE_STATUS_INTERVIEW_ADMITTED || candidate.recruitment_state === CANDIDATE_STATUS_INTERVIEW_DROPPED ? (
                                    <Grid container spacing={1}>
                                        <Grid className={classes.gridCell} item xs={4}>
                                            <Button
                                                variant="contained" 
                                                color="primary"
                                                onClick={()=>viewInterviewData()}
                                                >
                                                Преглед интервю
                                            </Button>
                                        </Grid>
                                        {
                                            candidate.recruitment_state === CANDIDATE_STATUS_INTERVIEW_ADMITTED ? (
                                                <>
                                                    <Grid className={classes.gridCell} item xs={4}>
                                                        <GreenButton
                                                            variant="contained" 
                                                            onClick={handleAcceptForContractCandidate}
                                                            disabled={candidateForPastSeason()}
                                                            >
                                                            Допусни до договор
                                                        </GreenButton>
                                                    </Grid>
                                                    <Grid className={classes.gridCell} item xs={4}>
                                                        <RedButton
                                                            variant="contained" 
                                                            onClick={handleRejectFromInterview}
                                                            disabled={candidateForPastSeason()}
                                                            >
                                                            Отпада от интервю
                                                        </RedButton>
                                                    </Grid>
                                                </>
                                            ) : (
                                                <>
                                                    <Grid className={classes.gridCell} item xs={4}>
                                                    </Grid>
                                                    <Grid className={classes.gridCell} item xs={4}>
                                                        <GreenButton
                                                            variant="contained" 
                                                            onClick={handleRestoreToInterview}
                                                            disabled={candidateForPastSeason()}
                                                            >
                                                            Допусни до интервю
                                                        </GreenButton>
                                                    </Grid>
                                                </>
                                            )
                                        }
                                        
                                    </Grid>
                                ) : 
                                candidate.recruitment_state === CANDIDATE_STATUS_CONTRACT_ADMITTED ? (
                                    <Grid container spacing={1}>
                                        <Grid className={classes.gridCell} item xs={6}>
                                            {
                                                candidateForPastSeason() ? "" : 
                                                (
                                                    <FormControl className={classes.filterInput}>
                                                <InputLabel>Изберете група (курс)</InputLabel>
                                                <Select
                                                    value={candidate.course ? candidate.course.id : null}
                                                    onChange={(event)=> handleChooseCourse(event.target.value) }
                                                >
                                                <MenuItem value={""}>Извън група</MenuItem>
                                                {
                                                    courses && courses.length > 0 ? courses.map((course) => (
                                                    <MenuItem key={course.id} value={course.id}>{course.name}</MenuItem>
                                                    )) : ""
                                                }
                                                </Select>
                                            </FormControl>
                                                )
                                            }
                                            
                                        </Grid>
                                        <Grid className={classes.gridCell} item xs={6}>
                                            <Button
                                                variant="contained" 
                                                color="primary"
                                                onClick={()=>setViewContractData(true)}
                                                >
                                                Преглед лични данни
                                            </Button>
                                        </Grid>
                                    </Grid>
                                ) : ""
                            }
                        </Grid>
                        <Grid className={classes.gridCell} item xs={3}>
                            {
                                candidate.recruitment_state === CANDIDATE_STATUS_REJECTED ? (
                                    <GreenButton
                                    variant="contained" 
                                    onClick={modalOpenHandlerReturn}
                                    disabled={candidateForPastSeason()}
                                    >
                                        Върни в подбора
                                    </GreenButton>
                                ) : (
                                    <RedButton
                                    variant="contained" 
                                    disabled={candidateForPastSeason()}
                                    onClick={modalOpenHandlerReject}
                                    >
                                        Отхвърли
                                    </RedButton>
                                )
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Collapse>
            </TableCell>
        </TableRow>
        <RejectCandidateNoteModal
            handleRejectCandidate = {handleRejectCandidate}
            modalCloseHandler = {modalCloseHandlerReject}
            setRejectionNote = {setRejectionNote}
            setBannedForFutureSeasons = {setBannedForFutureSeasons}
            openModal = {openModalReject}
        ></RejectCandidateNoteModal>
        <SimpleModal open={viewDummyURL} onClose={()=>setViewDummyURL(false)}>
            <Typography component="h3">
                Автоматично генериран линк за тест:
            </Typography>
            <h4><strong>{dummyURL}</strong></h4>
        </SimpleModal>
        <SimpleModal open={viewInterview} onClose={()=>setViewInterview(false)}>
            <div className={classes.modalContainer}>
                <Grid item xs={12}>
                    <h3>Данни за интервю на {candidate.full_name}</h3>
                </Grid>
                <Grid item xs={12}>
                    <FormControl className={classes.formInput}>
                        <InputLabel>Предпочитана технология 1</InputLabel>
                        <Select
                            labelId="desired-technology-label-1"
                            id="desired-technology-1"
                            value={interviewDesiredTech1}
                            required
                            onChange={(event) => setInterviewDesiredTech1(event.target.value)}
                            input={<Input />}
                            >
                            {allTechnologies.map((tech) => (
                                <MenuItem key={tech.id} value={tech.id}>
                                    {tech.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl className={classes.formInput}>
                        <InputLabel>Предпочитана технология 2</InputLabel>
                        <Select
                            labelId="desired-technology-label-2"
                            id="desired-technology-2"
                            value={interviewDesiredTech2}
                            required
                            onChange={(event) => setInterviewDesiredTech2(event.target.value)}
                            input={<Input />}
                            >
                            {allTechnologies.map((tech) => (
                                <MenuItem key={tech.id} value={tech.id}>
                                    {tech.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Typography component="p">Бележки</Typography>
                    <textarea className={classes.textarea}
                        rows="10" 
                        onChange={(event) => setInterviewNotes(event.target.value)}
                        value={interviewNotes}
                        >
                    </textarea>
                </Grid>
                <Grid item xs={12}>
                    <FormControl className={classes.formInput}>
                        <InputLabel>Резултат от интервюто</InputLabel>
                        <Select
                            labelId="desired-technology-label-1"
                            id="desired-technology-1"
                            value={interviewScore}
                            required
                            onChange={(event) => setInterviewScore(event.target.value)}
                            input={<Input />}
                            >
                            {scores.map((score) => (
                                <MenuItem key={score} value={score}>
                                    {score}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <h3>{interviewLastEdit}</h3>
                </Grid>
                <Grid item xs={12}>
                    <GreenButton className={classes.formInput} onClick={() => handleSaveInterview()}>
                        Запази
                    </GreenButton>
                </Grid>
            </div>
        </SimpleModal>
        <SimpleModal open={viewProfile} onClose={()=>setViewProfile(false)}>
            <Grid container>
                <Grid className={classes.gridCell} item xs={12}>
                    <ProfilePage 
                        userId={candidate.user_id} 
                        candidateId={candidate.id}
                        updateCandidate={props.updateCandidate}
                        canEdit={true}
                    ></ProfilePage>
                </Grid>
                
                <Grid className={classes.gridCell} item xs={12}>
                    <h2>История на кандидатурите дотук:</h2>
                </Grid>

                <Grid className={classes.gridCell} item xs={12}>
                    <AppBar position="static">
                        <Tabs variant="fullWidth" value={chosenTabId} onChange={handleChange} aria-label="simple tabs example">
                            {
                                recruitmentInfoList.map((recruitmentInfo, index) => {
                                        return <Tab label={recruitmentInfo.season.name} {...a11yProps(index)} />
                                })
                            }
                        </Tabs>
                    </AppBar>
                    {
                        recruitmentInfoList.map((recruitmentInfo, index) => {
                            return (
                                <TabPanel value={chosenTabId} index={index}>
                                    <ApplicationEntry info={recruitmentInfo}></ApplicationEntry>
                                </TabPanel>
                            )
                        })
                    }
                    
                </Grid>
            </Grid>
        </SimpleModal>
        <SimpleModal open={openModalReturn} onClose={modalCloseHandlerReturn}>
          <div className={classes.modalContainer}>
            <h3>Сигурни ли сте, че искате да върнете кандидата?</h3>
            <p>При потвърждение той ще бъде със статус <strong>{candidate.recruitment_state_before_rejection}</strong></p>
            <GreenButton
              className={classes.formInput} 
              variant="contained" 
              color="primary"
              onClick={handleReturnCandidate}>
                  Върни в подбора
            </GreenButton>  
        </div>
        </SimpleModal>
        <SimpleModal open={viewContractData} onClose={()=>setViewContractData(false)}>
            <div className={classes.modalContainer}>
                <Grid container>
                    <Grid className={classes.gridCell} item xs={6}>
                        Три имена: 
                    </Grid>
                    <Grid className={classes.gridCell} item xs={6}>
                        {candidate.contract_names}
                    </Grid>
                    <Grid className={classes.gridCell} item xs={6}>
                        Адрес по ЛК: 
                    </Grid>
                    <Grid className={classes.gridCell} item xs={6}>
                        {candidate.contract_address}
                    </Grid>
                    <Grid className={classes.gridCell} item xs={6}>
                        Номер на ЛК: 
                    </Grid>
                    <Grid className={classes.gridCell} item xs={6}>
                        {candidate.contract_license_id}
                    </Grid>
                    <Grid className={classes.gridCell} item xs={6}>
                        Дата на издаване: 
                    </Grid>
                    <Grid className={classes.gridCell} item xs={6}>
                        {candidate.contract_license_date}
                    </Grid>
                </Grid>
            </div>
        </SimpleModal>
        </Aux>
    );
}

export default CandidateActionsRow;