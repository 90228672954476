import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Select, InputLabel, FormControl, MenuItem } from '@material-ui/core';
import axios from '../../util/Axios/Axios';
import { useSelector } from 'react-redux';
import TabPanel from '../../components/TabPanel';
import CadetsInCoursePage from './CadetsInCoursePage';


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign : 'center',
    backgroundColor: theme.palette.background.paper,
  },
  filterInput : {
    width: "80%",
    margin: "auto",
    marginTop : '16px',
    marginBottom : '16px',
    height: "45px",
    textAlign : 'center',
  },
}));

function CadetsViewPage() {
  const classes = useStyles();

  const [chosenTabId, setChosenTabId] = useState(0);

  useEffect(() => {
    axios.get('/seasons').then(response => setSeasons(response.data.data));
    if(nextSeason){
        filterCourses(nextSeason.id)
    }
    
    //eslint-disable-next-line
  }, []);

  const nextSeason = useSelector(state => state.loginReducer.nextSeason)
  const [filteredSeason, setFilteredSeason] = useState(nextSeason ? nextSeason.id : null);
  const [seasons, setSeasons] = useState([]);
  const [courses, setCourses] = useState([])

  const handleChange = (event, newValue) => {
    setChosenTabId(newValue);
  };
  
  const filterCourses = (seasonId) => {
    axios.get('/seasons/'+seasonId+'/courses').then(response => setCourses(response.data.data));
  };

  return (
    <div className={classes.root}>
      <FormControl className={classes.filterInput}>
        <InputLabel>Сезон</InputLabel>
        <Select
            value={filteredSeason}
            onChange={(event)=>{
                filterCourses(event.target.value)
                setFilteredSeason(event.target.value)
            }}
        >
          <MenuItem value={""}>Всички</MenuItem>
          {
            seasons.map(season => (
              <MenuItem key={season.id} value={season.id}>{season.name}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
      <AppBar position="static">
        <Tabs variant="fullWidth" value={chosenTabId} onChange={handleChange} aria-label="simple tabs example">
            {
                courses.map((course, index) => (
                    <Tab key={course.id} label={course.name} {...a11yProps(index)} />
                ))
            }
        </Tabs>
      </AppBar>
            {
                courses.map((course, index) => (
                    <TabPanel key={course.id} value={chosenTabId} index={index}>
                        <CadetsInCoursePage course={course.id}></CadetsInCoursePage>
                    </TabPanel>
                ))
            }
    </div>
  );
}

export default CadetsViewPage;