import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Paper, Button, Link, useMediaQuery } from "@material-ui/core";
import axios from '../../util/Axios/Axios';
import SimpleModal from '../../components/modals/SimpleModal';
import { useDispatch } from 'react-redux';
import * as actionCreators from '../../store/actions/login/loginActions'
import { OPEN_REGISTER_PAGE } from '../../store/actions/generalActions';

function LoginPage() {
  
  const mobile = !useMediaQuery('(min-width:600px)');

  const useStyles = makeStyles(theme => ({
    paper: {
      width: mobile ? "95%" : "40%",
      margin: "auto",
      marginTop: "60px",
      display: "flex",
      "flex-direction": "column",
      "justify-content" : "center",

    },
    formInput: {
      width: "80%",
      margin: "auto",
      marginTop : '16px',
      marginBottom : '16px',
      height: "45px",
      textAlign : 'center',
    },
    formLink: {
      width: "80%",
      margin: "auto",
      marginBottom : '8px',
      textAlign : 'center',
      cursor : 'pointer',
    },
    error : {
      color : 'red',
      textAlign : 'center',
    },
    success : {
      color : 'green',
      textAlign : 'center',
    },
    modalContainer : {
      width : '100%',
      display : 'flex',
      'flex-direction' : 'column',
      'justify-content' : 'center',
      'align-items' : 'center',
    },
    modal : {
      width : '30%',
    }
  }));

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [resetPassEmail, setResetPassEmail] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  const resetPasswordHandler = (event) => {
    axios.post('users/forgotpass', {
      email : resetPassEmail
    })
    .then(response => {
      setTimeout(() => {
        setOpenModal(false)  
      }, 2000);
    })
  };
  
  const modalCloseHandler = (type) => {
    setOpenModal(false)
  };

  const modalOpenHandler = (type) => {
    setOpenModal(true)
  };

  return (
    <Paper className={classes.paper}>
      <TextField
        className={classes.formInput}
        label="Имейл"
        onChange={(event) => setEmail(event.target.value)}
      />
      <TextField
        className={classes.formInput}
        label="Парола"
        type = "password"
        onKeyDown={(event) => {
          if(event.keyCode === 13){
            setPassword(event.target.value);
            dispatch(actionCreators.logIn(email, event.target.value, mobile));
          }
        }}
        onChange={(event) => setPassword(event.target.value)}
      />
      <Button 
        className={classes.formInput} 
        variant="contained" 
        color="primary"
        onClick={()=> dispatch(actionCreators.logIn(email, password, mobile))}
        >
        Вход
      </Button>
      <Link className={classes.formLink} onClick={
        ()=> {
          window.history.pushState('state', 'title', '/register');
          dispatch({type : OPEN_REGISTER_PAGE});
        }
      }>Нямате акаунт? Моля регистрирайте се тук</Link>
      <Link className={classes.formLink} onClick={modalOpenHandler}>Забравена парола</Link>

      <SimpleModal className={classes.modal} open={openModal} onClose={modalCloseHandler} >
          <div className={classes.modalContainer}>
            <h3>Напишете вашата електронна поща, за да ви изпратим нова парола</h3>
            <TextField
              required
              className={classes.formInput}
              label="Имейл"
              type="email"
              onChange={(event) => setResetPassEmail(event.target.value)}
            />
            <Button
              className={classes.formInput} 
              variant="contained" 
              color="primary"
              onClick={resetPasswordHandler}>Изпрати нова парола</Button>  
          </div>          
      </SimpleModal>
    </Paper>
    
  );
}

export default LoginPage;
