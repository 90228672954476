import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SimpleModal from '../../components/modals/SimpleModal';
import { Grid, TextField, CircularProgress, Paper, Button, Typography, FormControl, Select, InputLabel, MenuItem, Input, AppBar, Tabs, Tab } from '@material-ui/core';
import axios from '../../util/Axios/Axios';
import { RedButton } from '../../components/buttons/RedButton';
import { GreenButton } from '../../components/buttons/GreenButton';
import noimage from '../../assets/noimage.png';
import { BASE_URL } from '../../util/Constants';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { useSelector } from 'react-redux';
import TabPanel from '../../components/TabPanel';
import Scrollbars from 'react-custom-scrollbars';


function InterviewPage(props) {

    const useStyles = makeStyles((theme) => ({

        profileImg : {
            borderRadius : '50%',
            height : '180px',
            width : '180px',
            objectFit: 'cover',
        },
        formInput: {
            width: "90%",
            margin: "auto",
            marginBottom : '16px',
            height: "45px",
            textAlign : 'center',
        },
        paperProfile: {
            padding: theme.spacing(4),
            textAlign: 'center',
            color: theme.palette.text.secondприary,
        },
        paperTech: {
            flexGrow : 0,
            padding: theme.spacing(1),
            textAlign: 'center',
            color: theme.palette.text.secondприary,
        },
        paperNotes: {
            marginTop : '12px',
            marginBottom : '12px',
            flexGrow : 9,
            padding: theme.spacing(1),
            textAlign: 'center',
            color: theme.palette.text.secondприary,
        },
        paperScore: {
            flexGrow : 1,
            padding: theme.spacing(1),
            textAlign: 'center',
            color: theme.palette.text.secondприary,
        },
        link : {
            textDecoration : 'none'
        },
        parent : {
            overflow: "hidden",
            position: "relative",
            width: "100%",
            minWidth : "800px",
        },
        childLeft : {
            height : "100%",
            width : "50%",
            padding : '12px',
        },
        childRight : {
            padding : '12px',
            height : "100%",
            width : "50%",
            position : "absolute",
            top : '0',
            right : '0',
            display : 'flex',
            flexFlow: 'column wrap',
            justifyContent : 'space-between',
        },
        centeredText : {
            textAlign : 'center'
        },
        textarea : {
            width : '100%',
            maxHeight : '250px',
            resize : 'vertical',
        }
      }));


    useEffect(()=>{
        axios.get('/users/'+props.interview.user_id)
        .then(response => {
            setProfile(response.data.data.user);
            setUserImage(response.data.data.user.image ? BASE_URL + response.data.data.user.image.file_url : noimage);
            response.data.data.recruitment_info.forEach(info => {
                if(info.season_id === props.season){
                    setRecruitmentInfo(info);
                }
            });
        })
        .catch(error=>{
        })
        axios.get('seasons/'+nextSeason.id+'/technologies').then(response => { setAllTechnologies(response.data.data)});
        axios.get('candidates/'+props.interview.candidate_id+'/survey').then(response => {setSurveyReport(response.data.data)});
        }, 
        //eslint-disable-next-line
    [])

    const classes = useStyles();
    const [profile, setProfile] = useState(null);
    const [allTechnologies, setAllTechnologies] = useState([]);
    const [recruitmentInfo, setRecruitmentInfo] = useState(null);
    const [surveyReport, setSurveyReport] = useState([]);
    const [userImage, setUserImage] = useState(noimage);
    const [desiredTech1, setDesiredTech1] = useState(props.interview.score ? props.interview.desired_tech_1 : "");
    const [desiredTech2, setDesiredTech2] = useState(props.interview.score ? props.interview.desired_tech_2 : "");
    const [notes, setNotes] = useState(props.interview.score ? props.interview.notes : "");
    const [score, setScore] = useState(props.interview.score ? props.interview.score : "");
    const nextSeason = useSelector(state => state.loginReducer.nextSeason);
    const scores = ['ДА', 'НЕ', 'МОЖЕ БИ', 'ОТКАЗАЛ СЕ'];


    const handleSaveInterview = () => {
        axios.post('interviews', {
            id : props.interview.score ? props.interview.interview_id : null,
            candidate_id : props.interview.candidate_id,
            slot_id : props.interview.slot_id,
            score : score,
            notes : notes,
            desired_tech_1_id : desiredTech1,
            desired_tech_2_id : desiredTech2
        })
        .then(response => {
            props.onClose(true);
        })
    }
    const [value, setValue] = useState(0);//tab selected value
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    function a11yProps(index) {
        return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <SimpleModal open={props.open} >
            {
                profile && recruitmentInfo ? (
                    <div className={classes.modal_root}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} >
                                <Typography variant="h4" className={classes.centeredText}>
                                    Интервю на кандидат {profile.full_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <AppBar position="static">
                                    <Tabs variant="fullWidth" value={value} onChange={handleChange} aria-label="interview-tabs">
                                        <Tab label="Лични данни" {...a11yProps(0)} />
                                        <Tab label="Интервю" {...a11yProps(1)} />
                                        <Tab label="Въпросник" {...a11yProps(2)} />
                                    </Tabs>
                                </AppBar>
                                <Scrollbars style={{ height: "60vh" }} renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}>
                                    <TabPanel value={value} index={0} >
                                        <Paper className={classes.paperProfile}>
                                            <Grid item xs={12}>
                                                <img className={classes.profileImg} src={userImage} alt='profile'></img>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                        className={classes.formInput}
                                                        label="Имейл"
                                                        value={profile.email}
                                                        disabled
                                                        type="text">                           
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                        className={classes.formInput}
                                                        label="Възраст"
                                                        value={recruitmentInfo.age + ' год.'}
                                                        disabled
                                                        type="text">                           
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                        className={classes.formInput}
                                                        label="Телефон"
                                                        value={profile.phone_number}
                                                        disabled
                                                        type="text">                           
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                        className={classes.formInput}
                                                        label="Скайп"
                                                        value={profile.skype_username}
                                                        disabled
                                                        type="text">                           
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                        className={classes.formInput}
                                                        label="Резултат от онлайн тест"
                                                        value={recruitmentInfo.online_test_score == null ? "Очаквайте скоро" : recruitmentInfo.online_test_global_position+ " място от 707 кандидата"}
                                                        disabled
                                                        type="text">                           
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                        className={classes.formInput}
                                                        label="Резултат от присъствен тест"
                                                        value={recruitmentInfo.attendance_test_score == null ? "Очаквайте скоро" : recruitmentInfo.attendance_test_global_position+ " място от 254 кандидата"}
                                                        disabled
                                                        type="text">                           
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <a className={classes.link} href={profile.cvFile ? BASE_URL + profile.cvFile.file_url : null} download target="_blank" rel="noopener noreferrer" >
                                                    <Button
                                                        className={classes.formInput} 
                                                        variant="contained" 
                                                        startIcon={<CloudDownloadIcon />}
                                                        disabled = {!profile.cvFile}
                                                    >
                                                    {profile.cvFile ? "Преглед на CV" : "Няма CV"}
                                                    </Button>
                                                </a>
                                            </Grid>
                                        </Paper>
                                    </TabPanel>
                                    <TabPanel value={value} index={1} >
                                            <Paper className={classes.paperTech}>
                                                <Grid item xs={12}>
                                                    <FormControl className={classes.formInput}>
                                                        <InputLabel>Предпочитана технология 1</InputLabel>
                                                        <Select
                                                            labelId="desired-technology-label-1"
                                                            id="desired-technology-1"
                                                            value={desiredTech1}
                                                            required
                                                            onChange={(event) => setDesiredTech1(event.target.value)}
                                                            input={<Input />}
                                                            >
                                                            {allTechnologies.map((tech) => (
                                                                <MenuItem key={tech.id} value={tech.id}>
                                                                    {tech.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl className={classes.formInput}>
                                                        <InputLabel>Предпочитана технология 2</InputLabel>
                                                        <Select
                                                            labelId="desired-technology-label-2"
                                                            id="desired-technology-2"
                                                            value={desiredTech2}
                                                            required
                                                            onChange={(event) => setDesiredTech2(event.target.value)}
                                                            input={<Input />}
                                                            >
                                                            {allTechnologies.map((tech) => (
                                                                <MenuItem key={tech.id} value={tech.id}>
                                                                    {tech.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Paper>
                                            <Paper className={classes.paperNotes}>
                                                <Grid item xs={12}>
                                                    <Typography component="p">Бележки</Typography>
                                                    <textarea
                                                        rows="10" 
                                                        className={classes.textarea}
                                                        onChange={(event) => setNotes(event.target.value)}
                                                        value={notes}
                                                        >
                                                    </textarea>
                                                </Grid>
                                            </Paper>
                                            <Paper className={classes.paperScore}>
                                            <Grid item xs={12}>
                                                <FormControl className={classes.formInput}>
                                                    <InputLabel>Резултат от интервюто</InputLabel>
                                                    <Select
                                                        labelId="desired-technology-label-1"
                                                        id="desired-technology-1"
                                                        value={score}
                                                        required
                                                        onChange={(event) => setScore(event.target.value)}
                                                        input={<Input />}
                                                        >
                                                        {scores.map((score) => (
                                                            <MenuItem key={score} value={score}>
                                                                {score}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Paper>
                                    </TabPanel>
                                    <TabPanel value={value} index={2} >
                                        {
                                            surveyReport.map(entry => (
                                                    <>
                                                        <p>{entry.question}</p> 
                                                        <p><strong>{entry.answer}</strong></p> 
                                                        <hr></hr>
                                                    </>
                                            ))
                                        }
                                    </TabPanel>
                                </Scrollbars>
                            </Grid>
                            <Grid item xs={6} className={classes.centeredText}>
                                <GreenButton className={classes.formInput} onClick={() => handleSaveInterview()}>
                                    Запази
                                </GreenButton>
                            </Grid>
                            <Grid item xs={6} className={classes.centeredText}>
                                <RedButton className={classes.formInput} onClick={() => props.onClose(false)}>
                                    Откажи
                                </RedButton>
                            </Grid>
                        </Grid>
                    </div>
                ) : <CircularProgress />
            }
        </SimpleModal>
    )
}

export default InterviewPage;