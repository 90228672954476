import React, { PureComponent } from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';


export default class CustomStackedBarChart extends PureComponent {
  static jsfiddleUrl = 'https://jsfiddle.net/alidingling/90v76x08/';

  render() {
    return (
      <BarChart
          style={{margin:"auto"}}
            width={this.props.width}
            height={300}
            data={this.props.data}
            margin={{
            top: 20, right: 30, left: 20, bottom: 5,
            }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={this.props.x_label} />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey={this.props.y_label} stackId="a" fill="#8884d8" />
      </BarChart>
    );
  }
}
