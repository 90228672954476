import * as actions from "../../store/actions/generalActions";

const initialState = {
    successToastOpen : false,
    errorToastOpen : false,
    successMessage : '',
    errorMessage : '',
    showMenu : localStorage["auth"],
    pageSelected : null,
    seasonViewedId : null,
    showLoadingDiv : false,
}

const generalReducer = (state = initialState, action) => {
    switch(action.type){
        case actions.OPEN_LOGIN_PAGE:                   {return {...state,pageSelected : actions.OPEN_LOGIN_PAGE}}
        case actions.OPEN_REGISTER_PAGE:                {return {...state,pageSelected : actions.OPEN_REGISTER_PAGE}}
        case actions.MENU_OPEN_MY_HOME:                 {return {...state,pageSelected : actions.MENU_OPEN_MY_HOME}}
        case actions.MENU_OPEN_MY_PROFILE:              {return {...state,pageSelected : actions.MENU_OPEN_MY_PROFILE}}
        case actions.MENU_OPEN_MY_INBOX:                {return {...state,pageSelected : actions.MENU_OPEN_MY_INBOX}}
        case actions.MENU_OPEN_MY_TEST_PREPARATION:     {return {...state,pageSelected : actions.MENU_OPEN_MY_TEST_PREPARATION}}
        case actions.MENU_OPEN_MY_TEST_RESULTS:         {return {...state,pageSelected : actions.MENU_OPEN_MY_TEST_RESULTS}}
        case actions.MENU_OPEN_MY_FAQ:                  {return {...state,pageSelected : actions.MENU_OPEN_MY_FAQ}}
        case actions.MENU_OPEN_INTERVIEWS:              {return {...state,pageSelected : actions.MENU_OPEN_INTERVIEWS}}
        case actions.MENU_OPEN_SEASONS_ADD:             {return {...state,pageSelected : actions.MENU_OPEN_SEASONS_ADD}}
        case actions.MENU_OPEN_SEASONS_VIEW:            {return {...state,pageSelected : actions.MENU_OPEN_SEASONS_VIEW}}
        case actions.MENU_OPEN_AUTO_NOTIFICATIONS:      {return {...state,pageSelected : actions.MENU_OPEN_AUTO_NOTIFICATIONS}}
        case actions.MENU_OPEN_MANUAL_NOTIFICATIONS:    {return {...state,pageSelected : actions.MENU_OPEN_MANUAL_NOTIFICATIONS}}
        case actions.MENU_OPEN_COMPANIES_ADD:           {return {...state,pageSelected : actions.MENU_OPEN_COMPANIES_ADD}}
        case actions.MENU_OPEN_COMPANIES_VIEW:          {return {...state,pageSelected : actions.MENU_OPEN_COMPANIES_VIEW}}
        case actions.MENU_OPEN_CADETS_RECRUITMENT:      {return {...state,pageSelected : actions.MENU_OPEN_CADETS_RECRUITMENT}}
        case actions.MENU_OPEN_CADETS_IN_SEASON:        {return {...state,pageSelected : actions.MENU_OPEN_CADETS_IN_SEASON}}
        case actions.MENU_OPEN_STATISTICS:              {return {...state,pageSelected : actions.MENU_OPEN_STATISTICS}}
        case actions.OPEN_SURVEY_PAGE:                  {return {...state,pageSelected : actions.OPEN_SURVEY_PAGE}}
        case actions.OPEN_SEASON_SETTINGS_PAGE:         {return {...state,pageSelected : actions.OPEN_SEASON_SETTINGS_PAGE, seasonViewedId : action.seasonViewedId}}
        case actions.SHOW_LOADING_DIV:                  {return {...state,showLoadingDiv : true}}
        case actions.HIDE_LOADING_DIV:                  {return {...state,showLoadingDiv : false}}
        case actions.SHOW_SUCCESS_TOAST:
            return {
                ...state,
                successToastOpen : true,
                successMessage : action.msg
            }
        case actions.HIDE_SUCCESS_TOAST:
            return {
                ...state,
                successToastOpen : false
            }
        case actions.SHOW_ERROR_TOAST:
            return {
                ...state,
                errorToastOpen : true,
                errorMessage : action.msg
            }
        case actions.HIDE_ERROR_TOAST:
            return {
                ...state,
                errorToastOpen : false
            }
        case actions.SHOW_MENU:
            return {
                ...state,
                showMenu : true
            }
        case actions.HIDE_MENU:
            return {
                ...state,
                showMenu : false
            }
        default: 
            return state;
    }
}

export default generalReducer;