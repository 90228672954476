import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from '../../../util/Axios/Axios';
import AutomaticStatusEdit from './AutomaticStatusEdit';


function AutomaticStatusesPage(props) {

    const useStyles = makeStyles((theme) => ({
        root: {
          width: '100%',
        },
        heading: {
          fontSize: theme.typography.pxToRem(15),
          flexBasis: '33.33%',
          flexShrink: 0,
        },
      }));

    const classes = useStyles();
    const [statuses, setStatuses] = useState([]);
    const [expanded, setExpanded] = useState(false);
   
    
    useEffect(()=>{
        axios.get('/notifications/statuses').then(response => {setStatuses(response.data.data);})}, 
        //eslint-disable-next-line
    [])
    
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className={classes.root}>
            {
                statuses.map((status) => (
                    <Accordion key={status.id} expanded={expanded === 'panel'+status.id} onChange={handleChange('panel'+status.id)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography className={classes.heading}>{status.occasion}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AutomaticStatusEdit status={status}></AutomaticStatusEdit>
                        </AccordionDetails>
                    </Accordion>
            ))
            }
        </div>
    )
}

export default AutomaticStatusesPage;