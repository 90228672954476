import React, { useState, useEffect } from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { TextField, Grid, makeStyles, Paper, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, FormControl, InputLabel, Select, MenuItem, Tabs, AppBar, Tab } from '@material-ui/core';
import { GreenButton } from '../../../components/buttons/GreenButton';
import axios from '../../../util/Axios/Axios';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import EmailIcon from '@material-ui/icons/Email';
import SmsIcon from '@material-ui/icons/SmsFailed';
// import NotificationIcon from '@material-ui/icons/Notifications';
import { Scrollbars } from 'react-custom-scrollbars';
import {PRIMARY_BLUE, SMS_MAX_LENGTH, GROUP_ONLINE_TEST_ADMITTED, GROUP_ATTENDANCE_TEST_ADMITTED, GROUP_ATTENDANCE_TEST_ADMITTED_WITH_SLOT, GROUP_ATTENDANCE_TEST_ADMITTED_WITHOUT_SLOT, GROUP_INTERVIEW_ADMITTED, GROUP_INTERVIEW_ADMITTED_WITH_SLOT, GROUP_INTERVIEW_ADMITTED_WITHOUT_SLOT, GROUP_CONTRACTS_ADMITTED, GROUP_CONTRACTS_ADMITTED_WITH_SLOT, GROUP_CONTRACTS_ADMITTED_WITHOUT_SLOT, GROUP_IN_COURSE_GROUP, GROUP_IN_COURSES, GROUP_IDS_SEPARATOR} from '../../../util/Constants';
import TabPanel from '../../../components/TabPanel';
import { useSelector } from 'react-redux';

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

function ManualNotificationsPage(props) {

    const useStyles = makeStyles(theme => ({
        formInput: {
          width: "100%",
          margin: "auto",
          marginTop : '16px',
          marginBottom : '16px',
          height: "45px",
          textAlign : 'center',
        },
        centerdItems : {
            textAlign : "center",
        },
        paper : {
            padding : '16px',
        },
        listContainer : {
            height : '200px',
            minHeight : '200px',
        },
        h4 : {
            margin : '0px',
        },
        included : {
            backgroundColor : 'lightgreen'
        },
        root: {
            flexGrow: 1,
            textAlign : 'center',
            backgroundColor: theme.palette.background.paper,
        },
        tabpanel : {
            backgroundColor : PRIMARY_BLUE
        },
        textarea : {
            width : '60%',
            maxHeight : '250px',
            resize : 'vertical',
        }
    }));

    const [chosenTabId, setChosenTabId] = useState(0);

    const handleChange = (event, newValue) => {
        setChosenTabId(newValue);
    };

    const classes = useStyles();

    const [length, setLength] = useState(0);
    const [users, setUsers] = useState([]);
    const [userSearchValue, setUserSearchValue] = useState("");
    const [receivers, setReceivers] = useState([]);
    const [receiverGroup, setReceiverGroup] = useState("");
    
    const nextSeason = useSelector(state => state.loginReducer.nextSeason);
    const [receiverGroups, setReceiverGroups] = useState({});

    const [emailSubject, setEmailSubject] = useState("");
    const [emailBody, setEmailBody] = useState("");
    
    const [smsBody, setSmsBody] = useState("");

    const [msgSubject, setMsgSubject] = useState("");
    const [msgBody, setMsgBody] = useState("");

    const [disableSendEmails, setDisableSendEmails] = useState(false);

    useEffect(()=>{
            calculateReceiverGroups()
        },
        //eslint-disable-next-line
    [])

    const calculateReceiverGroups = () => {
        const getAttTestSlots =     axios.get("seasons/"+nextSeason.id+"/attendance/slots");
        const getInterviewSlots =   axios.get("seasons/"+nextSeason.id+"/interview/slots");
        const getContractSlots =    axios.get("seasons/"+nextSeason.id+"/contract/slots");
        const getCourses =          axios.get("seasons/"+nextSeason.id+"/courses");
        Promise.all([getAttTestSlots, getInterviewSlots, getContractSlots, getCourses])
        .then( responses => {
            const attendanceTestSlots = responses[0];
            const interviewSlots = responses[1];
            const contractSlots = responses[2];
            const courses = responses[3];
            
            const receiverGroups = {};
            receiverGroups[GROUP_ONLINE_TEST_ADMITTED] = "Допуснати до онлайн тест - всички";
            //attendance test
            receiverGroups[GROUP_ATTENDANCE_TEST_ADMITTED] = "Допуснати до присъствен тест - всички";
            attendanceTestSlots.data.data.forEach(slot => {
                receiverGroups[GROUP_ATTENDANCE_TEST_ADMITTED_WITH_SLOT+GROUP_IDS_SEPARATOR+slot.id] = "Допуснати до присъствен тест - слот от " + slot.date
            });
            receiverGroups[GROUP_ATTENDANCE_TEST_ADMITTED_WITHOUT_SLOT] = "Допуснати до присъствен тест - без избран слот";
            //interview
            receiverGroups[GROUP_INTERVIEW_ADMITTED] = "Допуснати до интервю - всички";
            interviewSlots.data.data.forEach(slot => {
                receiverGroups[GROUP_INTERVIEW_ADMITTED_WITH_SLOT+GROUP_IDS_SEPARATOR+slot.id] = "Допуснати до интервю - слот от " + slot.start_time + " при " + slot.interviewer.full_name
            });
            receiverGroups[GROUP_INTERVIEW_ADMITTED_WITHOUT_SLOT] = "Допуснати до интервю - без избран слот";
            //contract
            receiverGroups[GROUP_CONTRACTS_ADMITTED] = "Допуснати до договор - всички";
            contractSlots.data.data.forEach(slot => {
                receiverGroups[GROUP_CONTRACTS_ADMITTED_WITH_SLOT+GROUP_IDS_SEPARATOR+slot.id] = "Допуснати до договор - слот от " + slot.date
            });
            receiverGroups[GROUP_CONTRACTS_ADMITTED_WITHOUT_SLOT] = "Допуснати до договор - без избран слот";
            //courses
            receiverGroups[GROUP_IN_COURSES] = "Приети в сезона - всички";
            courses.data.data.forEach(course => {
                receiverGroups[GROUP_IN_COURSE_GROUP+GROUP_IDS_SEPARATOR+course.id] = "Привети в сезона - група " + course.name
            });
            setReceiverGroups(receiverGroups);
        });
    }

    const handleFilterUsers = (text) => {
        setUserSearchValue(text);
        axios.post('/users/filter', {
            text : text
        })
        .then( response => {
            setUsers(response.data.data);
        })
    }

    const handleAddReceiver = (id) => {
        users.forEach(user => {
            if(user.id === id){
                if(!receivers.includes(user)){
                    setReceivers([...receivers, user]);
                }
            }
        });
    }

    const handleRemoveReceiver = (id) => {
        receivers.forEach(user => {
            if(user.id === id){
                receivers.splice(receivers.indexOf(user), 1);
                setReceivers([...receivers]);
            }
        });
    }

    const handleAddReceiverGroup = (id) => {
        setReceiverGroup("");
        if(!receivers.includes(receiverGroups[id])){
            setReceivers([...receivers, {id : id, desc : receiverGroups[id]}]);
        }
    }

    const getReceiverIds = () => {
        return receivers.map(receiver => receiver.id);
    }

    const handleSendEmail = () => {
        const receivers = getReceiverIds();
        setDisableSendEmails(true);
        axios.post('/notifications/emails', {
            receivers : receivers,
            subject : emailSubject,
            body : emailBody
        })
        .then(response => {
            setDisableSendEmails(false);
        })
    }

    const handleSendSMS = () => {
        const receivers = getReceiverIds();
        setDisableSendEmails(true);
        axios.post('/notifications/sms', {
            receivers : receivers,
            subject : "",
            body : smsBody
        })
        .then(response => {
            setDisableSendEmails(false);
        })
    }

    const handleSendMessage = () => {
        const receivers = getReceiverIds();
        setDisableSendEmails(true);
        axios.post('/notifications/message', {
            receivers : receivers,
            subject : emailSubject,
            body : emailBody
        })
        .then(response => {
            setDisableSendEmails(false);
        })
    }


    return (
        <Paper className={classes.paper}>
            <Grid container spacing={1} >
                <Grid item xs={12} className={classes.centerdItems}>
                    <h3>Избери получатели</h3>
                </Grid>
                <Grid item xs={6} className={classes.centerdItems}>
                    <TextField
                        className={classes.formInput}
                        label="Търси получател по име / мейл / телефон"
                        value={userSearchValue}
                        type="text"
                        onChange={(event) => handleFilterUsers(event.target.value)}
                    />
                </Grid>
                <Grid item xs={6} className={classes.centerdItems}>
                    <FormControl className={classes.formInput}>
                        <InputLabel>Избери множество получатели</InputLabel>
                        <Select
                            value={receiverGroup}
                            onChange={(event)=>{handleAddReceiverGroup(event.target.value)}}
                        >
                        {
                            Object.keys(receiverGroups).map(groupIdx => (
                            <MenuItem key={groupIdx} value={groupIdx}>{receiverGroups[groupIdx]}</MenuItem>
                            ))
                        }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} className={classes.centerdItems}>
                    <Paper className={classes.listContainer}>
                        {
                            users.length === 0 ? (
                                <h4 className={classes.h4}>Няма филтрирани потребители</h4>
                            ) : (
                                <Scrollbars style={{ height: "200px" }} renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}>
                                    <h4 className={classes.h4}>Резултати от търсенето</h4>
                                    <List>
                                        {
                                            users.map(user => {
                                                return (
                                                    <ListItem key={user.id} dense button onClick={() => handleAddReceiver(user.id)} className={receivers.includes(user) ? classes.included : ""}>
                                                        <ListItemText primary={user.desc} />
                                                        <ListItemSecondaryAction>
                                                            <IconButton edge="end" disabled >
                                                                <AddCircleIcon></AddCircleIcon>
                                                            </IconButton>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                )
                                            })
                                        }
                                    </List>
                                </Scrollbars>
                            )
                        }
                        
                    </Paper>
                </Grid>
                <Grid item xs={6} className={classes.centerdItems}>
                    <Paper className={classes.listContainer}>
                        {
                            receivers.length === 0 ? (
                                <h4 className={classes.h4}>Няма избрани получатели</h4>
                            ) : (
                                <Scrollbars style={{ height: "200px" }} renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}>
                                    <h4 className={classes.h4}>Изпрати до</h4>
                                    <List>
                                        {
                                            receivers.map(user => {
                                                return (
                                                    <ListItem key={user.id} dense button onClick={() => handleRemoveReceiver(user.id)}>
                                                        <ListItemText primary={user.desc} />
                                                        <ListItemSecondaryAction>
                                                            <IconButton edge="end" disabled>
                                                                <RemoveCircleIcon></RemoveCircleIcon>
                                                            </IconButton>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                )
                                            })
                                        }
                                    </List>
                                </Scrollbars>
                            )
                        }
                        
                    </Paper>
                </Grid>
                <Grid item xs={6} className={classes.centerdItems}>
                    
                </Grid>
                <Grid item xs={12} className={classes.centerdItems}>
                    <hr></hr>
                </Grid>
                
                <Grid item xs={12} className={classes.centerdItems}>
                    <AppBar position="static" color="default">
                        <Tabs 
                        variant="fullWidth" 
                        value={chosenTabId} 
                        onChange={handleChange} 
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="simple tabs example">
                            <Tab label="Изпрати мейл" icon={<EmailIcon />} {...a11yProps(0)} />
                            <Tab label="Изпрати смс" icon={<SmsIcon />} {...a11yProps(1)} />
                            {/* <Tab label="Изпрати съобщение" icon={<NotificationIcon />}  {...a11yProps(2)} /> */}
                        </Tabs>
                    </AppBar>
                    <TabPanel value={chosenTabId} index={0}>
                        <Grid container>
                            <Grid item xs={12} className={classes.centerdItems}>
                                <h4>Напиши мейл</h4>
                            </Grid>
                            <Grid item xs={12} className={classes.centerdItems}>
                                <TextField
                                    className={classes.formInput}
                                    label="Заглавие"
                                    value={emailSubject}
                                    type="text"
                                    onChange={(event) => setEmailSubject(event.target.value)}
                                    />
                            </Grid>
                            <Grid item xs={12} className={classes.centerdItems}>
                                <CKEditor
                                    className={classes.formInput}
                                    editor={ ClassicEditor }
                                    data={emailBody}  
                                    onChange={ ( event, editor ) => {
                                        setEmailBody(editor.getData());
                                    } }
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.centerdItems}>
                                <GreenButton
                                    className={classes.formInput}
                                    onClick = {() => handleSendEmail()}
                                    disabled = {disableSendEmails}>
                                    Изпрати имейл
                                </GreenButton>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={chosenTabId} index={1}>
                        <Grid container >
                            <Grid item xs={12} className={classes.centerdItems}>
                                <h4>Напиши смс</h4>
                            </Grid>
                            <Grid item xs={12} className={classes.centerdItems}>
                                <textarea
                                    maxLength={SMS_MAX_LENGTH}
                                    rows="5" 
                                    className={classes.textarea}
                                    onChange={(event) => {
                                        setSmsBody(event.target.value);
                                        setLength(event.target.value.length);
                                    }}
                                    onKeyPress = { (e) => {
                                        var verified = String.fromCharCode(e.which).match(/[\w[\]`!@#$%^&*()={}:;<>+ ,?.'//-]/);
                                            if (!verified) {
                                                e.preventDefault();
                                            }
                                        }
                                    }
                                    value={smsBody}
                                >
                                </textarea>
                            </Grid>
                            <Grid item xs={12} className={classes.centerdItems}>
                                <p>Дължина: {length} / {SMS_MAX_LENGTH} символа</p>
                            </Grid>
                            <Grid item xs={12} className={classes.centerdItems}>
                                <GreenButton
                                    className={classes.formInput}
                                    onClick = {() => handleSendSMS()}>
                                    Изпрати СМС
                                </GreenButton>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={chosenTabId} index={2}>
                        <Grid container>
                            <Grid item xs={12} className={classes.centerdItems}>
                                <h4>Напиши съобщение</h4>
                            </Grid>
                            <Grid item xs={12} className={classes.centerdItems}>
                                <TextField
                                    className={classes.formInput}
                                    label="Заглавие"
                                    value={msgSubject}
                                    type="text"
                                    onChange={(event) => setMsgSubject(event.target.value)}
                                    />
                            </Grid>
                            <Grid item xs={12} className={classes.centerdItems}>
                                <CKEditor
                                    className={classes.formInput}
                                    editor={ ClassicEditor }
                                    data={msgBody}  
                                    onChange={ ( event, editor ) => {
                                        setMsgBody(editor.getData());
                                    } }
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.centerdItems}>
                                <GreenButton
                                    className={classes.formInput}
                                    onClick = {() => handleSendMessage()}>
                                    Изпрати съобщение
                                </GreenButton>
                            </Grid>
                        </Grid>
                    </TabPanel>
                </Grid>
                
            </Grid>
        </Paper>
    )
}

export default ManualNotificationsPage;