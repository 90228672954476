import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Select, InputLabel, FormControl, MenuItem } from '@material-ui/core';
import axios from '../../util/Axios/Axios';
import { useSelector } from 'react-redux';
import CandidatesInterviewView from './CandidatesInterviewView';
import CandidatesOnlineTestView from './CandidatesOnlineTestView';
import CandidatesAttendanceTestView from './CandidatesAttendanceTestView';
import CandidatesPreliminaryView from './CandidatesPreliminaryView';
import TabPanel from '../../components/TabPanel';
import CandidatesContractView from './CandidatesContrartView';


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign : 'center',
    backgroundColor: theme.palette.background.paper,
  },
  filterInput : {
    width: "80%",
    margin: "auto",
    marginTop : '16px',
    marginBottom : '16px',
    height: "45px",
    textAlign : 'center',
  },
}));

function CandidatesRecruitmentPage() {
  const classes = useStyles();

  const [chosenTabId, setChosenTabId] = useState(0);

  useEffect(() => {
    axios.get('/seasons').then(response => setSeasons(response.data.data));
    //eslint-disable-next-line
  }, []);

  const nextSeason = useSelector(state => state.loginReducer.nextSeason)
  const [filteredSeason, setFilteredSeason] = useState(nextSeason ? nextSeason.id : null);
  const [seasons, setSeasons] = useState([]);

  const handleChange = (event, newValue) => {
    setChosenTabId(newValue);
  };

  return (
    <div className={classes.root}>
      <FormControl className={classes.filterInput}>
        <InputLabel>Сезон</InputLabel>
        <Select
            value={filteredSeason}
            onChange={(event)=>{setFilteredSeason(event.target.value)}}
        >
          <MenuItem value={""}>Всички</MenuItem>
          {
            seasons.map(season => (
              <MenuItem key={season.id} value={season.id}>{season.name}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
      <AppBar position="static">
        <Tabs variant="fullWidth" value={chosenTabId} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Предварителен подбор" {...a11yProps(0)} />
          <Tab label="Онлайн тест" {...a11yProps(1)} />
          <Tab label="Присъствен тест" {...a11yProps(2)} />
          <Tab label="Интервюта" {...a11yProps(3)} />
          <Tab label="Договори" {...a11yProps(4)} />
        </Tabs>
      </AppBar>
      <TabPanel value={chosenTabId} index={0}>
        <CandidatesPreliminaryView season = {filteredSeason}></CandidatesPreliminaryView>
      </TabPanel>
      <TabPanel value={chosenTabId} index={1}>
        <CandidatesOnlineTestView season = {filteredSeason}></CandidatesOnlineTestView>
      </TabPanel>
      <TabPanel value={chosenTabId} index={2}>
        <CandidatesAttendanceTestView season = {filteredSeason}></CandidatesAttendanceTestView>
      </TabPanel>
      <TabPanel value={chosenTabId} index={3}>
        <CandidatesInterviewView season = {filteredSeason}></CandidatesInterviewView>
      </TabPanel>
      <TabPanel value={chosenTabId} index={4}>
        <CandidatesContractView season = {filteredSeason}></CandidatesContractView>
      </TabPanel>
    </div>
  );
}

export default CandidatesRecruitmentPage;