import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from '../../util/Axios/Axios';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TextField, Button, IconButton } from '@material-ui/core';
import SimpleModal from '../../components/modals/SimpleModal';
import DateTimeField from '../../components/DateTimeField';
import { Scrollbars } from 'react-custom-scrollbars';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Aux from '../../hoc/Auxiliary/Auxiliary';


function AttendanceTestSlotsPage(props) {

    const useStyles = makeStyles(theme => ({
        formInput: {
            width: "80%",
            margin: "auto",
            marginTop : '16px',
            minWidth : '350px',
            marginBottom : '16px',
            height: "45px",
            textAlign : 'center',
        },
        modalContainer : {
          width : '100%',
          display : 'flex',
          'flex-direction' : 'column',
          'justify-content' : 'center',
          'align-items' : 'center',
        }
    }));

    useEffect(()=>{
        loadSlots();
    },
        //eslint-disable-next-line
    [])

    const loadSlots = () => {
        axios.get('/seasons/'+props.seasonId+'/attendance/slots')
        .then(response => {
            const slots = response.data.data;
            let total = 0;
            let taken = 0;
            slots.forEach(slot => {
                total += slot.max_slots;
                taken += slot.slots_taken;
            });
            setTotalAvailableSlots(total);
            setTotalTakenSlots(taken);
            setSlots(response.data.data);

        })
    }

    const classes = useStyles();
    const [slots, setSlots] = useState([]);
    const [totalAvailableSlots, setTotalAvailableSlots] = useState(0);
    const [totalTakenSlots, setTotalTakenSlots] = useState(0);
    const [date, setDate] = useState(new Date());
    const [maxSlots, setMaxSlots] = useState("");
    const [openAddSlot, setOpenAddSlot] = useState(false);
    const [openEditSlot, setOpenEditSlot] = useState(false);
    const [editedSlotId, setEditedSlotId] = useState(null);
    const [editDate, setEditDate] = useState("");
    const [editMaxSlots, setEditMaxSlots] = useState("");

    const handleSaveSlot = () => {
        axios.post('/seasons/'+ props.seasonId+'/attendance/slots', {
            date : date,
            max_slots : maxSlots
        })
        .then(response => {
            loadSlots();
            setOpenAddSlot(false);
            setDate(null);
            setMaxSlots("");
        });
    }

    const handleEditSlot = () => {
        axios.put('/seasons/'+ props.seasonId+'/attendance/slots', {
            id : editedSlotId,
            date : editDate,
            max_slots : editMaxSlots
        })
        .then(response => {
            loadSlots();
            setOpenEditSlot(false);
        });
    }

    const handleDeleteSlot = (slotId) => {
        axios.delete('/seasons/'+ props.seasonId+'/attendance/slots', {
            data : {
                id : slotId
            }
        })
        .then(response => {
            loadSlots();
        });
    }

    const modalCloseHandler = () => {
        setOpenAddSlot(false);
        setOpenEditSlot(false);
    }

    return (
        <Aux>
            <Paper>
                <h1>Слотове за присъствен тест (Общо {totalAvailableSlots} слота, {totalTakenSlots} заети, {totalAvailableSlots - totalTakenSlots} свободни)</h1>
            </Paper>
            <Scrollbars style={{ height: "30vh" }} renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                        <TableRow>
                            <TableCell>Дата</TableCell>
                            <TableCell >Общо места</TableCell>
                            <TableCell >Заети</TableCell>
                            <TableCell >Свободни</TableCell>
                            <TableCell >Редакция</TableCell>
                            <TableCell >Изтриване</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {slots.map(slot => (
                            <TableRow key={slot.id}>
                                <TableCell >{slot.date}</TableCell>
                                <TableCell >{slot.max_slots}</TableCell>
                                <TableCell >{slot.slots_taken}</TableCell>
                                <TableCell >{slot.max_slots - slot.slots_taken}</TableCell>
                                <TableCell >
                                    <IconButton onClick={()=> { 
                                        setEditedSlotId(slot.id); 
                                        setEditDate(slot.date); 
                                        setEditMaxSlots(slot.max_slots);
                                        setOpenEditSlot(true);}}>
                                        <EditIcon/>
                                    </IconButton>
                                </TableCell>
                                <TableCell ><IconButton onClick={()=>handleDeleteSlot(slot.id)}><DeleteIcon/></IconButton></TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <SimpleModal open={openAddSlot} onClose={modalCloseHandler} >
                    <div className={classes.modalContainer}>
                        <h2>Нов слот за присъствен тест</h2>
                        <DateTimeField
                            className={classes.formInput}
                            label="Дата"
                            onChange={date => {setDate(date);}}
                            value={date}>
                        </DateTimeField>
                        <TextField
                            value={maxSlots}
                            onChange={(event)=> setMaxSlots(event.target.value)}
                            className={classes.formInput}
                            label="Места"
                            type="number"
                        />
                        <Button
                        className={classes.formInput} 
                        variant="contained" 
                        color="primary"
                        onClick={handleSaveSlot}>Запази</Button>  
                    </div>       
                </SimpleModal>
                <SimpleModal open={openEditSlot} onClose={modalCloseHandler} >
                    <div className={classes.modalContainer}>
                        <h2>Редакция на слот</h2>
                        <DateTimeField
                            className={classes.formInput}
                            label="Дата"
                            onChange={date => setEditDate(date)}
                            value={editDate}>
                        </DateTimeField>
                        <TextField
                            className={classes.formInput}
                            label="Места"
                            onChange={(event)=> setEditMaxSlots(event.target.value)}
                            value={editMaxSlots}
                            type="number"
                        />
                        <Button
                        className={classes.formInput} 
                        variant="contained" 
                        color="primary"
                        onClick={handleEditSlot}>Запази</Button>  
                    </div>
                </SimpleModal>
            </Scrollbars>
            <Button 
                variant="contained"
                color="primary" 
                onClick={()=> setOpenAddSlot(true)}>
                    Добави слот
            </Button>
        </Aux>
    );

}

export default AttendanceTestSlotsPage;