import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { RedButton } from '../../components/buttons/RedButton';
import SimpleModal from '../../components/modals/SimpleModal';
import { RedCheckBox } from '../../components/checkboxes/RedCheckBox';
import { FormControlLabel } from '@material-ui/core';

function RejectCandidateNoteModal (props) {

    const useRowStyles = makeStyles({
        modalContainer : {
          width : '100%',
          display : 'flex',
          'flex-direction' : 'column',
          'justify-content' : 'center',
          'align-items' : 'center',
        },
        formInput: {
          width: "80%",
          margin: "auto",
          marginTop : '16px',
          marginBottom : '16px',
        },
        red : {
          color : "red",
        },
      });

    const classes = useRowStyles();



    return (
        <SimpleModal open={props.openModal} onClose={props.modalCloseHandler} >
          <div className={classes.modalContainer}>
            <h3>Напишете причина за отхвърлянето</h3>
            <textarea
              rows='10'
              cols='40'
              required
              value={props.rejectionNote}
              onChange={(event)=> props.setRejectionNote(event.target.value)}
              label="Забележка"
              type="text"
            />
            
            <FormControlLabel className={classes.red}
              control={
                <RedCheckBox
                  checked={props.bannedForFutureSeasons}
                  onChange={()=> props.setBannedForFutureSeasons(!props.bannedForFutureSeasons)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              }
              label="Не допускай до бъдещи сезони"
            />
            
            <RedButton
              RedCheckBox={classes.formInput} 
              variant="contained" 
              color="primary"
              onClick={props.handleRejectCandidate}>Отхвърли кандидат</RedButton>  
          </div>          
      </SimpleModal>
    )
}

export default RejectCandidateNoteModal;

