import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import clsx from 'clsx';
import CadetActionsRow from './CadetActionsRow';

function CadetViewRow(props) {

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    }
  },
  green : {
    backgroundColor : 'lightgreen'
  }
});

  useEffect(()=>{
    setCadet(props.cadet);//every time this component receives new props, we update the state
  },[props.cadet])

  const [cadet, setCadet] = useState({});
  const [expandRow, setExpandRow] = useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow 
        className={clsx(classes.footer, {
          [classes.root]: true
      })}
      key={cadet.id} onClick={() => setExpandRow(!expandRow)}>
        <TableCell>
          <IconButton aria-label="expand candidate" size="small" onClick={() => setExpandRow(!expandRow)}>
            {expandRow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            {props.order}
          </IconButton>
        </TableCell>
        <TableCell >{props.index+1}</TableCell>
        <TableCell >{cadet.full_name}</TableCell>
        <TableCell >{cadet.email}</TableCell>
        <TableCell >{cadet.phone_number}</TableCell>
      </TableRow>
      <CadetActionsRow
        colSpan = {6}
        expandRow = {expandRow}
        cadet = {cadet}
      ></CadetActionsRow>
    </React.Fragment>
  );
}

export default CadetViewRow;