import React, { useState, useEffect } from 'react';
import axios from '../../util/Axios/Axios';
import { TableContainer, Table, TableRow, TableCell, Paper, TableHead, TableBody, FormControl, InputLabel, Select, MenuItem, TextField, makeStyles, Button, IconButton } from '@material-ui/core';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import Scrollbars from 'react-custom-scrollbars';
import SimpleModal from '../../components/modals/SimpleModal';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

function CoursesPage(props) {

  const useStyles = makeStyles(theme => ({
    formInput: {
        width: "80%",
        margin: "auto",
        marginTop : '16px',
        minWidth : '350px',
        marginBottom : '16px',
        height: "45px",
        textAlign : 'center',
    },
    modalContainer : {
      width : '100%',
      display : 'flex',
      'flex-direction' : 'column',
      'justify-content' : 'center',
      'align-items' : 'center',
    }
  }));

  useEffect(() => {
    loadCourses()
    axios.get("/users/trainers").then(response => {setTrainers(response.data.data);});
    axios.get("/seasons/"+props.seasonId+"/technologies").then(response => {setTechnologies(response.data.data);});
    //eslint-disable-next-line
  }, []);

  const loadCourses = () => {
    axios.get('/seasons/'+props.seasonId+'/courses')
        .then(response => {
            const courses = response.data.data;
            setCourses(courses)
        })
  }

  const [courses, setCourses] = useState([]);
  const [name, setName] = useState("");
  const [trainerId, setTrainerId] = useState("");
  const [technologyId, setTechnologyId] = useState("");
  const [maxSlots, setMaxSlots] = useState("");

  const [openAddCourse, setOpenAddCourse] = useState(false);
  const [openEditCourse, setOpenEditCourse] = useState(false);

  const [trainers, setTrainers] = useState([]);
  const [technologies, setTechnologies] = useState([]);
  
  const [editedCourseId, setEditedCourseId] = useState(null);
  const [editName, setEditName] = useState("");
  const [editTrainerId, setEditTrainerId] = useState("");
  const [editTechnologyId, setEditTechnologyId] = useState("");
  const [editMaxSlots, setEditMaxSlots] = useState("");

  const classes = useStyles();
  
  const handleAddCourse = () => {
    axios.post('/seasons/'+ props.seasonId+'/courses', {
        name : name,
        trainer : { id : trainerId },
        technology : { id : technologyId },
        max_slots : maxSlots
    })
    .then(response => {
        loadCourses();
        setOpenAddCourse(false);
        setName("");
        setTrainerId(null);
        setTechnologyId(null);
        setMaxSlots("");
    });
}

const handleEditCourse = () => {
    axios.put('/seasons/'+ props.seasonId+'/courses', {
        id : editedCourseId,
        name : editName,
        trainer : { id : editTrainerId },
        technology : { id : editTechnologyId },
        max_slots : editMaxSlots
    })
    .then(response => {
        loadCourses();
        setOpenEditCourse(false);
    });
}

const handleDeleteCourse = (courseId) => {
    axios.delete('/seasons/'+ props.seasonId+'/courses', {
        data : {
            id : courseId
        }
    })
    .then(response => {
        loadCourses();
    });
}

const modalCloseHandler = () => {
    setOpenAddCourse(false);
    setOpenEditCourse(false);
}

  return (<Aux>
            <Paper>
                <h1>Курсове</h1>
            </Paper>
            <Scrollbars style={{ height: "30vh" }} renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Наименование</TableCell>
                        <TableCell >Технология</TableCell>
                        <TableCell >Лектор</TableCell>
                        <TableCell >Редакция</TableCell>
                        <TableCell >Изтриване</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {courses.map(course => (
                        <TableRow key={course.id}>
                            <TableCell >{course.name}</TableCell>
                            <TableCell >{course.technology.name}</TableCell>
                            <TableCell >{course.trainer.full_name}</TableCell>
                            <TableCell >
                                    <IconButton onClick={()=> { 
                                        setEditedCourseId(course.id); 
                                        setEditName(course.name); 
                                        setEditTrainerId(course.trainer.id)
                                        setEditTechnologyId(course.technology.id)
                                        setEditMaxSlots(course.max_slots);
                                        setOpenEditCourse(true);}}>
                                        <EditIcon/>
                                    </IconButton>
                                </TableCell>
                                <TableCell ><IconButton onClick={()=>handleDeleteCourse(course.id)}><DeleteIcon/></IconButton></TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <SimpleModal open={openAddCourse} onClose={modalCloseHandler} >
              <div className={classes.modalContainer}>
                  <h2>Нов Курс</h2>
                  <TextField
                      value={name}
                      onChange={(event)=> setName(event.target.value)}
                      className={classes.formInput}
                      label="Наименование"
                      type="text"
                  />
                  <FormControl className={classes.formInput}>
                      <InputLabel>Технология</InputLabel>
                      <Select
                          value={technologyId}
                          onChange={(event)=>setTechnologyId(event.target.value)}
                      >
                      {
                          technologies.map(tech => (
                                  <MenuItem key={tech.id} value={tech.id}>{tech.name}</MenuItem>
                              )
                          )   
                      }
                      </Select>
                  </FormControl>
                  <FormControl className={classes.formInput}>
                      <InputLabel>Лектор</InputLabel>
                      <Select
                          value={trainerId}
                          onChange={(event)=>setTrainerId(event.target.value)}
                      >
                      {
                          trainers.map(trainer => (
                                  <MenuItem key={trainer.id} value={trainer.id}>{trainer.full_name}</MenuItem>
                              )
                          )   
                      }
                      </Select>
                  </FormControl>
                  <TextField
                      value={maxSlots}
                      onChange={(event)=> setMaxSlots(event.target.value)}
                      className={classes.formInput}
                      label="Места"
                      type="number"
                  />
                  <Button
                  className={classes.formInput} 
                  variant="contained" 
                  color="primary"
                  onClick={handleAddCourse}>Запази</Button>  
              </div>       
            </SimpleModal>
            <SimpleModal open={openEditCourse} onClose={modalCloseHandler} >
              <div className={classes.modalContainer}>
                  <h2>Редакция на курс</h2>
                  <TextField
                      value={editName}
                      onChange={(event)=> setEditName(event.target.value)}
                      className={classes.formInput}
                      label="Наименование"
                      type="text"
                  />
                  <FormControl className={classes.formInput}>
                      <InputLabel>Технология</InputLabel>
                      <Select
                          value={editTechnologyId}
                          onChange={(event)=>setEditTechnologyId(event.target.value)}
                      >
                      {
                          technologies.map(tech => (
                                  <MenuItem key={tech.id} value={tech.id}>{tech.name}</MenuItem>
                              )
                          )   
                      }
                      </Select>
                  </FormControl>
                  <FormControl className={classes.formInput}>
                      <InputLabel>Лектор</InputLabel>
                      <Select
                          value={editTrainerId}
                          onChange={(event)=>setEditTrainerId(event.target.value)}
                      >
                      {
                          trainers.map(trainer => (
                                  <MenuItem key={trainer.id} value={trainer.id}>{trainer.full_name}</MenuItem>
                              )
                          )   
                      }
                      </Select>
                  </FormControl>
                  <TextField
                      value={editMaxSlots}
                      onChange={(event)=> setEditMaxSlots(event.target.value)}
                      className={classes.formInput}
                      label="Места"
                      type="number"
                  />
                  <Button
                  className={classes.formInput} 
                  variant="contained" 
                  color="primary"
                  onClick={handleEditCourse}>Запази</Button>  
              </div>
            </SimpleModal>
          </Scrollbars>
          <Button 
              variant="contained"
              color="primary" 
              onClick={()=> setOpenAddCourse(true)}>
                  Добави курс
          </Button>
      </Aux>
  );
}

export default CoursesPage;
