import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from '../../util/Axios/Axios';
import { CircularProgress, Paper, Grid, TextField, Button, InputLabel, FormControl, Select, MenuItem } from '@material-ui/core';
import noimage from '../../assets/noimage.png';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { BASE_URL, USER_ROLE_ADMINISTRATOR_ID, USER_ROLE_HR_ID } from '../../util/Constants';
import { GreenButton } from '../../components/buttons/GreenButton';
import DateOfBirthPicker from '../../components/DateOfBirthPicker';
import PasswordIcon from '@material-ui/icons/Lock';
import SimpleModal from '../../components/modals/SimpleModal';
import { useSelector } from 'react-redux';

function ProfilePage(props) {

    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
        },
        paper: {
          padding: theme.spacing(4),
          textAlign: 'center',
          color: theme.palette.text.secondприary,
        },
        profileImg : {
            borderRadius : '50%',
            height : '280px',
            width : '280px',
            objectFit: 'cover',
        },
        gridCell : {
            textAlign : 'center'
        },
        gridCellLeft : {
            textAlign : 'left'
        },
        formInput: {
          width: "100%",
          margin: "auto",
          marginTop : '16px',
          marginBottom : '16px',
          height: "45px",
          textAlign : 'center',
        },
        hidden : {
            display : 'none'
        },
        link : {
            textDecoration : 'none'
        },
        system_status : {
            backgroundColor : 'yellow',
        },
        textarea : {
            width : '100%',
            maxWidth : '100%',
        }
    }));

    useEffect(()=>{
        getUserProfile()}, 
        //eslint-disable-next-line
    [])

    const classes = useStyles();
    const userId = props.userId;

    const getUserProfile = () => {
        axios.get('/users/'+userId)
        .then(response => {
            const data = response.data.data.user;
            setEmail(data.email);
            setFullName(data.full_name);
            setGender(data.gender ? data.gender : "");
            setDateOfBirth(data.date_of_birth);
            setPhoneNumber(data.phone_number);
            setSkype(data.skype_username);
            setZoom(data.zoom_account);
            setComments(data.comments);
            setUserImage(data.image ? BASE_URL + data.image.file_url : noimage);
            setUserCV(data.cvFile ? BASE_URL + data.cvFile.file_url : null);
        })
        .catch(error=>{
        });
    }
    const userRoleId = useSelector(state => state.loginReducer.userRoleId);

    const [email, setEmail] = useState("");
    const [fullName, setFullName] = useState("");
    const [gender, setGender] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState(new Date());
    const [phoneNumber, setPhoneNumber] = useState("");
    const [skype, setSkype] = useState("");
    const [zoom, setZoom] = useState("");
    const [comments, setComments] = useState("");
    const [editMode, setEditMode] = useState(false);
    const [userImage, setUserImage] = useState(noimage);
    const [userCV, setUserCV] = useState(null);

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPassword2, setNewPassword2] = useState("");
    const [openChangePassModal, setOpenChangePassModal] = useState(false);

    const handleChangePassword = () => {
        axios.put("users/changepass", {
            old_pass : oldPassword,
            new_pass : newPassword,
            new_pass2 : newPassword2
        })
        .then(response => {
            setOpenChangePassModal(false)
        });
    }
    
    const modalCloseHandler = (type) => {
        setOpenChangePassModal(false)
    };

    const handleUserImageUpload = event => {
        const photo = event.target.files[0];
        const formData = new FormData();
        formData.append("file", photo);
        axios.post("/users/"+userId+"/image", formData)
        .then(response => {
            setUserImage(BASE_URL + response.data.data.file_url);
        })
    }
    const handleUserCVUpload = event => {
        const photo = event.target.files[0];
        const formData = new FormData();
        formData.append("file", photo);
        axios.post("/users/"+userId+"/cv", formData)
        .then(response => {
            setUserCV(BASE_URL + response.data.data.file_url);
        })
    }

    const handleSaveUser = () => {
        axios.put('/users/'+ userId, {
            email : email,
            full_name : fullName,
            date_of_birth : dateOfBirth,
            gender : gender,
            phone_number : phoneNumber,
            skype_username : skype,
            zoom_account : zoom,
            comments : comments
        })
        .then(response => {
            setEditMode(false);
            if(props.updateCandidate){
                axios.get("/candidates/"+props.candidateId)
                .then(response => {
                    props.updateCandidate(response.data.data)
                })
                
            }
        })
    }

    const adminOrHR = () => {
        return userRoleId === USER_ROLE_ADMINISTRATOR_ID || userRoleId === USER_ROLE_HR_ID;
    }

    return (
        <div className={classes.root}>
            {email ? (
                <Paper className={classes.paper}>
                    <Grid container spacing={1}>
                        <Grid className={classes.gridCell} item md={4} sm={12}>
                            <img className={classes.profileImg} src={userImage} alt='profile'></img>
                        </Grid>
                        <Grid className={classes.gridCell} item md={8} sm={12}>
                            <Grid container spacing={1}>
                                <Grid item md={6} sm={12}>
                                <TextField
                                    className={classes.formInput}
                                    label="Имена"
                                    value={fullName}
                                    disabled={!editMode}
                                    type="text"
                                    onChange={(event) => setFullName(event.target.value)}
                                    />
                                </Grid>
                                <Grid item md={6} sm={12}>
                                <FormControl className={classes.formInput}>
                                    <InputLabel>Пол</InputLabel>
                                    <Select
                                        value={gender}
                                        disabled={!editMode}
                                        onChange={(event)=>setGender(event.target.value)}
                                    >
                                    <MenuItem value={'M'}>Мъж</MenuItem>
                                    <MenuItem value={'F'}>Жена</MenuItem>
                                    </Select>
                                </FormControl>
                                </Grid>
                                <Grid item md={6} sm={12}>
                                <TextField
                                    className={classes.formInput}
                                    label="Имейл"
                                    value={email}
                                    disabled={!editMode}
                                    type="text"
                                    onChange={(event) => setEmail(event.target.value)}
                                    />
                                </Grid>
                                <Grid item md={6} sm={12}>
                                <TextField
                                    className={classes.formInput}
                                    label="Телефонен номер"
                                    value={phoneNumber}
                                    disabled={!editMode}
                                    type="text"
                                    onChange={(event) => setPhoneNumber(event.target.value)}
                                    />
                                </Grid>
                                <Grid item md={6} sm={12}>
                                    <DateOfBirthPicker
                                        className={classes.formInput}
                                        label="Дата на раждане"
                                        onChange={date => {setDateOfBirth(date);}}
                                        disabled={!editMode}
                                        value={dateOfBirth}>
                                    </DateOfBirthPicker>
                                </Grid>
                                <Grid item md={6} sm={12}>
                                <TextField
                                    className={classes.formInput}
                                    label="Скайп"
                                    value={skype}
                                    disabled={!editMode}
                                    type="text"
                                    onChange={(event) => setSkype(event.target.value)}
                                    />
                                </Grid>
                                <Grid item md={6} sm={12}>
                                <TextField
                                    className={classes.formInput}
                                    label="Зуум Акаунт"
                                    value={zoom}
                                    disabled={!editMode}
                                    type="text"
                                    onChange={(event) => setZoom(event.target.value)}
                                    />
                                </Grid>
                                <Grid item md={6} sm={12}>
                                    <input
                                        accept="application/pdf, application/octet-stream"
                                        className={classes.hidden}
                                        id="contained-button-cv"
                                        multiple
                                        type="file"
                                        onChange={handleUserCVUpload}
                                    />
                                    <label htmlFor="contained-button-cv">
                                        <Button
                                            className={classes.formInput} 
                                            variant="contained" 
                                            color="primary"
                                            component="span"
                                            disabled = {!props.canEdit}
                                            startIcon={<CloudUploadIcon />}
                                        >
                                            Качи CV
                                        </Button>
                                    </label>
                                </Grid>
                                <Grid item md={12} sm={12}>
                                    <a className={classes.link} href={userCV} download target="_blank" rel="noopener noreferrer" >
                                        <Button
                                            className={classes.formInput} 
                                            variant="contained" 
                                            startIcon={<CloudDownloadIcon />}
                                            disabled = {!userCV}
                                        >
                                        {userCV ? "Преглед CV" : "Няма CV"}
                                        </Button>
                                    </a>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid className={classes.gridCell} item md={12} sm={12}>
                            <p className={!adminOrHR() ? classes.hidden : ""}>Попълнете коментар за кандидата</p>
                            <textarea
                                rows="5"
                                cols="100"
                                value={comments}
                                disabled={!editMode}
                                onChange={(event) => setComments(event.target.value)}
                                className={!adminOrHR() ? classes.hidden : classes.textarea}
                            ></textarea>
                        </Grid>
                        <Grid className={classes.gridCell} item md={4} sm={12}>
                                <input
                                    accept="image/*"
                                    className={classes.hidden}
                                    id="contained-button-photo"
                                    type="file"
                                    onChange={handleUserImageUpload}
                                />
                                <label htmlFor="contained-button-photo">
                                    <Button
                                        className={classes.formInput} 
                                        variant="contained" 
                                        color="primary"
                                        component="span"
                                        disabled = {!props.canEdit}
                                        startIcon={<CloudUploadIcon />}
                                    >
                                        Качи профилна снимка
                                    </Button>
                                </label>
                        </Grid>
                        <Grid className={classes.gridCell} item md={4} sm={12}>
                            {
                                editMode ? (
                                    <GreenButton
                                        className={classes.formInput} 
                                        variant="contained" 
                                        onClick={handleSaveUser}
                                        disabled = {!props.canEdit}
                                        >
                                        Запази промените
                                    </GreenButton>
                                ) : (
                                    <Button
                                        className={classes.formInput} 
                                        variant="contained" 
                                        color="primary"
                                        onClick={()=> setEditMode(true)}
                                        disabled = {!props.canEdit}
                                        >
                                        Редактирай профила
                                    </Button>)
                            }
                        </Grid>
                        <Grid className={classes.gridCell} item md={4} sm={12}>
                            <Button
                                className={classes.formInput} 
                                variant="contained" 
                                color="primary"
                                component="span"
                                startIcon={<PasswordIcon />}
                                disabled = {!props.canEdit}
                                onClick={()=> setOpenChangePassModal(true)}
                            >
                                Смени парола
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            ) :
            <CircularProgress />}
            <SimpleModal open={openChangePassModal} onClose={modalCloseHandler}>
            <div className={classes.modalContainer}>
                <h3>Смяна на парола?</h3>
                <TextField
                    className={classes.formInput}
                    label="Текуща парола"
                    value={oldPassword}
                    type="password"
                    onChange={(event) => setOldPassword(event.target.value)}
                    />
                    <TextField
                        className={classes.formInput}
                        label="Нова парола"
                        value={newPassword}
                        type="password"
                        onChange={(event) => setNewPassword(event.target.value)}
                        />
                    <TextField
                        className={classes.formInput}
                        label="Повтори нова парола"
                        value={newPassword2}
                        type="password"
                        onChange={(event) => setNewPassword2(event.target.value)}
                        />
                <GreenButton
                    className={classes.formInput} 
                    variant="contained" 
                    color="primary"
                    onClick={handleChangePassword}>
                    Смени парола
                </GreenButton>  
            </div>
            </SimpleModal>
        </div>
    );

}

export default ProfilePage;