import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from '../../util/Axios/Axios';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TextField, Button, IconButton, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import SimpleModal from '../../components/modals/SimpleModal';
import DateTimeField from '../../components/DateTimeField';
import { Scrollbars } from 'react-custom-scrollbars';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';


function InterviewSlotsPage(props) {

    const useStyles = makeStyles(theme => ({
        formInput: {
            width: "80%",
            minWidth : '350px',
            margin: "auto",
            marginTop : '16px',
            marginBottom : '16px',
            height: "45px",
            textAlign : 'center',
        },
        modalContainer : {
          width : '100%',
          display : 'flex',
          'flex-direction' : 'column',
          'justify-content' : 'center',
          'align-items' : 'center',
        },
    }));

    useEffect(()=>{
        loadSlots();
        axios.get('/users/interviewers').then(response => setInterviewers(response.data.data));
    },
        //eslint-disable-next-line
    [])

    const loadSlots = () => {
        axios.get('/seasons/'+props.seasonId+'/interview/slots')
        .then(response => {
            const slots = response.data.data;
            let total = 0;
            let taken = 0;
            slots.forEach(slot => {
                total += slot.max_slots;
                taken += slot.slots_taken;
            });
            setTotalAvailableSlots(total);
            setTotalTakenSlots(taken);
            setSlots(response.data.data);
        })
    }

    const classes = useStyles();
    const [slots, setSlots] = useState([]);
    const [totalAvailableSlots, setTotalAvailableSlots] = useState(0);
    const [totalTakenSlots, setTotalTakenSlots] = useState(0);
    const [startTime, setStartTime] = useState(new Date());
    const [endTime, setEndTime] = useState(new Date());
    const [interviewerId, setInterviewerId] = useState("");
    const [maxSlots, setMaxSlots] = useState("");

    const [openAddSlot, setOpenAddSlot] = useState(false);
    const [openEditSlot, setOpenEditSlot] = useState(false);

    const [editedSlotId, setEditedSlotId] = useState(null);
    const [editStartTime, setEditStartTime] = useState("");
    const [editEndTime, setEditEndTime] = useState("");
    const [editMaxSlots, setEditMaxSlots] = useState("");
    const [editInterviewerId, setEditInterviewerId] = useState("");

    const [interviewers, setInterviewers] = useState([]);

    const handleSaveSlot = () => {
        axios.post('/seasons/'+ props.seasonId+'/interview/slots', {
            start_time : startTime,
            end_time : endTime,
            interviewer : { id : interviewerId},
            max_slots : maxSlots
        })
        .then(response => {
            loadSlots();
            setOpenAddSlot(false);
            setStartTime(null);
            setEndTime(null);
            setInterviewerId("");
            setMaxSlots("");
        });
    }

    const handleEditSlot = () => {
        axios.put('/seasons/'+ props.seasonId+'/interview/slots', {
            id : editedSlotId,
            start_time : editStartTime,
            end_time : editEndTime,
            interviewer : { id : editInterviewerId},
            max_slots : editMaxSlots
        })
        .then(response => {
            loadSlots();
            setOpenEditSlot(false);
        });
    }

    const handleDeleteSlot = (slotId) => {
        axios.delete('/seasons/'+ props.seasonId+'/interview/slots', {
            data : {
                id : slotId
            }
        })
        .then(response => {
            loadSlots();
        });
    }

    const modalCloseHandler = () => {
        setOpenAddSlot(false);
        setOpenEditSlot(false);
    }

    return (
        <Paper className={classes.miniTable}>
        <h1>Слотове за интервюта (Общо {totalAvailableSlots} слота, {totalTakenSlots} заети, {totalAvailableSlots - totalTakenSlots} свободни)</h1>
            <Scrollbars style={{ height: "30vh" }} renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                        <TableRow>
                            <TableCell>Начало</TableCell>
                            <TableCell>Край</TableCell>
                            <TableCell>HR</TableCell>
                            <TableCell >Общо места</TableCell>
                            <TableCell >Заети</TableCell>
                            <TableCell >Свободни</TableCell>
                            <TableCell>Редакция</TableCell>
                            <TableCell>Изтриване</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {slots.map(slot => (
                            <TableRow key={slot.id}>
                                <TableCell >{slot.start_time}</TableCell>
                                <TableCell >{slot.end_time}</TableCell>
                                <TableCell >{slot.interviewer.full_name}</TableCell>
                                <TableCell >{slot.max_slots}</TableCell>
                                <TableCell >{slot.slots_taken}</TableCell>
                                <TableCell >{slot.max_slots - slot.slots_taken}</TableCell>
                                <TableCell >
                                    <IconButton onClick={()=> { 
                                        setEditedSlotId(slot.id); 
                                        setEditStartTime(slot.start_time);
                                        setEditEndTime(slot.end_time); 
                                        setEditInterviewerId(slot.interviewer.id); 
                                        setEditMaxSlots(slot.max_slots);
                                        setOpenEditSlot(true);}}>
                                        <EditIcon/>
                                    </IconButton>
                                </TableCell>
                                <TableCell ><IconButton onClick={()=>handleDeleteSlot(slot.id)}><DeleteIcon/></IconButton></TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <SimpleModal open={openAddSlot} onClose={modalCloseHandler} >
                    <div className={classes.modalContainer}>
                        <h2>Нов слот за интервю</h2>
                        <DateTimeField
                            className={classes.formInput}
                            label="Начало"
                            onChange={date => {setStartTime(date);}}
                            value={startTime}>
                        </DateTimeField>
                        <DateTimeField
                            className={classes.formInput}
                            label="Край"
                            onChange={date => {setEndTime(date);}}
                            value={endTime}>
                        </DateTimeField>
                        <FormControl className={classes.formInput}>
                            <InputLabel>Интервюиращ</InputLabel>
                            <Select
                                value={interviewerId}
                                onChange={(event)=>setInterviewerId(event.target.value)}
                            >
                            {
                                interviewers.map(interviewer => (
                                        <MenuItem key={interviewer.id} value={interviewer.id}>{interviewer.full_name}</MenuItem>
                                    )
                                )   
                            }
                            </Select>
                        </FormControl>
                        <TextField
                            value={maxSlots}
                            onChange={(event)=> setMaxSlots(event.target.value)}
                            className={classes.formInput}
                            label="Места"
                            type="number"
                        />
                        <Button
                        className={classes.formInput} 
                        variant="contained" 
                        color="primary"
                        onClick={handleSaveSlot}>Запази</Button>  
                    </div>       
                </SimpleModal>
                <SimpleModal open={openEditSlot} onClose={modalCloseHandler} >
                    <div className={classes.modalContainer}>
                        <h2>Редакция на слот</h2>
                        <DateTimeField
                            className={classes.formInput}
                            label="Начало"
                            onChange={date => setEditStartTime(date)}
                            value={editStartTime}>
                        </DateTimeField>
                        <DateTimeField
                            className={classes.formInput}
                            label="Край"
                            onChange={date => setEditEndTime(date)}
                            value={editEndTime}>
                        </DateTimeField>
                        <FormControl className={classes.formInput}>
                            <InputLabel>Интервюиращ</InputLabel>
                            <Select
                                value={editInterviewerId}
                                onChange={(event)=>setEditInterviewerId(event.target.value)}
                            >
                            {
                                interviewers.map(interviewer => (
                                        <MenuItem key={interviewer.id} value={interviewer.id}>{interviewer.full_name}</MenuItem>
                                    )
                                )   
                            }
                            </Select>
                        </FormControl>
                        <TextField
                            className={classes.formInput}
                            label="Места"
                            onChange={(event)=> setEditMaxSlots(event.target.value)}
                            value={editMaxSlots}
                            type="number"
                        />
                        <Button
                        className={classes.formInput} 
                        variant="contained" 
                        color="primary"
                        onClick={handleEditSlot}>Запази</Button>  
                    </div>
                </SimpleModal>
            </Scrollbars>
            <Button 
                variant="contained"
                color="primary" 
                onClick={()=> setOpenAddSlot(true)}>
                    Добави слот
            </Button>
        </Paper>
    );

}

export default InterviewSlotsPage;