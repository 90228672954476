import * as actions from "../../actions/login/loginActions";

const initialState = {
    loggedIn : false,
    userId: null,
    userRoleId: null,
    nextSeason : null,
    candidatesStatuses : [],
    nextSeasonCourses : []
}

const loginReducer = (state = initialState, action) => {
    switch(action.type){
        case actions.ACTION_LOG_IN:
            return {
                ...state,
                loggedIn : true,
                userId: action.userId,
                userRoleId: action.userRoleId.toString(),
                nextSeason : action.nextSeason,
                candidatesStatuses : action.candidatesStatuses,
                nextSeasonCourses : action.nextSeasonCourses
            }
        case actions.ACTION_LOG_OUT:
            return {
                ...state,
                loggedIn : false,
                userId: null,
                userRoleId: null,
                nextSeason : null
            }
        default:
            return state;
    }
}

export default loginReducer;