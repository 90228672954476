import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from '../../../util/Axios/Axios';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import { Tabs, useMediaQuery } from '@material-ui/core';
import TabPanel from '../../../components/TabPanel';
import TestResultsContainer from './TestResultsContainer';

function TestResultsPage(props) {

    function a11yProps(index) {
        return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const mobile = !useMediaQuery('(min-width:600px)');

    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(4),
            width: mobile ? "95%" : "60%",
            margin : 'auto',
            color: theme.palette.text.secondприary,
            marginBottom : "16px",
        },
        heading : {
            textAlign: 'center',
        }
    }));

    useEffect(()=>{
        getUserProfile()}, 
        //eslint-disable-next-line
    [])

    const [chosenTabId, setChosenTabId] = useState(0);
    const classes = useStyles();
    const userId = props.userId;
    const [recruitmentInfoList, setRecruitmentInfoList] = useState([]);

    const handleChange = (event, newValue) => {
        setChosenTabId(newValue);
      };

    const getUserProfile = () => {
        axios.get('/users/'+userId)
        .then(response => {
            setRecruitmentInfoList(response.data.data.recruitment_info);
            setChosenTabId(response.data.data.recruitment_info.length-1);
        })
        .catch(error=>{
        });
    }

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs variant="fullWidth" value={chosenTabId} onChange={handleChange} aria-label="simple tabs example">
                    {
                        recruitmentInfoList.map((recruitmentInfo, index) => {
                                return <Tab label={recruitmentInfo.season.name} {...a11yProps(index)} />
                        })
                    }
                </Tabs>
            </AppBar>
            {
                recruitmentInfoList.map((recruitmentInfo, index) => {
                    return (
                        <TabPanel value={chosenTabId} index={index}>
                            <TestResultsContainer recruitmentInfo = {recruitmentInfo}></TestResultsContainer>
                        </TabPanel>
                    )
                })
            }
        </div>
    );

}

export default TestResultsPage;