import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from '../../util/Axios/Axios';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useSelector } from 'react-redux';
import InterviewsListPage from './InterviewListPage';


function InterviewsViewPage() {

    const useStyles = makeStyles((theme) => ({
        root: {
          flexGrow: 1,
          textAlign : 'center',
          backgroundColor: theme.palette.background.paper,
        },
        filterInput : {
          width: "80%",
          margin: "auto",
          marginTop : '16px',
          marginBottom : '16px',
          height: "45px",
          textAlign : 'center',
        },
        interviewed : {
            backgroundColor : 'lightgreen'
        },
        chip : {
            margin : '8px',
        }
      }));

    const classes = useStyles();
    
    useEffect(() => {
        axios.get('/seasons').then(response => setSeasons(response.data.data));
    }, []);

    const nextSeason = useSelector(state => state.loginReducer.nextSeason)
    const [filteredSeason, setFilteredSeason] = useState(nextSeason ? nextSeason.id : null);
    const [seasons, setSeasons] = useState([]);
    
    return (
        <div className={classes.root}>
            <FormControl className={classes.filterInput}>
                <InputLabel>Сезон</InputLabel>
                <Select
                    value={filteredSeason}
                    onChange={(event)=>{setFilteredSeason(event.target.value)}}
                >
                {
                    seasons.map(season => (
                    <MenuItem key={season.id} value={season.id}>{season.name}</MenuItem>
                    ))
                }
                </Select>
            </FormControl>
            <InterviewsListPage filteredSeason={filteredSeason}></InterviewsListPage>
                
        </div>
    )
}

export default InterviewsViewPage;