import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Backdrop, CircularProgress } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
}));

export default function LoadingDiv() {
  const classes = useStyles();
  const visible = useSelector(state => state.generalReducer.showLoadingDiv)

  return (
    <Backdrop className={classes.backdrop} open={visible}>
        <CircularProgress color="inherit" />
    </Backdrop>
  );
}