import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from '../../util/Axios/Axios';
import { Paper, Grid, TextField, Button, InputLabel, FormControl, Select, MenuItem, Input, CircularProgress, AppBar, Tabs, Tab } from '@material-ui/core';
import DateField from '../../components/DateField';
import { GreenButton } from '../../components/buttons/GreenButton';
import { useSelector } from 'react-redux';
import AttendanceTestSlotsPage from './AttendanceTestSlotsPage';
import InterviewSlotsPage from './InterviewSlotsPage';
import TabPanel from '../../components/TabPanel';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import CoursesPage from './CoursesPage';
import ContractSlotsPage from './ContractSlotsPage';
import DateTimeField from '../../components/DateTimeField';

function SeasonSettingsPage() {

    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }
      
    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
            textAlign : 'center',
            backgroundColor: theme.palette.background.paper,
        },
        gridCell : {
            textAlign : 'center'
        },
        formInput: {
          width: "80%",
          margin: "auto",
          marginTop : '16px',
          marginBottom : '16px',
          height: "45px",
          textAlign : 'center',
        },
        heading : {
            width : "100%",
            textAlign : "center",
        },
    }));

    useEffect(()=>{
        axios.get('/seasons/'+seasonId)
        .then(response => {
            const season = response.data.data;
            setName(season.name);
            setStartDate(season.start_date);
            setEndDate(season.end_date);
            setRegisterEndDate(season.register_end_date);
            setOnlineTestDate(season.online_test_date);
            setOnlineTestQualifiers(season.online_test_qualifiers);
            setAttendanceTestQualifiers(season.attendance_test_qualifiers);
            setInterviewQualifiers(season.interview_qualifiers);
            setTechnologies(season.technologies.map(tech => tech.id));
            axios.get('seasons/technologies').then(response => { setAllTechnologies(response.data.data)});
        })}, 
        //eslint-disable-next-line
    [])

    const classes = useStyles();
    const [value, setValue] = useState(0);//tab selected value
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    const [name, setName] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [registerEndDate, setRegisterEndDate] = useState(new Date());
    const [onlineTestDate, setOnlineTestDate] = useState(new Date());
    const [onlineTestQualifiers, setOnlineTestQualifiers] = useState("");
    const [attendanceTestQualifiers, setAttendanceTestQualifiers] = useState("");
    const [interviewQualifiers, setInterviewQualifiers] = useState("");
    const [technologies, setTechnologies] = useState([]);
    const [allTechnologies, setAllTechnologies] = useState([]);

    const [editMode, setEditMode] = useState(false);
    const seasonId = useSelector(state => state.generalReducer.seasonViewedId);


    const handleSaveSeason = () => {
        axios.put('/seasons/'+ seasonId, {
            name : name,
            start_date : startDate,
            end_date : endDate,
            register_end_date : registerEndDate,
            online_test_date : onlineTestDate,
            online_test_qualifiers : onlineTestQualifiers,
            attendance_test_qualifiers : attendanceTestQualifiers,
            interview_qualifiers : interviewQualifiers,
            technologies : technologies.map(techId => {return {id : techId}})
        });
        setEditMode(false);
    }

    return (
        
        <div className={classes.root}>
            {
                name ? (
                <Aux>
                <h1 className={classes.heading}>Настройки на сезон</h1>
                <Paper>
                    <Grid className={classes.gridCell} container>
                        <Grid className={classes.gridCell} item xs={4}>
                            <TextField
                                className={classes.formInput}
                                label="Наименование"
                                value={name}
                                disabled={!editMode}
                                type="text"
                                onChange={(event) => setName(event.target.value)}
                            />
                        </Grid>
                        <Grid className={classes.gridCell} item xs={4}>
                            <TextField
                                className={classes.formInput}
                                label="MAX класирани след онлайн тест"
                                value={onlineTestQualifiers}
                                disabled={!editMode}
                                type="number"
                                onChange={(event) => setOnlineTestQualifiers(event.target.value)}
                            />
                        </Grid>
                        <Grid className={classes.gridCell} item xs={4}>
                            <DateTimeField
                                className={classes.formInput}
                                label="Дата на онлайн тест"
                                onChange={date => {setOnlineTestDate(date);}}
                                disabled={!editMode}
                                value={onlineTestDate}>
                            </DateTimeField>
                        </Grid>
                        <Grid className={classes.gridCell} item xs={4}>
                            <DateField
                                className={classes.formInput}
                                label="Начало"
                                onChange={date => {setStartDate(date);}}
                                disabled={!editMode}
                                value={startDate}>
                            </DateField>
                        </Grid>
                        <Grid className={classes.gridCell} item xs={4}>
                            <TextField
                                className={classes.formInput}
                                label="MAX класирани след присъствен тест"
                                value={attendanceTestQualifiers}
                                disabled={!editMode}
                                type="number"
                                onChange={(event) => setAttendanceTestQualifiers(event.target.value)}
                            />
                        </Grid>
                        <Grid className={classes.gridCell} item xs={4}>
                            <FormControl className={classes.formInput}>
                                <InputLabel>Технологии</InputLabel>
                                <Select
                                    labelId="mutiple-technologies-label"
                                    id="mutiple-technologies"
                                    multiple
                                    value={technologies}
                                    disabled={!editMode}
                                    onChange={(event) => setTechnologies(event.target.value)}
                                    input={<Input />}
                                    >
                                    {allTechnologies.map((tech) => (
                                        <MenuItem key={tech.id} value={tech.id}>
                                            {tech.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid className={classes.gridCell} item xs={4}>
                            <DateField
                                className={classes.formInput}
                                label="Край"
                                onChange={date => {setEndDate(date);}}
                                disabled={!editMode}
                                value={endDate}>
                            </DateField>
                        </Grid>
                        <Grid className={classes.gridCell} item xs={4}>
                            <TextField
                                className={classes.formInput}
                                label="MAX класирани след интервюта"
                                value={interviewQualifiers}
                                disabled={!editMode}
                                type="number"
                                onChange={(event) => setInterviewQualifiers(event.target.value)}
                            />
                        </Grid>
                        <Grid className={classes.gridCell} item xs={4}>
                            <DateField
                                className={classes.formInput}
                                label="Крайна дата за регистрации"
                                onChange={date => {setRegisterEndDate(date);}}
                                disabled={!editMode}
                                value={registerEndDate}>
                            </DateField>
                        </Grid>
                        <Grid className={classes.gridCell} item xs={4}></Grid>
                        <Grid className={classes.gridCell} item xs={4}>
                        {
                                editMode ? (
                                    <GreenButton
                                        className={classes.formInput} 
                                        variant="contained" 
                                        onClick={handleSaveSeason}
                                        >
                                        Запази промените
                                    </GreenButton>
                                ) : (
                                    <Button
                                        className={classes.formInput} 
                                        variant="contained" 
                                        color="primary"
                                        onClick={()=> setEditMode(true)}
                                        >
                                        Редактирай сезона
                                    </Button>)
                            }
                        </Grid>
                    </Grid>
                </Paper>
                <AppBar position="static">
                    <Tabs variant="fullWidth" value={value} onChange={handleChange} aria-label="season-tabs">
                        <Tab label="Слотове за присъствен тест" {...a11yProps(0)} />
                        <Tab label="Слотове за интервюта" {...a11yProps(1)} />
                        <Tab label="Слотове за договори" {...a11yProps(2)} />
                        <Tab label="Курсове" {...a11yProps(3)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <AttendanceTestSlotsPage seasonId={seasonId}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <InterviewSlotsPage seasonId={seasonId}/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <ContractSlotsPage seasonId={seasonId}/>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <CoursesPage seasonId={seasonId}/>
                </TabPanel>
            </Aux>
                ) : <CircularProgress />}
        </div>
    );

}

export default SeasonSettingsPage;