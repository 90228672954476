import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Paper, Button, Select, FormControl, InputLabel, MenuItem } from "@material-ui/core";
import { BASE_URL, COMPANIES_STATUS_GOLD, COMPANIES_STATUS_GOLD_NAME, COMPANIES_STATUS_SILVER, COMPANIES_STATUS_SILVER_NAME} from '../../util/Constants';
import nologo from '../../assets/nologo.jpg';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import axios from '../../util/Axios/Axios';
import { MENU_OPEN_COMPANIES_VIEW } from '../../store/actions/generalActions';
import { useDispatch } from 'react-redux';

function CompaniesAddPage() {

  const useStyles = makeStyles(theme => ({
    paper: {
      width: "40%",
      margin: "auto",
      marginTop: "60px",
      display: "flex",
      padding : '16px',
      "flex-direction": "column",
      "justify-content" : "flex-start",
      // "@media(min-width:600px)" : {
      //   width: "40%",
      //   margin: "auto",
      //   marginTop: "60px",
      //   display: "flex",
      //   padding : '16px',
      // }
    },
    formInput: {
      width: "80%",
      margin: "auto",
      marginTop : '16px',
      marginBottom : '16px',
      height: "45px",
      'text-align' : 'center',
    },
    logoImage : {
      margin: "auto",
      borderRadius : '50%',
      height : '180px',
      width : '180px',
      objectFit: 'cover',
    },
    hidden : {
        display : 'none'
    },
    uploadLabel : {
      textAlign : "center"
    }
  }));

  
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [logo, setLogo] = useState(nologo);
  const [logoId, setLogoId] = useState(null);

  const classes = useStyles();
  const dispatch = useDispatch();
  
  const handleUploadLogo = event => {
    const photo = event.target.files[0];
    const formData = new FormData();
    formData.append("file", photo);
    axios.post("/companies/logo", formData)
    .then(response => {
      setLogo(BASE_URL + response.data.data.file_url);
      setLogoId(response.data.data.id);
    })
  }

  const handleAddCompany = event => {
    axios.post("/companies", {
      name : name,
      description : description,
      status : status,
      logo : {
        id : logoId
      }
    })
    .then(response => {
      dispatch({type : MENU_OPEN_COMPANIES_VIEW});
    })
  }

  return (
    <Paper className={classes.paper}>
      <TextField
        required
        value={name}
        className={classes.formInput}
        label="Име"
        type="text"
        onChange={(event) => setName(event.target.value)}
      />
      <TextField
        required
        value={description}
        className={classes.formInput}
        label="Описание"
        type = "text"
        multiline
        onChange={(event) => setDescription(event.target.value)}
      />
      <img className={classes.logoImage} src={logo} alt='logo'></img>
      <input
        accept="image/*"
        className={classes.hidden}
        id="contained-button-logo"
        multiple
        type="file"
        onChange={handleUploadLogo}
      />
      <label className={classes.uploadLabel} htmlFor="contained-button-logo">
        <Button
            className={classes.formInput} 
            variant="contained" 
            color="primary"
            component="span"
            startIcon={<CloudUploadIcon />}
        >
          Качи лого (max 5MB)
        </Button>
      </label>
      <FormControl className={classes.formInput}>
        <InputLabel>Партньорство</InputLabel>
        <Select
            value={status}
            onChange={(event)=>setStatus(event.target.value)}
        >
          <MenuItem value={COMPANIES_STATUS_GOLD}>{COMPANIES_STATUS_GOLD_NAME}</MenuItem>
          <MenuItem value={COMPANIES_STATUS_SILVER}>{COMPANIES_STATUS_SILVER_NAME}</MenuItem>
        </Select>
      </FormControl>
      <Button 
        className={classes.formInput} 
        variant="contained" 
        color="primary"
        onClick={handleAddCompany}
        >
        Добави нова фирма
      </Button>
    </Paper>

  );
}

export default CompaniesAddPage;
