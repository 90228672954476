import React from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';

function DateOfBirthPicker(props) {

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
            {...props}
            disableFuture
            openTo="year"
            views={["year", "month", "date"]}
            className={props.className}
            margin="normal"
            label={props.label}
            format="dd/MM/yyyy"
            onChange={props.onChange}
            value={props.value}
            KeyboardButtonProps={{
                'aria-label': 'change date',
            }}
            maxDate={new Date(new Date().getFullYear()-18, new Date().getMonth(), new Date().getDate())}
            maxDateMessage="Трябва да сте навършили 18 години!"
        />
  </MuiPickersUtilsProvider>
  )
}

DateOfBirthPicker.propTypes = {
    className : PropTypes.any,
    label :     PropTypes.string.isRequired,
    onChange :  PropTypes.func.isRequired,
    value :     PropTypes.any.isRequired
}

export default DateOfBirthPicker;
