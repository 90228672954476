import React, { useState, useEffect } from 'react';
import axios from '../../util/Axios/Axios';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import { TableContainer, Table, TableRow, TableCell, Paper, TableHead, TableBody, Select, TextField, makeStyles, FormControl, InputLabel, MenuItem, Grid, TableSortLabel, TablePagination } from '@material-ui/core';
import { useSelector } from 'react-redux';
import CandidatesInterviewRow from './CandidatesInterviewRow';
import CandidatesExportButton from '../../components/buttons/CandidatesExportButton';

function CandidateInterviewView(props) {

  const useStyles = makeStyles(theme => ({
    filterInput : {
      width: "80%",
      margin: "auto",
      marginTop : '16px',
      marginBottom : '16px',
      height: "45px",
      textAlign : 'center',
    },
    gridCell : {
      textAlign : 'center'
    },
  }));

  const classes = useStyles();


  const filterCandidates = (name, phone, status, slot, email, interviewer_name, date, score) => {
    axios.post("/candidates/interviews", {
      name : name === "" ? null : name,
      phone : phone === "" ? null : phone,
      season_id : props.season,
      interview_slot_id : slot,
      email : email === "" ? null : email,
      status,
      interviewer_name,
      interview_date : date,
      interview_score : score
    })
    .then(response => {
      const candidates = response.data.data;
      setCandidates(candidates);
      if(dates.length === 0){
        let dates_arr = [];
        candidates.forEach(candidate => {
          if(candidate.interview_slot){
            dates_arr.push(candidate.interview_slot.start_time.split(' ')[0]);
          }
        });
        dates_arr = dates_arr.filter((v, i, a) => a.indexOf(v) === i);
        dates_arr.sort();
        setDates(dates_arr);
      } 
    })
  }

  const [candidates, setCandidates] = useState([]);
  const [filteredName, setFilteredName] = useState("");
  const [filteredPhone, setFilteredPhone] = useState("");
  const [filteredStatus, setFilteredStatus] = useState("");
  const [filteredSlot, setFilteredSlot] = useState("");
  const [filteredEmail, setFilteredEmail] = useState("");
  const [filteredInterviewer, setFilteredInterviewer] = useState("");
  const [filteredDate, setFilteredDate] = useState("");
  const [filteredScore, setFilteredScore] = useState("");
  const [slots, setSlots] = useState([]);
  const [dates, setDates] = useState([]);
  const statuses = useSelector(state => state.loginReducer.candidatesStatuses);

  const [interviewers, setInterviewers] = useState([]);
  const [orderBy, setOrderBy] = useState('interview_score');
  const [order, setOrder] = useState('asc');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 50));
    setPage(0);
  };

  useEffect(()=> {
      filterCandidates(filteredName, filteredPhone, filteredStatus, filteredSlot, filteredEmail, filteredInterviewer, filteredDate, filteredScore);
      axios.get('/seasons/'+props.season+'/interview/slots').then ( response => setSlots(response.data.data));
      axios.get('/users/interviewers').then ( response => setInterviewers(response.data.data));
      //eslint-disable-next-line
  }, [props.season])

  const handleCandidatesSort = (property) => {
    setOrderBy(property);
    setOrder(order === 'asc' ? 'desc' : 'asc');
    candidates.sort(function (a, b){
      return order === 'desc'
        ? descendingComparator(a, b, property)
        : -descendingComparator(a, b, property);
    });
    setCandidates(candidates);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  return (
    <Aux>
    <Paper>
      <Grid container>
        <Grid item xs={4} className={classes.gridCell} >
          <TextField
              label="Име"
              className = {classes.filterInput}
              value={filteredName}
              onChange={(event)=> {setFilteredName(event.target.value); filterCandidates(event.target.value, filteredPhone, filteredStatus, filteredSlot, filteredEmail, filteredInterviewer, filteredDate, filteredScore)}}
              />
        </Grid>
        <Grid item xs={4} className={classes.gridCell} >
          <TextField
              label="Имейл"
              className = {classes.filterInput}
              value={filteredEmail}
              onChange={(event)=> {setFilteredEmail(event.target.value); filterCandidates(filteredName, filteredPhone, filteredStatus, filteredSlot, event.target.value, filteredInterviewer, filteredDate, filteredScore)}}
              />
        </Grid>
        <Grid item xs={4} className={classes.gridCell} >
          <TextField
              label="Телефон"
              className = {classes.filterInput}
              value={filteredPhone}
              onChange={(event)=> {setFilteredPhone(event.target.value); filterCandidates(filteredName, event.target.value, filteredStatus, filteredSlot, filteredEmail, filteredInterviewer, filteredDate, filteredScore)}}
              />
        </Grid>
        <Grid item xs={4} className={classes.gridCell} >
          <FormControl className={classes.filterInput}>
            <InputLabel>Статус</InputLabel>
            <Select
                value={filteredStatus}
                onChange={(event)=>{setFilteredStatus(event.target.value); filterCandidates(filteredName, filteredPhone, event.target.value, filteredSlot, filteredEmail, filteredInterviewer, filteredDate, filteredScore)}}
            >
              <MenuItem value={""}>Всички</MenuItem>
              {
                statuses.map((status, key) => (
                  key > 5 ?
                  <MenuItem key={status} value={status}>{status}</MenuItem>
                  : null
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} className={classes.gridCell} >
          <FormControl className={classes.filterInput}>
            <InputLabel>Интервюиращ</InputLabel>
            <Select
                value={filteredInterviewer}
                onChange={(event)=>{setFilteredInterviewer(event.target.value); filterCandidates(filteredName, filteredPhone, filteredStatus, filteredSlot, filteredEmail, event.target.value, filteredDate, filteredScore)}}
            >
              <MenuItem value={""}>Всички</MenuItem>
              {
                interviewers.map((interviewer) => (
                  <MenuItem key={interviewer.id} value={interviewer.full_name}>{interviewer.full_name}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} className={classes.gridCell} >
          <FormControl className={classes.filterInput}>
            <InputLabel>Слот</InputLabel>
            <Select
                value={filteredSlot}
                onChange={(event)=>{setFilteredSlot(event.target.value); filterCandidates(filteredName, filteredPhone, filteredStatus, event.target.value, filteredEmail, filteredInterviewer, filteredDate, filteredScore)}}
            >
              <MenuItem value={""}>Всички</MenuItem>
              <MenuItem value={-1}>Без слот</MenuItem>
              {
                slots.map((slot) => (
                  <MenuItem key={slot.id} value={slot.id}>{slot.start_time} - {slot.end_time}  ({slot.interviewer.full_name} - общо {slot.max_slots} места)</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} className={classes.gridCell} >
          <FormControl className={classes.filterInput}>
            <InputLabel>Дата</InputLabel>
            <Select
                value={filteredDate}
                onChange={(event)=>{setFilteredDate(event.target.value); filterCandidates(filteredName, filteredPhone, filteredStatus, filteredSlot, filteredEmail, filteredInterviewer, event.target.value, filteredScore)}}
            >
              <MenuItem value={""}>Всички</MenuItem>
              {
                dates.map((date) => (
                  <MenuItem key={date} value={date}>{date}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} className={classes.gridCell} >
          <FormControl className={classes.filterInput}>
            <InputLabel>Резултат</InputLabel>
            <Select
                value={filteredScore}
                onChange={(event)=>{setFilteredScore(event.target.value); filterCandidates(filteredName, filteredPhone, filteredStatus, filteredSlot, filteredEmail, filteredInterviewer, filteredDate, event.target.value)}}
            >
              <MenuItem value={""}>Всички</MenuItem>
              <MenuItem value={"ДА"}>ДА</MenuItem>
              <MenuItem value={"НЕ"}>НЕ</MenuItem>
              <MenuItem value={"МОЖЕ БИ"}>МОЖЕ БИ</MenuItem>
              <MenuItem value={"ОТКАЗАЛ СЕ"}>ОТКАЗАЛ СЕ</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} className={classes.gridCell} >
          <FormControl className={classes.filterInput}>
            <CandidatesExportButton
              candidates = {candidates}
              fileName = {"Интервюта_"+ new Date().toLocaleDateString("en-GB")}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
    <TableContainer component={Paper}>
        <Table aria-label="simple table" size="small">
            <TableHead>
            <TableRow>
                <TableCell style={{fontWeight : "bold"}}>Общо: {candidates.length} кандидата</TableCell>
                <TableCell>Имена</TableCell>
                <TableCell>Имейл</TableCell>
                <TableCell>Телефон</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'interview_score'}
                    direction={orderBy === 'interview_score' ? order : 'asc'}
                    onClick={()=>handleCandidatesSort('interview_score')}
                  >
                    Резултат интервю
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'interview_desired_tech_1'}
                    direction={orderBy === 'interview_desired_tech_1' ? order : 'asc'}
                    onClick={()=>handleCandidatesSort('interview_desired_tech_1')}
                  >
                    Желани технологии
                  </TableSortLabel>
                </TableCell>
                <TableCell>Статус</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {candidates.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((cand, index) => (
                <CandidatesInterviewRow key={cand.id} candidate={cand} order={(page * rowsPerPage) + index+1}/>
            ))}
            </TableBody>
        </Table>
    </TableContainer>
    <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={candidates.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
    </Aux>
  );
}

export default CandidateInterviewView;
