export const SHOW_SUCCESS_TOAST = 'SHOW_SUCCESS_TOAST';
export const HIDE_SUCCESS_TOAST = 'HIDE_SUCCESS_TOAST';
export const SHOW_ERROR_TOAST = 'SHOW_ERROR_TOAST';
export const HIDE_ERROR_TOAST = 'HIDE_ERROR_TOAST';
export const SHOW_MENU = 'SHOW_MENU';
export const HIDE_MENU = 'HIDE_MENU';
export const OPEN_LOGIN_PAGE = 'OPEN_LOGIN';
export const OPEN_REGISTER_PAGE = 'OPEN_REGISTER';
export const MENU_OPEN_INTERVIEWS = 'OPEN_INTERVIEWS';
export const MENU_OPEN_COMPANIES_VIEW = 'OPEN_COMPANIES_VIEW';
export const MENU_OPEN_COMPANIES_ADD = 'OPEN_COMPANIES_ADD';
export const MENU_OPEN_SEASONS_ADD = 'OPEN_SEASONS_ADD';
export const MENU_OPEN_SEASONS_VIEW = 'OPEN_SEASONS_VIEW';
export const MENU_OPEN_CADETS_RECRUITMENT = 'OPEN_CADETS_RECRUITMENT';
export const MENU_OPEN_CADETS_IN_SEASON = 'OPEN_CADETS_IN_SEASON';
export const MENU_OPEN_AUTO_NOTIFICATIONS = 'OPEN_AUTO_NOTIFICATIONS';
export const MENU_OPEN_MANUAL_NOTIFICATIONS = 'OPEN_MANUAL_NOTIFICATIONS';
export const MENU_OPEN_STATISTICS = 'OPEN_STATISTICS'
export const OPEN_SEASON_SETTINGS_PAGE = 'OPEN_SEASON_SETTINGS';
export const OPEN_SURVEY_PAGE = 'OPEN_SURVEY_PAGE';

export const MENU_OPEN_MY_HOME = 'OPEN_MY_HOME';
export const MENU_OPEN_MY_PROFILE = 'OPEN_MY_PROFILE_';
export const MENU_OPEN_MY_INBOX = 'OPEN_MY_INBOX';
export const MENU_OPEN_MY_TEST_PREPARATION = 'OPEN_MY_TEST_PREPARATION';
export const MENU_OPEN_MY_TEST_RESULTS = 'OPEN_MY_TEST_RESULTS';
export const MENU_OPEN_MY_FAQ = 'OPEN_MY_FAQ';

export const SHOW_LOADING_DIV = 'SHOW_LOADING_DIV';
export const HIDE_LOADING_DIV = 'HIDE_LOADING_DIV';



