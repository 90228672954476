import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from '../../util/Axios/Axios';
import { CircularProgress, Paper, Grid, useMediaQuery, Typography } from '@material-ui/core';
import parse from 'html-react-parser';
import { useSelector } from 'react-redux';
import ApplicationEntry from './ApplicationEntry';
import {CANDIDATE_STATUS_ATTENDANCE_TEST_DROPPED, CANDIDATE_STATUS_INTERVIEW_DROPPED, CANDIDATE_STATUS_ONLINE_TEST_DROPPED, CANDIDATE_STATUS_REJECTED} from '../../util/Constants';
import SimpleModal from '../../components/modals/SimpleModal';
import { GreenButton } from '../../components/buttons/GreenButton';
import { RedButton } from '../../components/buttons/RedButton';

function HomePage(props) {

    const mobile = !useMediaQuery('(min-width:600px)');
    
    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
        },
        paper: {
          padding: theme.spacing(4),
          textAlign: 'center',
          width: mobile ? "95%" : "60%",
          margin : 'auto',
          color: theme.palette.text.secondприary,
        },
        gridCell : {
            textAlign : 'center'
        },
        system_status : {
            textAlign : 'left',
        },
        recruitment_status_ok : {
            backgroundColor : '#DFF0D8',
            fontSize : 'large',
            color : '#468847',
        },
        recruitment_status_dropped : {
            backgroundColor : '#f2dede',
            fontSize : 'large',
            color : '#b94a48',
        }
    }));

    useEffect(()=>{
        getUserProfile()}, 
        //eslint-disable-next-line
    [])

    const classes = useStyles();
    const userId = props.userId;
    const nextSeason = useSelector(state => state.loginReducer.nextSeason);
    const [user, setUser] = useState(null);
    const [openPopup, setOpenPopup] = useState(false);

    const getUserProfile = () => {
        axios.get('/users/'+userId)
        .then(response => {
            const data = response.data.data.user;
            setUser(data);
            setSystemStatus(data.automatic_status);
            let isCandidate = false;
            response.data.data.recruitment_info.forEach(info => {
                if(info.season_id === nextSeason.id){
                    setRecruitmentInfo(info);
                    isCandidate = true;//if there is a recruitment info for the next season, then he has been assigned.
                }
            });
            setOpenPopup(!isCandidate);//if he is registered but is not a candidate for the next season, prompt him to become one
        })
        .catch(error=>{
        });
    }

    const [recruitmentInfo, setRecruitmentInfo] = useState(null);
    const [systemStatus, setSystemStatus] = useState("");

    const hasDropped = () =>{
        return  recruitmentInfo.recruitment_state === CANDIDATE_STATUS_REJECTED ||
                recruitmentInfo.recruitment_state === CANDIDATE_STATUS_ONLINE_TEST_DROPPED ||
                recruitmentInfo.recruitment_state === CANDIDATE_STATUS_ATTENDANCE_TEST_DROPPED ||
                recruitmentInfo.recruitment_state === CANDIDATE_STATUS_INTERVIEW_DROPPED;
    }

    const handleRejectApplication = () => {
        setOpenPopup(false);
        fetchRecruitmentInfo();
    }

    const handleConfirmApplication = () => {
        setOpenPopup(false);
        //display the new recruitment info
        axios.post('/users/apply')
        .then(response => {
            fetchRecruitmentInfo();
        })
        .catch(error=>{
        });
    }

    const fetchRecruitmentInfo = () => {
        //display the recruitment info from the last season the guy has applied.
        axios.get('/users/'+userId)
        .then(response => {
            let seasonsAsCandidate = response.data.data.recruitment_info;
            seasonsAsCandidate.sort((a, b) => b.season_id - a.season_id);
            setRecruitmentInfo(seasonsAsCandidate[0]);
        })
        .catch(error=>{
        });
    }

    return (
        <div className={classes.root}>
            {user ? (
                    recruitmentInfo ? (
                        <Paper className={classes.paper}>
                            <Grid container >
                                <Grid className={classes.gridCell} item xs={12}>
                                    <p> 
                                        <i>Добре дошъл, {user.full_name}!</i>
                                    </p>
                                </Grid>
                                <Grid className={classes.gridCell} item xs={12}>
                                    <p className={hasDropped() ? classes.recruitment_status_dropped : classes.recruitment_status_ok }>
                                        {hasDropped() ? "Отпаднал от подбор" : recruitmentInfo.recruitment_state}
                                    </p>
                                </Grid>

                                <Grid className={classes.gridCell} item xs={12}>
                                    <div className={classes.system_status}>{parse(systemStatus)}</div>
                                </Grid>
                                {
                                    !hasDropped() ? (
                                        <Grid className={classes.gridCell} item xs={12}>
                                            <ApplicationEntry info={recruitmentInfo}></ApplicationEntry>
                                        </Grid>
                                    ) : (
                                        ""
                                    )
                                }
                                
                            </Grid>
                        </Paper>
                    ) : (
                        <SimpleModal open={openPopup}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} className={classes.gridCell}>
                                <Typography component="h3">
                                    Вече имате регистрация от предишен сезон в ИТ Таланти.<br></br> Желаете ли да кандидатствате отново и за следващите курсове?
                                </Typography>
                                </Grid>
                                <Grid item xs={6} className={classes.gridCell}>
                                    <GreenButton onClick={() => handleConfirmApplication()}>
                                        Да
                                    </GreenButton>
                                </Grid>
                                <Grid item xs={6} className={classes.gridCell}>
                                    <RedButton onClick={() => handleRejectApplication()}>
                                        Не
                                    </RedButton>
                                </Grid>
                            </Grid>
                        </SimpleModal>
                    )
            ) :
            <CircularProgress />}
        </div>
    );

}

export default HomePage;