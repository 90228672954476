import axios from 'axios';
import { BASE_URL } from '../Constants';
import {store} from '../../index'
import * as actionCreators from '../../store/actions/generalActions'

const instance = axios.create({
    baseURL: BASE_URL
});

function onUploadProgress(ev) {
}

instance.interceptors.request.use((config) => {
    config.headers.Authorization =  localStorage["auth"];
    store.dispatch(
        {
            type : actionCreators.SHOW_LOADING_DIV,
        }
    )
    return {
        ...config,
        onUploadProgress: config.onUploadProgress || onUploadProgress,

    }
}, error => {
    return Promise.reject(error)
})


instance.interceptors.response.use(function (response) {

    store.dispatch(
        {
            type : actionCreators.HIDE_LOADING_DIV,
        });

    if (response.data.message) {
        store.dispatch(
            {
                type : actionCreators.SHOW_SUCCESS_TOAST, 
                msg : response.data.message
            });
    }

    return response;
}, function (error) {

    store.dispatch(
        {
            type : actionCreators.HIDE_LOADING_DIV,
        });
        
    if (error && error.response && error.response.data) {
        store.dispatch(
            {
                type : actionCreators.SHOW_ERROR_TOAST, 
                msg : error.response.status === 413 ? 'Размерът на файла трябва да е не повече от 5 MB!' : error.response.data.message
            });
    }
    return Promise.reject(error.response);
});

export default instance;