import React from 'react';
import ReactExport from "react-data-export";
import { Button } from '@material-ui/core';


function CandidatesExportButton(props) {

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    //for reference: https://github.com/securedeveloper/react-data-export/blob/HEAD/examples/styled_excel_sheet.md

    return (
    <ExcelFile
        element={
            <Button 
                variant="contained" 
                color="primary">
                Експорт в Excel
            </Button>
        }
        filename={props.fileName}
        fileExtension="xlsx">
        <ExcelSheet data={props.candidates} name="Candidates">
            <ExcelColumn label="Имена" value="full_name" />
            <ExcelColumn label="Имейл" value="email" />
            <ExcelColumn label="Телефон" value="phone_number" />
            <ExcelColumn label="Първо желание" value={(col) => col.interview ? (col.interview.desired_tech1 ? col.interview.desired_tech1.name : "") : ""} />
            <ExcelColumn label="Второ желание" value={(col) => col.interview ? (col.interview.desired_tech2 ? col.interview.desired_tech2.name : "") : ""} />
        </ExcelSheet>
    </ExcelFile>
  );
}
export default CandidatesExportButton;
