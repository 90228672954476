import React from 'react';
import ProfilePage from '../containers/home/ProfilePage'
import LoginPage from '../containers/login/LoginPage';
import CompaniesAddPage from '../containers/companies/CompaniesAddPage';
import CompaniesViewPage from '../containers/companies/CompaniesViewPage';
import SeasonsViewPage from '../containers/seasons/SeasonsViewPage';
import SeasonsAddPage from '../containers/seasons/SeasonsAddPage';
import RegisterPage from '../containers/register/RegisterPage';
import { useSelector } from 'react-redux';
import * as generalActions from '../store/actions/generalActions';
import CandidatesRecruitmentPage from '../containers/candidates/CandidatesRecruitmentPage';
import SeasonSettingsPage from '../containers/seasons/SeasonsSettingsPage';
import InterviewsViewPage from '../containers/interviews/InterviewsViewPage';
import AutomaticNotificationsPage from '../containers/notifications/automatic/AutomaticNotificationsPage';
import ManualNotificationsPage from '../containers/notifications/manual/ManualNotificationsPage';
import HomePage from '../containers/home/HomePage';
import InboxPage from '../containers/home/InboxPage';
import TestResultsPage from '../containers/home/testResults/TestResultsPage';
import TestPreparationPage from '../containers/home/TestPreparationPage';
import FAQPage from '../containers/home/FAQPage';
import SurveyViewPage from '../containers/candidates/survey/SurveyViewPage';
import StatisticsPage from '../containers/statistics/StatisticsPage';
import CadetsViewPage from '../containers/cadets/CadetsViewPage';

function Content() {
    
  const loggedIn = useSelector(state => state.loginReducer.loggedIn);
  const pageSelected = useSelector(state => state.generalReducer.pageSelected);

  return (
    loggedIn ? (
      pageSelected === generalActions.MENU_OPEN_MY_HOME ? (<HomePage userId={localStorage["user_id"]}/>) : 
      pageSelected === generalActions.MENU_OPEN_MY_PROFILE ? (<ProfilePage canEdit={true} userId={localStorage["user_id"]}/>) : 
      pageSelected === generalActions.MENU_OPEN_MY_INBOX ? (<InboxPage userId={localStorage["user_id"]}/>) : 
      pageSelected === generalActions.MENU_OPEN_MY_TEST_PREPARATION ? (<TestPreparationPage />) : 
      pageSelected === generalActions.MENU_OPEN_MY_TEST_RESULTS ? (<TestResultsPage userId={localStorage["user_id"]}/>) : 
      pageSelected === generalActions.MENU_OPEN_MY_FAQ ? (<FAQPage/>) : 
      pageSelected === generalActions.MENU_OPEN_INTERVIEWS ? (<InterviewsViewPage/>) : 
      pageSelected === generalActions.MENU_OPEN_COMPANIES_ADD ? (<CompaniesAddPage/>) : 
      pageSelected === generalActions.MENU_OPEN_COMPANIES_VIEW ? (<CompaniesViewPage/>) : 
      pageSelected === generalActions.MENU_OPEN_SEASONS_VIEW ? (<SeasonsViewPage/>) : 
      pageSelected === generalActions.MENU_OPEN_SEASONS_ADD ? (<SeasonsAddPage/>) : 
      pageSelected === generalActions.MENU_OPEN_SEASONS_VIEW ? (<SeasonsViewPage/>) : 
      pageSelected === generalActions.MENU_OPEN_SEASONS_ADD ? (<SeasonsAddPage/>) : 
      pageSelected === generalActions.MENU_OPEN_AUTO_NOTIFICATIONS ? (<AutomaticNotificationsPage/>) : 
      pageSelected === generalActions.MENU_OPEN_MANUAL_NOTIFICATIONS ? (<ManualNotificationsPage/>) : 
      pageSelected === generalActions.MENU_OPEN_CADETS_RECRUITMENT ? (<CandidatesRecruitmentPage/>) : 
      pageSelected === generalActions.MENU_OPEN_CADETS_IN_SEASON ? (<CadetsViewPage/>) : 
      pageSelected === generalActions.OPEN_SEASON_SETTINGS_PAGE ? (<SeasonSettingsPage/>) : 
      pageSelected === generalActions.OPEN_SURVEY_PAGE ? (<SurveyViewPage/>) : 
      pageSelected === generalActions.MENU_OPEN_STATISTICS ? (<StatisticsPage/>)
      
      
      : (<HomePage userId={localStorage["user_id"]}/>)
    ) : 
    (
      pageSelected === generalActions.OPEN_REGISTER_PAGE ? (<RegisterPage></RegisterPage>) : (<LoginPage></LoginPage>)
    )
  )
}

export default Content;