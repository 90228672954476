import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Button } from '@material-ui/core';
import { MENU_WIDTH, USER_ROLE_ADMINISTRATOR_ID, USER_ROLE_HR_ID } from '../util/Constants';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { SHOW_MENU,  } from '../store/actions/generalActions';
import * as actionCreators from '../store/actions/login/loginActions';
import logo from '../assets/logo-white.png';

function Header(props) {
    
  const drawerWidth = MENU_WIDTH;
  
  const open = useSelector(state => state.generalReducer.showMenu);  
  const loggedIn =  useSelector(state => state.loginReducer.loggedIn);
  const userRoleId = useSelector(state => state.loginReducer.userRoleId);
  const dispatch = useDispatch();

  const useStyles = makeStyles(theme => ({
    toolBar : {
      display : 'flex',
      justifyContent: 'space-between',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    hide: {
      display : 'none'
    },
    logo_big: {
      height : '92px',
      margin : '4px',
    },
    logo_small: {
      height : '55px',
      margin : '4px',
    },
    buttonsContainer : {
      display : 'flex',
      alignItems : 'center',
      justifyContent: 'center',
    },
    verticalLine : {
      width : '1px',
      height : '20px',
      backgroundColor : 'white',
    }
  }));

  const classes = useStyles();

    return (
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.toolBar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={()=>dispatch({type : SHOW_MENU})}
            edge="start"
            className={clsx(
              {
                [classes.menuButton] : true,
                [classes.hide] : (open || !loggedIn)
              }
              )}
          >
            <MenuIcon />
          </IconButton>
          <img className={userRoleId === USER_ROLE_ADMINISTRATOR_ID || userRoleId === USER_ROLE_HR_ID ? classes.logo_small : classes.logo_big} src={logo} alt="ittalents logo"></img>
          <div className={classes.buttonsContainer}>
            <Button 
              color="inherit" 
              onClick={()=>  window.open("https://ittalents.bg", '_blank')}
            >Към сайта</Button>
            <div className={clsx(
                {
                  [classes.hide] : (!loggedIn),
                  [classes.verticalLine] : true
                }
                )}></div>
            <Button 
              color="inherit" 
              onClick={()=> dispatch(actionCreators.logOut())}
              className={clsx(
                {
                  [classes.hide] : (!loggedIn)
                }
                )}
            >Изход</Button>
          </div>
        </Toolbar>
      </AppBar>
    );

}

export default Header;