import React, { useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { GreenButton } from '../../../components/buttons/GreenButton';
import axios from '../../../util/Axios/Axios';
import { SMS_MAX_LENGTH } from '../../../util/Constants';


function AutomaticSMSEdit(props) {

    const useStyles = makeStyles(theme => ({
        formInput: {
          width: "60%",
          margin: "auto",
          marginTop : '16px',
          marginBottom : '16px',
          height: "45px",
          textAlign : 'center',
        },
        centerdItems : {
            textAlign : "center",
        },
        textarea : {
            width : '60%',
            maxHeight : '250px',
            resize : 'vertical',
        }
    }));


    const classes = useStyles();

    const [body, setBody] = useState(props.sms.body);
    const [length, setLength] = useState(props.sms.body.length);

    const handleEditSMS = () => {
        axios.put('/notifications/sms', {
            id : props.sms.id,
            occasion : props.sms.occasion,
            body : body
        })
    }

    return (
        <Grid container spacing={3} >
            <Grid item xs={12} className={classes.centerdItems}>
                <textarea
                    maxLength={SMS_MAX_LENGTH}
                    rows="5" 
                    className={classes.textarea}
                    onChange={(event) => {
                        setBody(event.target.value);
                        setLength(event.target.value.length);
                    }}
                    onKeyPress = { (e) => {
                        var verified = String.fromCharCode(e.which).match(/[\w[\]`!@#$%^&*()={}:;<>+ ,?.'//-]/);
                            if (!verified) {
                                e.preventDefault();
                            }
                        }
                    }
                    value={body}
                >
                </textarea>
            </Grid>
            <Grid item xs={12} className={classes.centerdItems}>
                <p>Дължина: {length} / {SMS_MAX_LENGTH} символа</p>
            </Grid>
            <Grid item xs={12} className={classes.centerdItems}>
                <GreenButton
                    className={classes.formInput}
                    onClick={handleEditSMS}>
                    Запази промените
                </GreenButton>
            </Grid>
        </Grid>
    )
}

export default AutomaticSMSEdit;