import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../components/TabPanel';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Tabs, Grid, Button } from '@material-ui/core';
import axios from '../../util/Axios/Axios';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import SimpleModal from '../../components/modals/SimpleModal';
import ProfilePage from '../home/ProfilePage';
import ApplicationEntry from '../home/ApplicationEntry';



function CadetActionsRow(props) {

    function a11yProps(index) {
        return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const useRowStyles = makeStyles({
    gridCell: {
        textAlign : "center",
    },
    rejectionNote : {
        margin : "20px",
    },
    modalContainer : {
      width : '100%',
      display : 'flex',
      'flex-direction' : 'column',
      'justify-content' : 'center',
      'align-items' : 'center',
    },
    filterInput : {
        width: "80%",
        margin: "auto",
        height: "45px",
        textAlign : 'center',
      },
      formInput: {
          width: "90%",
          minWidth : '250px',
          margin: "auto",
          marginBottom : '16px',
          height: "45px",
          textAlign : 'center',
      },
      textarea: {
          width: "90%",
          minWidth : '250px',
          minHeight : '250px',
          margin: "auto",
          marginBottom : '16px',
          height: "45px",
          textAlign : 'center',
      },
    });

    useEffect(()=>{
        setCadet(props.cadet);//every time this component receives new props, we update the state
    },[props.cadet])

    const [chosenTabId, setChosenTabId] = useState(0);
    
    const handleChange = (event, newValue) => {
        setChosenTabId(newValue);
    };

    const [cadet, setCadet] = useState({});
    const [viewProfile, setViewProfile] = useState(false);
    
    const [recruitmentInfoList, setRecruitmentInfoList] = useState([]);

    const classes = useRowStyles();

    const openProfile = () => {
        axios.get('users/'+props.cadet.user_id)
        .then(response => {
            setRecruitmentInfoList(response.data.data.recruitment_info);
            setChosenTabId(response.data.data.recruitment_info.length-1);
            setViewProfile(true);
        });
    }
    return (
        <Aux>
        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={props.colSpan}>
            <Collapse in={props.expandRow} timeout="auto" unmountOnExit>
                <Box margin={1}>
                    <Grid container spacing={2}>
                        <Grid className={classes.gridCell} item xs={3}>
                            <Button
                                variant="contained" 
                                color="primary"
                                onClick={()=>openProfile()}
                                >
                                Пълен профил
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Collapse>
            </TableCell>
        </TableRow>
        <SimpleModal open={viewProfile} onClose={()=>setViewProfile(false)}>
            <Grid container>
                <Grid className={classes.gridCell} item xs={12}>
                    <ProfilePage 
                        userId={cadet.user_id} 
                        candidateId={cadet.candidate_id}
                        updateCandidate={props.updateCandidate}
                        canEdit={true}
                    ></ProfilePage>
                </Grid>
                
                <Grid className={classes.gridCell} item xs={12}>
                    <h2>История на кандидатурите дотук:</h2>
                </Grid>

                <Grid className={classes.gridCell} item xs={12}>
                    <AppBar position="static">
                        <Tabs variant="fullWidth" value={chosenTabId} onChange={handleChange} aria-label="simple tabs example">
                            {
                                recruitmentInfoList.map((recruitmentInfo, index) => {
                                        return <Tab label={recruitmentInfo.season.name} {...a11yProps(index)} />
                                })
                            }
                        </Tabs>
                    </AppBar>
                    {
                        recruitmentInfoList.map((recruitmentInfo, index) => {
                            return (
                                <TabPanel value={chosenTabId} index={index}>
                                    <ApplicationEntry info={recruitmentInfo}></ApplicationEntry>
                                </TabPanel>
                            )
                        })
                    }
                    
                </Grid>
            </Grid>
        </SimpleModal>
        </Aux>
    );
}

export default CadetActionsRow;