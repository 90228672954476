import React, { useState } from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { TextField, Grid, makeStyles } from '@material-ui/core';
import { GreenButton } from '../../../components/buttons/GreenButton';
import axios from '../../../util/Axios/Axios';


function AutomaticEmailEdit(props) {

    const useStyles = makeStyles(theme => ({
        formInput: {
          width: "60%",
          margin: "auto",
          marginTop : '16px',
          marginBottom : '16px',
          height: "45px",
          textAlign : 'center',
        },
        centerdItems : {
            textAlign : "center",
        }
    }));


    const classes = useStyles();

    const [subject, setSubject] = useState(props.email.subject);
    const [body, setBody] = useState(props.email.body);

    const handleEditEmail = () => {
        axios.put('/notifications/emails', {
            id : props.email.id,
            occasion : props.email.occasion,
            subject : subject,
            body : body
        })
        .then(response => {
            
        })
    }

    return (
        <Grid container spacing={3} >
            <Grid item xs={12} className={classes.centerdItems}>
            <TextField
                className={classes.formInput}
                label="Заглавие"
                value={subject}
                type="text"
                onChange={(event) => setSubject(event.target.value)}
                />
            </Grid>
            <Grid item xs={12} className={classes.centerdItems}>
                <CKEditor
                    className={classes.formInput}
                    editor={ ClassicEditor }
                    data={body}  
                    onChange={ ( event, editor ) => {
                        setBody(editor.getData());
                    } }
                />
            </Grid>
            <Grid item xs={12} className={classes.centerdItems}>
                <GreenButton
                    className={classes.formInput}
                    onClick={handleEditEmail}>
                    Запази промените
                </GreenButton>
            </Grid>
        </Grid>
    )
}

export default AutomaticEmailEdit;