import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from '../../../util/Axios/Axios';
import AutomaticSMSEdit from './AutomaticSMSEdit';


function AutomaticSMSPage(props) {

    const useStyles = makeStyles((theme) => ({
        root: {
          width: '100%',
        },
        heading: {
          fontSize: theme.typography.pxToRem(15),
          flexBasis: '33.33%',
          flexShrink: 0,
        },
      }));

    const classes = useStyles();
    const [sms, setSMS] = useState([]);
    const [expanded, setExpanded] = useState(false);
   
    
    useEffect(()=>{
        axios.get('/notifications/sms').then(response => {setSMS(response.data.data);})}, 
        //eslint-disable-next-line
    [])
    
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className={classes.root}>
            {
                sms.map((sms) => (
                    <Accordion key={sms.id} expanded={expanded === 'panel'+sms.id} onChange={handleChange('panel'+sms.id)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography className={classes.heading}>{sms.occasion}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AutomaticSMSEdit sms={sms}></AutomaticSMSEdit>
                        </AccordionDetails>
                    </Accordion>
            ))
            }
        </div>
    )
}

export default AutomaticSMSPage;