import React, { PureComponent } from 'react';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip,
} from 'recharts';


export default class CustomAreaChart extends PureComponent {
  static jsfiddleUrl = 'https://jsfiddle.net/alidingling/Lrffmzfc/';

  render() {
    return (
      <AreaChart
        style={{margin:"auto"}}
        width={this.props.width}
        height={300}
        data={this.props.data}
        margin={{
          top: 10, right: 30, left: 0, bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={this.props.x_label} />
        <YAxis />
        <Tooltip />
        <Area type="monotone" dataKey={this.props.y_label} stroke="#8884d8" fill="#8884d8" />
      </AreaChart>
    );
  }
}
