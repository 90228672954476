import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Paper, Button, Link, FormControlLabel, Checkbox, useMediaQuery } from "@material-ui/core";
import { register } from '../../store/actions/register/registerActions';
import { useDispatch } from 'react-redux';
import { OPEN_LOGIN_PAGE } from '../../store/actions/generalActions';
import DateOfBirthPicker from '../../components/DateOfBirthPicker';
import { BASE_URL } from '../../util/Constants';
import axios from '../../util/Axios/Axios';

function Register() {

  const mobile = !useMediaQuery('(min-width:600px)');
  
  const useStyles = makeStyles(theme => ({
    paper: {
      width: mobile ? "95%" : "40%",
      margin: "auto",
      marginTop: "60px",
      display: "flex",
      padding : '16px',
      "flex-direction": "column",
      "justify-content" : "flex-start",

    },
    formInput: {
      width: "80%",
      margin: "auto",
      marginTop : '16px',
      marginBottom : '16px',
      height: "45px",
      'text-align' : 'center',
    },
    formLink: {
      width: "80%",
      margin: "auto",
      marginBottom : '8px',
      textAlign : 'center',
      cursor : 'pointer',
    },
    error : {
      color : 'red',
      textAlign : 'center',
    },
    hint : {
      width: "80%",
      margin: "auto",
      marginTop : '16px',
      marginBottom : '16px',
      height: "45px",
      'text-align' : 'center',
      color : 'grey',
      fontSize : 'small'
    },
    hidden : {
      display : 'none',
    },
    red : {
      width: "80%",
      margin: "auto",
      'text-align' : 'center',
      color : 'red',
      fontSize : 'small'
    },
    checkbox : {
      width: "80%",
      margin: "auto",
      padding : '0px',
      fontSize : 'small'
    },
    checkboxLabel : {
      fontSize : 'small'
    },
    heading : {
      'text-align' : 'center',
      margin: "auto",
    }
  }));

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [fullName, setFullName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(new Date(new Date().getFullYear()-18, new Date().getMonth(), new Date().getDate()));
  const [phoneNumber, setPhoneNumber] = useState("");
  const [checktTerms, setCheckedTerms] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();


  const dateChangeHandler = date => {setDateOfBirth(date);}

  const [nextSeason, setNextSeason] = useState(null);

  useEffect(()=>{
    axios.get('/seasons/next').then(response => { setNextSeason(response.data.data); })
  }, [])

  return (
      nextSeason && nextSeason.registration_open ? (
        <Paper className={classes.paper}>
          <h2 className={classes.heading}>Кандидатствай за безплатно обучение в ИТ Таланти</h2>
          <TextField
            required
            className={classes.formInput}
            label="Имейл"
            type="email"
            onChange={(event) => setEmail(event.target.value)}
          />
          <TextField
            required
            className={classes.formInput}
            label="Парола"
            type = "password"
            onChange={(event) => setPassword(event.target.value)}
          />
          <TextField
            required
            error = {password !== password2}
            className={classes.formInput}
            label="Повторете паролата"
            type = "password"
            helperText={password !== password2 ? "Passwords missmatch" : ""}
            onChange={(event) => setPassword2(event.target.value)}
          />
          <TextField
            required
            className={classes.formInput}
            label="Име и фамилия (на кирилица)"
            onChange={(event) => setFullName(event.target.value)}
          />
          <DateOfBirthPicker
            required
            onChange={dateChangeHandler}
            value={dateOfBirth}
            className={classes.formInput}
            label="Дата на раждане"
          >
          </DateOfBirthPicker>
          <TextField
            required
            className={classes.formInput}
            label="Телефонен номер"
            type="tel"
            onChange={(event) => setPhoneNumber(event.target.value)}
          />
          <FormControlLabel className={classes.checkbox}
            control={
              <Checkbox
                checked={checktTerms}
                onChange={(event) => setCheckedTerms(event.target.checked)}
                name="checkedB"
                color="primary"
              />
            }
            label={<p className={classes.checkboxLabel}>Съгласен съм с <a href={BASE_URL + '/uploads/Declaratsia_Podbor.pdf'} target="_blank" rel="noopener noreferrer">Общите Условия</a></p>}
          />
          <p className={checktTerms ? classes.hidden : classes.red}>Трябва да се съгласите с общите условия за да кандидатствате.</p>
          <p className={classes.hint}>*Съгласен съм да получавам известия чрез SMS от кратък номер 1990 по време на подборния процес</p>
          <Button 
            className={classes.formInput} 
            variant="contained" 
            color="primary"
            disabled = {!checktTerms}
            onClick={
              ()=> {
                window.history.pushState('state', 'title', '/');
                dispatch(register(email, password, password2, fullName, phoneNumber, dateOfBirth, mobile));
            }
          }>
            Регистрация
          </Button>
          <Link className={classes.formLink}  onClick={
            ()=> {
            window.history.pushState('state', 'title', '/');
            dispatch({type : OPEN_LOGIN_PAGE});
            }
          }>Вече имате акаунт? Влезте тук</Link>
        </Paper>
      ) : (
        <Paper className={classes.paper}>
            <h2 className={classes.heading}>ИТ Таланти преустановява организирането на курсове по програмиране</h2>
        </Paper>
      )
  );
}

export default Register;
