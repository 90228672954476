import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, useMediaQuery } from '@material-ui/core';
import { CANDIDATE_STATUS_ATTENDANCE_TEST_ADMITTED, CANDIDATE_STATUS_ONLINE_TEST_ADMITTED, TEST_NOT_TAKEN_SCORE } from '../../../util/Constants';

function TestResultsContainer(props) {

    const mobile = !useMediaQuery('(min-width:600px)');

    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(4),
            width: mobile ? "95%" : "60%",
            margin : 'auto',
            color: theme.palette.text.secondприary,
            marginBottom : "16px",
        },
        heading : {
            textAlign: 'center',
        }
    }));
    const classes = useStyles();
    const recruitmentInfo = props.recruitmentInfo;

    return (
        <div className={classes.root}>
            {
                //if there is no score yet calculated or if the candidate is still not qualified further or dropped OR it is Mladen :)
                !recruitmentInfo.online_test_score || recruitmentInfo.recruitment_state === CANDIDATE_STATUS_ONLINE_TEST_ADMITTED || recruitmentInfo.user_id === 173 ? (//173 e Младен от Scale, не искаме да вижда резултатите си
                    <Paper className={classes.paper}>
                        <h3 className={classes.heading}>Все още няма резултати от онлайн тест</h3>
                    </Paper>
                )
                : (
                    <Paper className={classes.paper}>
                    <h3 className={classes.heading}>Резултати от онлайн тест</h3>
                    {
                        (recruitmentInfo.online_test_score === TEST_NOT_TAKEN_SCORE) ? (
                            <h2 className={classes.heading}>Неявил се</h2>
                        ) : (
                            <ul>
                                <li>Позиция: <strong> {recruitmentInfo.online_test_global_position} </strong> от 707 кандидата</li>
                                {/* we display only the position since points may be negative and we dont want drama */}
                                {/* <li>Верни отговори: <strong>{recruitmentInfo.online_test_right_answers}</strong></li>
                                <li>Грешни отговори: <strong>{recruitmentInfo.online_test_wrong_answers}</strong></li>
                                <li>Неотговорени въпроси: <strong>{recruitmentInfo.online_test_unanswered}</strong></li> */}
                            </ul>
                        )
                    }
                    </Paper>
                )
            }
            {
                //If there is no score yet calculated or if the candidate is still not qualified further or dropped
                recruitmentInfo.attendance_test_score === null || recruitmentInfo.recruitment_state === CANDIDATE_STATUS_ATTENDANCE_TEST_ADMITTED ? (
                    <Paper className={classes.paper}>
                        <h3 className={classes.heading}>Все още няма резултати от присъствен тест</h3>
                    </Paper>
                )
                : (
                    <Paper className={classes.paper}>
                    <h3 className={classes.heading}>Резултати от присъствен тест</h3>
                    {
                        (recruitmentInfo.attendance_test_score === TEST_NOT_TAKEN_SCORE) ? (
                            <h2 className={classes.heading}>Неявил се</h2>
                        ) : (
                            <ul>
                                <li>Позиция: <strong> {recruitmentInfo.attendance_test_global_position} </strong> от 254 кандидата</li>
                                {/* we display only the position since points may be negative and we dont want drama */}
                                {/* <li>Верни отговори: <strong>{recruitmentInfo.attendance_test_right_answers}</strong></li>
                                <li>Грешни отговори: <strong>{recruitmentInfo.attendance_test_wrong_answers}</strong></li>
                                <li>Неотговорени въпроси: <strong>{recruitmentInfo.attendance_test_unanswered}</strong></li> */}
                            </ul>
                        )
                    }
                    </Paper>
                )
            }
        </div>
    );

}

export default TestResultsContainer;