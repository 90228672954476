import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

const useStyles = makeStyles(theme => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    'border-radius' : '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    'overflow-y' : 'auto',
    maxHeight : '100vh',
  },
}));

function SimpleModal(props) {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={props.open}
      onClose={props.onClose}>
      <div  className={classes.modal}>
        {props.children}
      </div>
    </Modal>
  );
}

export default SimpleModal;