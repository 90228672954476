import React, { useState, useEffect } from 'react';
import axios from '../../util/Axios/Axios';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import { TableContainer, Table, TableRow, TableCell, Paper, TableHead, TableBody, Select, TextField, makeStyles, FormControl, InputLabel, MenuItem, Grid, Switch, TablePagination } from '@material-ui/core';
import CandidatesPreliminaryRow from './CandidatesPreliminaryRow';
import { useSelector } from 'react-redux';
import CandidatesExportButton from '../../components/buttons/CandidatesExportButton';

function CandidatesPreliminaryView(props) {

  const useStyles = makeStyles(theme => ({
    filterInput : {
      width: "80%",
      margin: "auto",
      marginTop : '16px',
      marginBottom : '16px',
      height: "45px",
      textAlign : 'center',
    },
    gridCell : {
      textAlign : 'center'
    },
  }));

  const classes = useStyles();


  const filterCandidates = (name, phone, status, email, showAll) => {
    axios.post("/candidates/preliminary", {
      name : name === "" ? null : name,
      phone : phone === "" ? null : phone,
      season_id : props.season,
      status,
      email : email === "" ? null : email,
      all : showAll
    })
    .then(response => {
      setCandidates(response.data.data);
      setPage(0);
    })
  }

  const [candidates, setCandidates] = useState([]);
  const [filteredName, setFilteredName] = useState("");
  const [filteredPhone, setFilteredPhone] = useState("");
  const [filteredStatus, setFilteredStatus] = useState("");
  const [filteredEmail, setFilteredEmail] = useState("");
  const [showAll, setShowAll] = useState(false);
  const statuses = useSelector(state => state.loginReducer.candidatesStatuses);
  
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 50));
    setPage(0);
  };

  useEffect(()=> {
      filterCandidates(filteredName, filteredPhone, filteredStatus, filteredEmail, showAll);
      //eslint-disable-next-line
  }, [props.season])

  return (
    <Aux>
      <Paper>
        <Grid container>
          <Grid item xs={4} className={classes.gridCell} >
            <TextField
                label="Име"
                className = {classes.filterInput}
                value={filteredName}
                onChange={(event)=> {setFilteredName(event.target.value); filterCandidates(event.target.value, filteredPhone, filteredStatus, filteredEmail, showAll)}}
                />
          </Grid>
          <Grid item xs={4} className={classes.gridCell} >
            <TextField
                label="Имейл"
                className = {classes.filterInput}
                value={filteredEmail}
                onChange={(event)=> {setFilteredEmail(event.target.value); filterCandidates(filteredName, filteredPhone, filteredStatus, event.target.value, showAll)}}
                />
          </Grid>
          <Grid item xs={4} className={classes.gridCell} >
            <FormControl className={classes.filterInput}>
              <Grid container>
                <Grid className={classes.gridCell} item xs={7}>Покажи всички</Grid>
                <Grid className={classes.gridCell} item xs={5}>
                    <Switch
                      checked={showAll}
                      onChange={()=>{setShowAll(!showAll); filterCandidates(filteredName, filteredPhone, filteredStatus, filteredEmail, !showAll)}}
                      color="primary"
                    />
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
          <Grid item xs={4} className={classes.gridCell} >
            <TextField
                label="Телефон"
                className = {classes.filterInput}
                value={filteredPhone}
                onChange={(event)=> {setFilteredPhone(event.target.value); filterCandidates(filteredName, event.target.value, filteredStatus, filteredEmail, showAll)}}
                />
          </Grid>
          <Grid item xs={4} className={classes.gridCell} >
            <FormControl className={classes.filterInput}>
              <InputLabel>Статус</InputLabel>
              <Select
                  value={filteredStatus}
                  onChange={(event)=>{setFilteredStatus(event.target.value); filterCandidates(filteredName, filteredPhone, event.target.value, filteredEmail, showAll)}}
              >
                <MenuItem value={""}>Всички</MenuItem>
                {
                  statuses.map((status, key) => (
                    showAll ? 
                    <MenuItem key={status} value={status}>{status}</MenuItem>
                    : (
                    key === 0 || key === 1 ? 
                    <MenuItem key={status} value={status}>{status}</MenuItem>
                    : null)
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4} className={classes.gridCell} >
            <FormControl className={classes.filterInput}>
              <CandidatesExportButton
                candidates = {candidates}
                fileName = {"Предварителен_подбор_"+ new Date().toLocaleDateString("en-GB")}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" size="small">
            <TableHead>
            <TableRow>
                <TableCell style={{fontWeight : "bold"}}>Общо: {candidates.length} кандидата</TableCell>
                <TableCell>Имена</TableCell>
                <TableCell>Имейл</TableCell>
                <TableCell>Възраст</TableCell>
                <TableCell>Телефон</TableCell>
                <TableCell>Статус</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {candidates.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((cand, index) => (
                <CandidatesPreliminaryRow key={cand.id} candidate={cand}  order={index+1} />
            ))}
            </TableBody>
        </Table>
    </TableContainer>
    <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={candidates.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
    </Aux>
  );
}

export default CandidatesPreliminaryView;
