import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';

function DateTimeField(props) {

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <DateTimePicker
        {...props}
        className={props.className}
        margin="normal"
        label={props.label}
        format="dd/MM/yyyy hh:mm"
        onChange={props.onChange}
        value={props.value}
        />
  </MuiPickersUtilsProvider>
  )
}

DateTimeField.propTypes = {
    className : PropTypes.any,
    label :     PropTypes.string.isRequired,
    onChange :  PropTypes.func.isRequired,
    value :     PropTypes.any.isRequired
}

export default DateTimeField;
