import React from 'react';
import { green } from '@material-ui/core/colors';
import { Checkbox, withStyles } from '@material-ui/core';

export const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);