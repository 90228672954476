import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Select, InputLabel, FormControl, MenuItem, Grid } from '@material-ui/core';
import axios from '../../util/Axios/Axios';
import { useSelector } from 'react-redux';
import CustomStackedBarChart from '../../components/charts/CustomStackedBarChart';
import CustomAreaChart from '../../components/charts/CustomAreaChart';
import CustomPieChart from '../../components/charts/CustomPieChart';

function StatisticsPage(props) {
    
    const useStyles = makeStyles(theme => ({
        root: {
          flexGrow: 1,
          textAlign : 'center',
          backgroundColor: theme.palette.background.paper,
        },
        filterInput : {
          width: "80%",
          margin: "auto",
          marginTop : '16px',
          marginBottom : '16px',
          height: "45px",
          textAlign : 'center',
        },
        gridCell : {
            textAlign : 'center'
        },
    }));

    useEffect(() => {
        axios.get('/seasons').then(response => setSeasons(response.data.data));
        axios.get('/season/'+filteredSeason.id+'/stats/overall').then(response => setOveralStats(response.data.data));
        //eslint-disable-next-line
      }, []);
    
    const nextSeason = useSelector(state => state.loginReducer.nextSeason)
    const [filteredSeason, setFilteredSeason] = useState(nextSeason ? nextSeason : null);
    const [overalStats, setOveralStats] = useState(null);
    const [seasons, setSeasons] = useState([]);

    const classes = useStyles();

    return (
        <div className={classes.root}>
          <FormControl className={classes.filterInput}>
            <InputLabel>Сезон</InputLabel>
            <Select
                value={filteredSeason}
                onChange={(event)=>{setFilteredSeason(event.target.value)}}
            >
              <MenuItem value={""}>Всички</MenuItem>
              {
                seasons.map(season => (
                  <MenuItem key={season.id} value={season}>{season.name}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
            <h2>Статистики за {filteredSeason === "" ? "всички сезони" : filteredSeason.name}</h2>
            <hr></hr>
            {
                overalStats && (
                    <Grid container>
                        <Grid item xs={12} className={classes.gridCell} >
                            <h3>Общо кандидатствали - {overalStats.season_info.candidates} човека в рамките на {overalStats.overall_stats.registered_by_day.length} дни</h3>
                            <p>Разбивка по дни</p>
                        </Grid>
                        <Grid item xs={12} className={classes.gridCell} >
                            <CustomAreaChart 
                            width={1000}
                            data={overalStats.overall_stats.registered_by_day}
                            x_label="day"
                            y_label="registered">

                            </CustomAreaChart>
                        </Grid>
                        <Grid item xs={12} md={5} className={classes.gridCell} >
                            <p>Разбивка по пол</p>
                            <CustomPieChart
                                width={250}
                                data={[
                                    { name: 'Мъже', value: overalStats.overall_stats.males },
                                    { name: 'Жени', value: overalStats.overall_stats.females },
                                  ]}>
                            </CustomPieChart>
                        </Grid>
                        <Grid item xs={12} md={7} className={classes.gridCell} >
                            <p>Разбивка по възраст</p>
                            <CustomStackedBarChart
                                width={500}
                                data={overalStats.overall_stats.age_groups}
                                x_label="name"
                                y_label="Регистрации"></CustomStackedBarChart>
                        </Grid>
                    </Grid>
                )
            }
        </div>
    );

}

export default StatisticsPage;