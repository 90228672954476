import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Paper, Button} from "@material-ui/core";
import axios from '../../util/Axios/Axios';
import { useDispatch } from 'react-redux';
import DateField from '../../components/DateField';
import { MENU_OPEN_SEASONS_VIEW } from '../../store/actions/generalActions';

function SeasonsAddPage() {

  const useStyles = makeStyles(theme => ({
    paper: {
      width: "40%",
      margin: "auto",
      marginTop: "60px",
      display: "flex",
      padding : '16px',
      "flex-direction": "column",
      "justify-content" : "flex-start",
    },
    formInput: {
      width: "80%",
      margin: "auto",
      marginTop : '16px',
      marginBottom : '16px',
      height: "45px",
      'text-align' : 'center',
    },
  }));

  
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleAddSeason = event => {
    axios.post("/seasons", {
      name : name,
      start_date : startDate,
      end_date : endDate
    })
    .then(response => {
      dispatch({type : MENU_OPEN_SEASONS_VIEW});
    })
  }

  return (
    <Paper className={classes.paper}>
      <TextField
        required
        value={name}
        className={classes.formInput}
        label="Име"
        type="text"
        onChange={(event) => setName(event.target.value)}
      />
      <DateField
        required
        value={startDate}
        className={classes.formInput}
        label="Начало"
        onChange= {date => {setStartDate(date)}}
      />
      <DateField
        required
        value={endDate}
        className={classes.formInput}
        label="Край"
        onChange={date => {setEndDate(date)}}
      />
      <Button 
        className={classes.formInput} 
        variant="contained" 
        color="primary"
        onClick={handleAddSeason}
        >
        Добави нов сезон
      </Button>
    </Paper>

  );
}

export default SeasonsAddPage;
