import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import loginReducer from './store/reducers/login/loginReducer';
import generalReducer from './store/reducers/generalReducer'
import { Provider} from 'react-redux'
import thunk from 'redux-thunk';
import ErrorToast from './components/toasts/ErrorToast';
import SuccessToast from './components/toasts/SuccessToast';
import LoadingDiv from './components/LoadingDiv';

const rootReducer = combineReducers({
    generalReducer,
    loginReducer
});
 
let composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// if (process.env.NODE_ENV === 'development') {
//     const whyDidYouRender = require('@welldone-software/why-did-you-render');
//     whyDidYouRender(React, {
//       trackAllPureComponents: true,
//     });
//   }

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))

ReactDOM.render(
    <Provider store={store}>
        <App/>
        <LoadingDiv></LoadingDiv>
        <ErrorToast></ErrorToast>
        <SuccessToast></SuccessToast>
    </Provider>, 
    document.getElementById('root'));

    
    if (module.hot) {
        module.hot.accept('./App', () => {
            ReactDOM.render(
            <Provider store={store}>
                <App />
                <LoadingDiv></LoadingDiv>
                <ErrorToast></ErrorToast>
                <SuccessToast></SuccessToast>
            </Provider>,
            document.getElementById('root'),
            )
        })
    }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
