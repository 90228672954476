import React, { useState, useEffect } from 'react';
import axios from '../../util/Axios/Axios';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import { TableContainer, Table, TableRow, TableCell, Paper, TableHead, TableBody, makeStyles, TextField } from '@material-ui/core';
import { BASE_URL, COMPANIES_STATUS_GOLD_NAME, COMPANIES_STATUS_SILVER_NAME, COMPANIES_STATUS_GOLD } from '../../util/Constants';
import nologo from '../../assets/nologo.jpg';

function CompaniesViewPage() {
    
    const useStyles = makeStyles(theme => ({
        filter : {
          display : 'flex',
          'justify-content' : 'center',
          marginTop : '16px',
          marginBottom : '16px',
          padding : '16px',
        },
        filterInput : {
          width : '30%',
          marginRight : '32px',
        },
        logoImage : {
          margin: "auto",
          borderRadius : '50%',
          height : '80px',
          width : '80px',
          objectFit: 'cover',
        },
        white : {
            backgroundColor : 'white',
        },
        silver : {
            backgroundColor : 'silver',
        }
      }));

  useEffect(() => {
    filterCompanies("")
  }, []);

  const classes = useStyles();
  const [companies, setCompanies] = useState([]);
  const [nameFilter, setNameFilter] = useState("");

  const filterNameHandler = (event) => {
      setNameFilter(event.target.value);
      filterCompanies(event.target.value);
  }

  function filterCompanies(name){
    axios.post('/companies/filter', {name : name})
        .then(response => {
            const companies = response.data.data;
            setCompanies(companies);
        })
        .catch(error=>{
          //TODO
        })
  }

  return (
    <Aux>
      <Paper className={classes.filter}>
        <TextField
            value = {nameFilter}
            className = {classes.filterInput}
            onChange={filterNameHandler}
            label="Търси по име"
            />
      </Paper>
    <TableContainer component={Paper}>
        <Table aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell>Име</TableCell>
                <TableCell >Лого</TableCell>
                <TableCell >Описание</TableCell>
                <TableCell align="right">Статус</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {companies.map(company => (
                <TableRow key={company.id} className={ company.status === COMPANIES_STATUS_GOLD ? classes.silver : classes.white}>
                  <TableCell >{company.name}</TableCell>
                  <TableCell padding="none">
                    <img className={classes.logoImage} src={company.logo ? BASE_URL + company.logo.file_url : nologo} alt={company.name}></img>
                  </TableCell>
                  <TableCell >{company.description}</TableCell>
                  <TableCell align="right">{company.status === COMPANIES_STATUS_GOLD ? COMPANIES_STATUS_GOLD_NAME : COMPANIES_STATUS_SILVER_NAME}</TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
    </TableContainer>
    </Aux>
  );
}

export default CompaniesViewPage;
