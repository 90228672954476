import React, { useState, useEffect } from 'react';
import axios from '../../../util/Axios/Axios';
import { TableContainer, Table, TableRow, TableCell, Paper, TableHead, TableBody, IconButton, Button, Grid, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddQuestionModal from './AddQuestionModal';
import EditQuestionModal from './EditQuestionModal';

function SeasonsViewPage() {

    const useStyles = makeStyles(theme => ({
        gridCell : {
        display : "flex",
        alignItems : "center",
        justifyContent : "center"
        },
        paper : {
            width : "100%",
            textAlign : "center"
        }
    }));

    const classes = useStyles();

    useEffect(() => {
        getQuestions();
    }, []);

    const [questions, setQuestions] = useState([]);

    const [openAddQuestion, setOpenAddQuestion] = useState(false);
    const [openEditQuestion, setOpenEditQuestion] = useState(false);
    const [editedQuestion, setEditedQuestion] = useState(null);
    const [totalQuestions, setTotalQuestions] = useState(0);

    const getQuestions = () => {
        axios.get('/survey')
        .then(response => {
            const questions = response.data.data;
            setQuestions(questions)
            setTotalQuestions(questions.length);
        })
    }

    const handleDeleteQuestion = (questionId) => {
        axios.delete('survey/questions/'+questionId).then(response => getQuestions());
    }

    return (
        <Grid container>
            <Grid item xs={12} className={classes.gridCell}>
                <Paper className={classes.paper}>
                    <h1>Начален въпросник</h1>
                    <Button
                        variant="contained"
                        color="primary" 
                        onClick={()=> setOpenAddQuestion(true)}>
                            Добави въпрос
                    </Button>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Пореден номер</TableCell>
                                <TableCell>Въпрос</TableCell>
                                <TableCell>Отворен</TableCell>
                                <TableCell >Редакция</TableCell>
                                <TableCell >Изтриване</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {questions.map(question => (
                            <TableRow key={question.id}>
                                <TableCell >{question.order_number}</TableCell>
                                <TableCell >{question.question}</TableCell>
                                <TableCell >{question.is_open ? "Да" : "Не"}</TableCell>
                                <TableCell >
                                    <IconButton onClick={()=> { 
                                        setEditedQuestion(question); 
                                        setOpenEditQuestion(true);}}>
                                        <EditIcon/>
                                    </IconButton>
                                </TableCell>
                                <TableCell ><IconButton onClick={()=>handleDeleteQuestion(question.id)}><DeleteIcon/></IconButton></TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12}>
                <AddQuestionModal
                    open={openAddQuestion}
                    onClose={(questionAdded)=> {
                        if(questionAdded){
                            getQuestions();
                        }
                        setOpenAddQuestion(false);
                    }}
                    order_number={totalQuestions + 1}
                ></AddQuestionModal>
                <EditQuestionModal
                    open={openEditQuestion}
                    onClose={(questionEdited)=> {
                        if(questionEdited){
                            getQuestions();
                        }
                        setOpenEditQuestion(false);
                    }}
                    question={editedQuestion}
                ></EditQuestionModal>
            </Grid>
        </Grid>
    );
}

export default SeasonsViewPage;
