import React, { useEffect } from 'react';
import './App.css';
import Header from './components/Header'
import Menu from './components/Menu'
import { makeStyles, CssBaseline, ThemeProvider, createMuiTheme } from '@material-ui/core';
import Content from './components/Content'
import clsx from 'clsx';
import { MENU_WIDTH, HEADER_HEIGHT, PRIMARY_BLUE, FOOTER_HEIGHT } from './util/Constants';
import { useSelector, useDispatch } from 'react-redux';
import { ACTION_LOG_IN } from './store/actions/login/loginActions';
import axios from './util/Axios/Axios'
import { OPEN_REGISTER_PAGE } from './store/actions/generalActions';
import Footer from './components/Footer';
import ReactPixel from 'react-facebook-pixel';

const drawerWidth = MENU_WIDTH;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    // height : "calc(100vh - "+HEADER_HEIGHT+"px)",
    marginTop : HEADER_HEIGHT,
    marginBottom : FOOTER_HEIGHT,
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

function App() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const open = useSelector(state => state.generalReducer.showMenu);
  const loggedIn = useSelector(state => state.loginReducer.loggedIn);

  const shouldLogIn = !loggedIn && localStorage["auth"];
  if(shouldLogIn) {
    axios.get('/candidates/statuses')
      .then(statusesResponse => {
        const nextSeason = JSON.parse(localStorage["next_season"]);
        axios.get("seasons/"+nextSeason.id+"/courses")
        .then(coursesResponse => {
          dispatch({
            type: ACTION_LOG_IN,
            userId : localStorage["user_id"],
            userRoleId : localStorage["user_role_id"],
            nextSeason : nextSeason,
            candidatesStatuses : statusesResponse.data.data,
            nextSeasonCourses : coursesResponse.data.data
          });
        })
        
      })
  }
  else{
    if(window.location.href.endsWith('/register')){
      dispatch({type : OPEN_REGISTER_PAGE});
    }
  }

  const theme = createMuiTheme({
    typography: {
      fontFamily: [
        'Roboto',
        'sans-serif'
      ].join(','),
    },
    palette: {
      primary: {
        main : PRIMARY_BLUE,
      },
    },
    overrides: {
      MuiTableRow: {
        root: {  '&$selected, &$selected:hover': { backgroundColor: PRIMARY_BLUE }  }
      },
     }
    
  });

  useEffect(()=>{
    const advancedMatching = { em: 'krasimir.stoev@ittalents.bg' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
    };
    ReactPixel.init('224670301546910', advancedMatching, options);
  },[loggedIn])

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <Header></Header>
        <Menu></Menu>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
          <Content></Content>
        </main>
        <Footer></Footer>
      </div>
    </ThemeProvider>
  );
}

export default App;
