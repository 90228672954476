import React, { useState } from 'react';
import { Divider, Drawer, List, Collapse, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import ListIcon from '@material-ui/icons/List';
import CompanyIcon from '@material-ui/icons/Business';
import CadetsIcon from '@material-ui/icons/Group';
import ProfileIcon from '@material-ui/icons/AccountCircle';
import SeasonIcon from '@material-ui/icons/Timelapse';
import InterviewIcon from '@material-ui/icons/QuestionAnswer';
import { MENU_WIDTH, USER_ROLE_ADMINISTRATOR_ID, USER_ROLE_HR_ID } from '../util/Constants';
import { useSelector, useDispatch } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import { HIDE_MENU, MENU_OPEN_MY_TEST_PREPARATION, MENU_OPEN_MY_PROFILE, MENU_OPEN_COMPANIES_ADD, MENU_OPEN_COMPANIES_VIEW, MENU_OPEN_SEASONS_ADD, MENU_OPEN_SEASONS_VIEW, MENU_OPEN_CADETS_RECRUITMENT, MENU_OPEN_INTERVIEWS, MENU_OPEN_AUTO_NOTIFICATIONS, MENU_OPEN_MANUAL_NOTIFICATIONS, MENU_OPEN_MY_HOME, MENU_OPEN_MY_INBOX, MENU_OPEN_MY_TEST_RESULTS, MENU_OPEN_MY_FAQ, MENU_OPEN_STATISTICS, MENU_OPEN_CADETS_IN_SEASON } from '../store/actions/generalActions';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import NotificationsIcon from '@material-ui/icons/NotificationImportant';
import HomeIcon from '@material-ui/icons/Home';
import InboxIcon from '@material-ui/icons/Inbox';
import TestPreparationIcon from '@material-ui/icons/Edit';
import TestResultsIcon from '@material-ui/icons/DoneAll';
import StatisticsIcon from '@material-ui/icons/Equalizer';
import HelpIcon from '@material-ui/icons/Help';

import AutoNotificationIcon from '@material-ui/icons/Autorenew';
import ManualNotificationIcon from '@material-ui/icons/Send';
import clsx from 'clsx';

function Menu() { 

  const drawerWidth = MENU_WIDTH;
  const open = useSelector(state => state.generalReducer.showMenu);
  const dispatch = useDispatch();
  const theme = useTheme();

  
  const [openCadets, setOpenCadets] = useState(false);
  const [openCompanies, setOpenCompanies] = useState(false);
  const [openSeasons, setOpenSeasons] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);

  const userRoleId = useSelector(state => state.loginReducer.userRoleId);


  const useStyles = makeStyles(theme => ({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    hidden : {
      display : "none"
    },
  }));

  const classes = useStyles();
  const selectedItem = useSelector(state => state.generalReducer.pageSelected);

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={!!open}//!! means auto convert to boolean
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={() => dispatch({type: HIDE_MENU})}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
      <Divider />
        <List>
          <ListItem button component="a" selected={selectedItem === MENU_OPEN_MY_HOME} onClick={()=>dispatch({type : MENU_OPEN_MY_HOME})} >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Начало" />
          </ListItem>

          <ListItem button component="a" selected={selectedItem === MENU_OPEN_MY_PROFILE} onClick={()=>dispatch({type : MENU_OPEN_MY_PROFILE})} >
            <ListItemIcon>
              <ProfileIcon />
            </ListItemIcon>
            <ListItemText primary="Моят профил" />
          </ListItem>
          
          <ListItem button component="a" selected={selectedItem === MENU_OPEN_MY_INBOX} onClick={()=>dispatch({type : MENU_OPEN_MY_INBOX})} >
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Входяща поща" />
          </ListItem>

          <ListItem button component="a" selected={selectedItem === MENU_OPEN_MY_TEST_PREPARATION} onClick={()=>dispatch({type : MENU_OPEN_MY_TEST_PREPARATION})} >
            <ListItemIcon>
              <TestPreparationIcon />
            </ListItemIcon>
            <ListItemText primary="Подготовка за тест" />
          </ListItem>

          <ListItem button component="a" selected={selectedItem === MENU_OPEN_MY_TEST_RESULTS} onClick={()=>dispatch({type : MENU_OPEN_MY_TEST_RESULTS})} >
            <ListItemIcon>
              <TestResultsIcon />
            </ListItemIcon>
            <ListItemText primary="Преглед на резултати" />
          </ListItem>

          <ListItem button component="a" selected={selectedItem === MENU_OPEN_MY_FAQ} onClick={()=>dispatch({type : MENU_OPEN_MY_FAQ})} >
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText primary="Често задавани въпроси" />
          </ListItem>
          
          <ListItem button  onClick={()=> setOpenCadets(!openCadets)} className={clsx({[classes.hidden]: userRoleId !== USER_ROLE_ADMINISTRATOR_ID})}>
            <ListItemIcon>
              <CadetsIcon />
            </ListItemIcon>
            <ListItemText primary="Кадети" />
            {openCadets ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openCadets} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} component="a" selected={selectedItem === MENU_OPEN_CADETS_RECRUITMENT} onClick={()=>dispatch({type : MENU_OPEN_CADETS_RECRUITMENT})} >
                <ListItemIcon>
                  <ListIcon />
                </ListItemIcon>
                <ListItemText primary="Подбор" />
              </ListItem>
              <ListItem button className={classes.nested} component="a" selected={selectedItem === MENU_OPEN_CADETS_IN_SEASON} onClick={()=>dispatch({type : MENU_OPEN_CADETS_IN_SEASON})} >
                <ListItemIcon>
                  <ListIcon />
                </ListItemIcon>
                <ListItemText primary="В сезона" />
              </ListItem>
            </List>
          </Collapse>
          
          <ListItem button component="a" selected={selectedItem === MENU_OPEN_INTERVIEWS} onClick={()=>dispatch({type : MENU_OPEN_INTERVIEWS})}
            className={clsx({[classes.hidden]: userRoleId !== USER_ROLE_HR_ID && userRoleId !== USER_ROLE_ADMINISTRATOR_ID})} 
          >          
            <ListItemIcon>
              <InterviewIcon />
            </ListItemIcon>
            <ListItemText primary="Интервюта" />
          </ListItem>
          
          <ListItem button onClick={() => setOpenSeasons(!openSeasons)} className={clsx({[classes.hidden]: userRoleId !== USER_ROLE_ADMINISTRATOR_ID})}>
              <ListItemIcon>
                <SeasonIcon />
              </ListItemIcon>
              <ListItemText primary="Сезони" />
              {openSeasons ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openSeasons} timeout="auto" unmountOnExit>
            <List component="div" disablePadding >
                <ListItem button className={classes.nested} component="a" selected={selectedItem === MENU_OPEN_SEASONS_VIEW} onClick={()=>dispatch({type : MENU_OPEN_SEASONS_VIEW})} >
                  <ListItemIcon>
                    <ListIcon />
                  </ListItemIcon>
                  <ListItemText primary="Преглед" />
                </ListItem>
              <ListItem button className={classes.nested} component="a" selected={selectedItem === MENU_OPEN_SEASONS_ADD} onClick={()=>dispatch({type : MENU_OPEN_SEASONS_ADD})} >
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText primary="Нов сезон" />
              </ListItem>
            </List>
          </Collapse>
          <ListItem button onClick={() => setOpenCompanies(!openCompanies)} className={clsx({[classes.hidden]: userRoleId !== USER_ROLE_ADMINISTRATOR_ID})}>
            <ListItemIcon>
              <CompanyIcon />
            </ListItemIcon>
            <ListItemText primary="Фирми" />
            {openCompanies ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openCompanies} timeout="auto" unmountOnExit>
            <List component="div" disablePadding >
              <ListItem button className={classes.nested} component="a"  selected={selectedItem === MENU_OPEN_COMPANIES_VIEW} onClick={()=>dispatch({type : MENU_OPEN_COMPANIES_VIEW})} >
                  <ListItemIcon>
                    <ListIcon />
                  </ListItemIcon>
                  <ListItemText primary="Преглед" />
                </ListItem>
              <ListItem button className={classes.nested} component="a" selected={selectedItem === MENU_OPEN_COMPANIES_ADD} onClick={()=>dispatch({type : MENU_OPEN_COMPANIES_ADD})} >
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText primary="Нова фирма" />
              </ListItem>
            </List>
          </Collapse>
          
          <ListItem button onClick={() => setOpenNotifications(!openNotifications)} className={clsx({[classes.hidden]: userRoleId !== USER_ROLE_ADMINISTRATOR_ID})}>
              <ListItemIcon>
                <NotificationsIcon />
              </ListItemIcon>
              <ListItemText primary="Известия" />
              {openNotifications ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openNotifications} timeout="auto" unmountOnExit>
            <List component="div" disablePadding >
              <ListItem button className={classes.nested} component="a" selected={selectedItem === MENU_OPEN_AUTO_NOTIFICATIONS} onClick={()=>dispatch({type : MENU_OPEN_AUTO_NOTIFICATIONS})} >
                <ListItemIcon>
                  <AutoNotificationIcon />
                </ListItemIcon>
                <ListItemText primary="Автоматични" />
              </ListItem>
              <ListItem button className={classes.nested} component="a" selected={selectedItem === MENU_OPEN_MANUAL_NOTIFICATIONS} onClick={()=>dispatch({type : MENU_OPEN_MANUAL_NOTIFICATIONS})} >
                <ListItemIcon>
                  <ManualNotificationIcon />
                </ListItemIcon>
                <ListItemText primary="Ръчно изпращане" />
              </ListItem>
            </List>
          </Collapse>

          <ListItem button component="a" selected={selectedItem === MENU_OPEN_STATISTICS} onClick={()=>dispatch({type : MENU_OPEN_STATISTICS})}
            className={clsx({[classes.hidden]: userRoleId !== USER_ROLE_HR_ID && userRoleId !== USER_ROLE_ADMINISTRATOR_ID})} 
          >          
            <ListItemIcon>
              <StatisticsIcon />
            </ListItemIcon>
            <ListItemText primary="Статистики" />
          </ListItem>
        </List>
      <Divider />
    </Drawer>
  );

}

export default Menu;