
import { green } from '@material-ui/core/colors';
import { Button, withStyles } from '@material-ui/core';

export const GreenButton = withStyles(theme => ({
    root: {
      color: theme.palette.getContrastText(green[500]),
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    }
  }))(Button);