import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useMediaQuery } from '@material-ui/core';


function FAQPage(props) {

    const mobile = !useMediaQuery('(min-width:600px)');

    const useStyles = makeStyles((theme) => ({
        root: {
            width: mobile ? "95%" : "60%",
            margin : 'auto',
        },
        heading: {
          fontSize: theme.typography.pxToRem(15),
        },
      }));

    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    
    const faqs = [
        {
            id : 1,
            question : "Кой и кога ще се свърже с мен?",
            answer : "Връзката с кандидатите се осъществява чрез онлайн платформа след успешна регистрация в системата. По време на подбора, кандидатите ще получават известия чрез имейл и смс от кратък номер 1990. Прогресът на кандидатурата и резултатите от приемните тестове може да следите на https://trainingcamp.ittalents.bg/"
        },
        {
            id : 2,
            question : "Какви са етапите на кандидатстване в конкурса?",
            answer : "Първият етап е изпращане на формуляр за регистрация. След преглеждане на кандидатурата и одобрение, ще получите покана за решаване на онлайн логически тест в онлайн плтаформата ни на адрес https://trainingcamp.ittalents.bg/. Тестовте ни не са обвързани с предварителни знания в сферата. Кандидатите с най-висок резултат от първия тест, се явяват на втори - присъствен тест на място в учебния център. Последният и решаващ етап в конкурса е кратко интервю, на което ще бъдат поканени кандидатите с най-добър резултат от втория приемен тест."
        },
        {
            id : 3,
            question : "Какво представляват приемните тестове?",
            answer : "Тестовете са съставени от логически задачи. Тяхната цел е да провери и оцени логическия и аналитичен аспект на мисленето на кандидатите."
        },
        {
            id : 4,
            question : "Каква е формата на обучението?",
            answer : "Занятията в курсовете са изцяло присъствени и програмата е много интензивна. В условията на епидемиологична обстановка, ако здравните власти ограничат възможността за присъствени обучения в залата ни, предвиждаме лекциите да се провеждат онлайн, но със задължително присъствие. Т.е. в определения ден и час, курсистите трябва да бъдат онлайн, заедно с всички от групата. Ако има занятия в учебната зала, ще са при определен ред, също със задължително присъствие. Според ситуацията с COVID-19, имаме готовност да се премине в изцяло онлайн форма, ако се наложи. Курсовете се провеждат в работно време. Обучението ще бъде комбинация от лекции, упражнения, домашни задания и тестове."
        },
        {
            id : 5,
            question : "Ще има ли допълнителни домашни задачи за работа извън Тренировъчния Лагер?",
            answer : "Цялото обучение ще бъде съпроводено с даването на допълнителни задачи за вкъщи, както и с достатъчно допълнителна литература."
        },
        {
            id : 6,
            question : "Задължително ли е присъствието?",
            answer : "Присъствието на занятията е задължително. Освен това трябва да се отделят и по няколко часа на ден за подготовка вкъщи."
        },
        {
            id : 7,
            question : "Къде ще се провежда обучението?",
            answer : "Курсовете се провеждат в град София на адрес: South Side Business Center, ул. \"Майстор Алекси Рилец\" №38, ет. 1. Ако ситуацията с COVID-19 го налага, ще се провеждат в онлайн среда, чрез платформата Zооm Meеtings."
        },
        {
            id : 8,
            question : "На какъв език ще се проведе обучението?",
            answer : "Курсовете са подготвени на български език, но е желателно владеенето на английски език."
        },
        {
            id : 9,
            question : "Обучението подходящо ли е за непълнолетни лица?",
            answer : "Предлаганите безплатни курсове не са подходящи за непълнолетни лица."
        },
        {
            id : 10,
            question : "Подходящо ли е обучението за хора с опит в областта?",
            answer : "Да, в случай, че биха желали да надградят и развият своите теоретични и практически умения в областта."
        },
        {
            id : 11,
            question : "Обучението подходящо ли е за хора, които искат само да повишат квалификацията или да придобият нова такава?",
            answer : "Не. Обучението е предназначено само за хора, които имат мотивация и възможност да започнат работа на трудов договор в сферата на програмирането веднага след завършване на курса."
        },
        {
            id : 12,
            question : "Кандидатствал съм преди, отново ли трябва да се включвам в подбора?",
            answer : "Да. Дори да сте стигнали до последния етап от подбор в минал сезон на Тренировъчния лагер, е необходимо отново да минете през целия процес"
        },
        {
            id : 13,
            question : "Обучението обвързано ли е с подписване на договор?",
            answer : "Да, всички кадети подписват договор за обучение преди започване на курса. Договорът е за срока на обучението и не е трудов договор."
        },
        {
            id : 14,
            question : "Всички кадети ли завършват успешно обучението?",
            answer : "Не, кадетите с най-слаби текущи резултати отпадат от групите по време на обучението. Това става посредством контролни изпити с предварително зададени критерии и прагове на успеваемост. Само най-добрите ще оцелеят до края."
        },
        {
            id : 15,
            question : "Всички успешно завършили ли получават предложения за работа?",
            answer : "На всички завършили кадети се организират поредица от интервюта, а предложенията за работа зависят единствено и само от собственото им представяне, както и от текущите нужди от кадри в компаниите. ИТ Таланти подготвя максимално добре своите кадети както за работа, така и за успешното им представяне на интервю."
        },
        {
            id : 16,
            question : "Получавам ли документ за завършения курс?",
            answer : "Сертификат получават само успешно завършилите кадети след четвъртият месец от завършването и след препоръка от работодателя им."
        },
    ]

    
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className={classes.root}>
            {
                faqs.map((faq) => (
                    <Accordion expanded={expanded === 'panel'+faq.id} onChange={handleChange('panel'+faq.id)} key={faq.id}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography className={classes.heading}><strong>{faq.question}</strong></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{faq.answer}</Typography>
                        </AccordionDetails>
                    </Accordion>
            ))
            }
        </div>
    )
}

export default FAQPage;