import React, { useState, useEffect } from 'react';
import axios from '../../util/Axios/Axios';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import { TableContainer, Table, TableRow, TableCell, Paper, TableHead, TableBody, TextField, makeStyles, Grid } from '@material-ui/core';
import CadetViewRow from './CadetViewRow';


function CadetsInCoursePage(props) {

  const useStyles = makeStyles(theme => ({
    filterInput : {
      width: "80%",
      margin: "auto",
      marginTop : '16px',
      marginBottom : '16px',
      height: "45px",
      textAlign : 'center',
    },
    gridCell : {
      textAlign : 'center'
    },
  }));

  const classes = useStyles();


  const filterCadets = (name, phone, email) => {
    axios.post("/cadets", {
      name : name === "" ? null : name,
      phone : phone === "" ? null : phone,
      email : email === "" ? null : email,
      course_id : props.course
    })
    .then(response => {
      setCadets(response.data.data);
    })
  }

  const [cadets, setCadets] = useState([]);
  const [filteredName, setFilteredName] = useState("");
  const [filteredPhone, setFilteredPhone] = useState("");
  const [filteredEmail, setFilteredEmail] = useState("");


  useEffect(()=> {
    filterCadets(filteredName, filteredPhone, filteredEmail);
      //eslint-disable-next-line
  }, [props.season, props.course])


  return (
    <Aux>
    <Paper>
      <Grid container>
        <Grid item xs={4} className={classes.gridCell} >
          <TextField
              label="Име"
              className = {classes.filterInput}
              value={filteredName}
              onChange={(event)=> {setFilteredName(event.target.value); filterCadets(event.target.value, filteredPhone, filteredEmail)}}
              />
        </Grid>
        <Grid item xs={4} className={classes.gridCell} >
          <TextField
              label="Имейл"
              className = {classes.filterInput}
              value={filteredEmail}
              onChange={(event)=> {setFilteredEmail(event.target.value); filterCadets(filteredName, filteredPhone, event.target.value)}}
              />
        </Grid>
        <Grid item xs={4} className={classes.gridCell} >
          <TextField
              label="Телефон"
              className = {classes.filterInput}
              value={filteredPhone}
              onChange={(event)=> {setFilteredPhone(event.target.value); filterCadets(filteredName, event.target.value, filteredEmail)}}
              />
        </Grid>
      </Grid>
    </Paper>
    <TableContainer component={Paper}>
        <Table aria-label="simple table" size="small">
            <TableHead>
            <TableRow>
                <TableCell>Детайли</TableCell>
                <TableCell style={{fontWeight : "bold"}}>Общо: {cadets.length} кадета</TableCell>
                <TableCell>Имена</TableCell>
                <TableCell>Имейл</TableCell>
                <TableCell>Телефон</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {cadets.map((cadet, index) => (
                <CadetViewRow cadet={cadet} index={index}></CadetViewRow>
            ))}
            </TableBody>
        </Table>
    </TableContainer>
    </Aux>
  );
}

export default CadetsInCoursePage;
