import React, { useState, useEffect } from 'react';
import axios from '../../util/Axios/Axios';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import EditIcon from '@material-ui/icons/Settings';
import { TableContainer, Table, TableRow, TableCell, Paper, TableHead, TableBody, IconButton } from '@material-ui/core';
import { OPEN_SEASON_SETTINGS_PAGE } from '../../store/actions/generalActions';
import { useDispatch } from 'react-redux';

function SeasonsViewPage() {

  useEffect(() => {
    axios.get('/seasons')
        .then(response => {
            const seasons = response.data.data;
            setSeasons(seasons)
        })
  }, []);

  const [seasons, setSeasons] = useState([]);
  const dispatch = useDispatch();

  return (
    <Aux>
    <TableContainer component={Paper}>
        <Table aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell>Име</TableCell>
                <TableCell >Началo</TableCell>
                <TableCell >Край</TableCell>
                <TableCell >Технологии</TableCell>
                <TableCell >Курсове</TableCell>
                <TableCell >Кандидатствали</TableCell>
                <TableCell >Настройки</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {seasons.map(season => (
              <Aux key={season.id}>
                <TableRow key={season.id}>
                  <TableCell >{season.name}</TableCell>
                  <TableCell >{new Date(season.start_date).toDateString()}</TableCell>
                  <TableCell >{new Date(season.end_date).toDateString()}</TableCell>
                  <TableCell >
                    {
                      season.technologies.map((tech, key) => {return key === season.technologies.length-1 ? tech.name : tech.name + " / "})
                    }
                  </TableCell>
                  <TableCell >{season.courses}</TableCell>
                  <TableCell >{season.candidates}</TableCell>
                  <TableCell >
                    <IconButton onClick={()=>dispatch({type : OPEN_SEASON_SETTINGS_PAGE, seasonViewedId : season.id})}>
                      <EditIcon/>
                    </IconButton>
                  </TableCell>
                </TableRow>
              </Aux>
            ))}
            </TableBody>
        </Table>
    </TableContainer>
    </Aux>
  );
}

export default SeasonsViewPage;
