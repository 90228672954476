import React, { useEffect, useState } from 'react';
import axios from '../../../util/Axios/Axios';
import { Button, makeStyles, FormControlLabel, TextField, Checkbox, TableContainer, Table, Paper, TableHead, TableRow, TableCell, TableBody, IconButton } from '@material-ui/core';
import SimpleModal from '../../../components/modals/SimpleModal';
import DeleteIcon from '@material-ui/icons/Delete';
import { GreenButton } from '../../../components/buttons/GreenButton';

function EditQuestionModal(props) {

    const useStyles = makeStyles(theme => ({
        modalContainer : {
          width : '100%',
          display : 'flex',
          'flex-direction' : 'column',
          'justify-content' : 'center',
          'align-items' : 'center',
        },
        formInput : {
            width : "100%",
            marginTop : '16px',
        }
    }));
  
    const classes = useStyles();

    const [question, setQuestion] = useState("");
    const [orderNumber, setOrderNumber] = useState("");
    const [isOpen, setIsOpen] = useState(true);
    const [answers, setAnswers] = useState([]);

    useEffect(() => {
        if(props.question) {
            setQuestion(props.question.question);
            setOrderNumber(props.question.order_number);
            setIsOpen(props.question.is_open);
            setAnswers(props.question.answers);
        }
    }, [props.question]);

    const handleEditQuestion = () => {
        axios.put("survey/questions/"+props.question.id, {
            question : question,
            is_open : isOpen, 
            order_number : orderNumber,
            answers : answers
        })
        .then(response => {
            props.onClose(true);
        })
    }

    const handleEditAnswerText = (index, newText) => {
        const copiedAnsers = [...answers]
        copiedAnsers[index].answer = newText;
        setAnswers(copiedAnsers);
    }

    const handleDeleteAnswer = (index) => {
        let copiedAnsers = [...answers]
        copiedAnsers.splice(index, 1);
        copiedAnsers = copiedAnsers.map((answer, index) => {
            answer.order_number = index+1;
            return answer;
        })
        setAnswers(copiedAnsers);
    }

    const handleAddNewAnswer = () => {
        const copiedAnsers = [...answers]
        copiedAnsers.push(
            {
                answer : "",
                order_number : copiedAnsers.length+1
            },
        );
        setAnswers(copiedAnsers);
    }

    return (
        <SimpleModal open={props.open} onClose={()=> props.onClose(false)} >
            <div className={classes.modalContainer}>
                <h2>Нов въпрос</h2>
                <textarea
                    rows="5" 
                    cols="75"
                    value={question}
                    onChange={(event)=> setQuestion(event.target.value)}
                />
                <TextField
                    required
                    value={orderNumber}
                    onChange={(event)=> setOrderNumber(event.target.value)}
                    className={classes.formInput}
                    label="Пореден номер"
                    type="number"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            checked={isOpen}
                            onChange={()=> setIsOpen(!isOpen)}
                        />
                    }
                    label="Отворен въпрос"
                />
                {
                    !isOpen && (
                        <>
                            <h3>Отговори</h3>
                            <TableContainer component={Paper}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Пореден номер</TableCell>
                                            <TableCell>Отговор</TableCell>
                                            <TableCell >Изтриване</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {answers.map((answer, index) => (
                                        <TableRow key={index}>
                                            <TableCell >{answer.order_number}</TableCell>
                                            <TableCell >
                                                <TextField
                                                    required
                                                    value={answer.answer}
                                                    onChange={(event)=> handleEditAnswerText(index, event.target.value)}
                                                    className={classes.formInput}
                                                    label="Въведете отговор"
                                                    type="text"
                                                />
                                            </TableCell>
                                            <TableCell ><IconButton onClick={()=>handleDeleteAnswer(index)}><DeleteIcon/></IconButton></TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Button
                                className={classes.formInput} 
                                variant="contained" 
                                color="primary"
                                onClick={handleAddNewAnswer}>
                                    Добави отговор
                            </Button>  
                        </>
                    )
                }
                <GreenButton
                    className={classes.formInput} 
                    onClick={handleEditQuestion}>Редактирай въпрос
                </GreenButton>  
            </div>       
        </SimpleModal>
    );
}

export default EditQuestionModal;
