import React from 'react';
import { red } from '@material-ui/core/colors';
import { Checkbox, withStyles } from '@material-ui/core';

export const RedCheckBox = withStyles({
    root: {
      color: red[400],
      '&$checked': {
        color: red[600],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);