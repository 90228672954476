import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CandidateActionsRow from './CandidateActionsRow';
import clsx from 'clsx';

function CandidatesContractRow(props) {

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    }
  },
  green : {
    backgroundColor : 'lightgreen'
  }
});

  useEffect(()=>{
    setCandidate(props.candidate);//every time this component receives new props, we update the state
  },[props.candidate])

  const [candidate, setCandidate] = useState({});
  const [expandRow, setExpandRow] = useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow 
        className={clsx(classes.footer, {
          [classes.root]: true,
          [classes.green] : candidate.contract_documents_accepted
      })}
      key={candidate.id} onClick={() => setExpandRow(!expandRow)}>
        <TableCell>
          <IconButton aria-label="expand candidate" size="small" onClick={() => setExpandRow(!expandRow)}>
            {expandRow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            {props.order}
          </IconButton>
        </TableCell>
        <TableCell >{candidate.full_name}</TableCell>
        <TableCell >{candidate.email}</TableCell>
        <TableCell >{candidate.phone_number}</TableCell>
        <TableCell >{candidate.interviewer_name === null ? "N/A" : candidate.interviewer_name}</TableCell>
        <TableCell >{candidate.course_name === null ? "N/A" : candidate.course_name}</TableCell>
        <TableCell >{candidate.interview_desired_tech_1 === null ? "N/A" : candidate.interview_desired_tech_1 + (candidate.interview_desired_tech_2 === null ? "" : " / " +candidate.interview_desired_tech_2)}</TableCell>
        <TableCell >{candidate.recruitment_state}</TableCell>
      </TableRow>
      <CandidateActionsRow
        colSpan = {8}
        expandRow = {expandRow}
        candidate = {candidate}
        updateCandidate = {setCandidate}
      ></CandidateActionsRow>
    </React.Fragment>
  );
}

export default CandidatesContractRow;