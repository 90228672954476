import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Paper, Button, useMediaQuery } from '@material-ui/core';
import { BASE_URL } from '../../util/Constants';

function TestPreparationPage(props) {

    const mobile = !useMediaQuery('(min-width:600px)');
    
    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
        },
        paper: {
            width: mobile ? "95%" : "60%",
            margin : 'auto',
            padding: theme.spacing(4),
            color: theme.palette.text.secondприary,
        },
        formInput: {
          width: "100%",
          margin: "auto",
          height: "25px",
          textAlign : 'center',
        },
    }));

    const classes = useStyles();

    return (
        <div className={classes.root}>
            {
                true ? (
                <Paper className={classes.paper}>
                    <p>Целта на входните тестове е да провери и оцени логическия и аналитичен аспект на вашето мислене.</p>
                    <p>Тестовете се състоят от 4 различни модула.</p>
                    <ul>
                        <li>Модул 1 – Логически задачи</li>
                        <li>Модул 2 – Числови редици и пъзели</li>
                        <li>Модул 3 – Логическа последователност на фигури и картини</li>
                        <li>Модул 4 – Критично мислене</li>
                    </ul>
                    <p><strong>Важно!!! По-време на онлайн теста ще са ви нужни: лист хартия, химикал и калкулатор.</strong></p>
                    <Button
                                className={classes.formInput} 
                                variant="contained" 
                                color="primary"
                                onClick={()=> window.open(BASE_URL + '/uploads/test_sample.pdf', '_blank')}
                            >
                                Примерни въпроси
                            </Button>
                </Paper>
            ) :
            <CircularProgress />}
        </div>
    );

}

export default TestPreparationPage;