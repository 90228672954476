import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EmailIcon from '@material-ui/icons/Email';
import SmsIcon from '@material-ui/icons/SmsFailed';
import NotificationIcon from '@material-ui/icons/Notifications';
import { Tabs, AppBar, Tab } from '@material-ui/core';
import TabPanel from '../../../components/TabPanel';
import AutomaticEmailsPage from './AutomaticEmailsPage';
import {PRIMARY_BLUE} from '../../../util/Constants';
import AutomaticSMSPage from './AutomaticSMSPage';
import AutomaticStatusesPage from './AutomaticStatusesPage';

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

function AutomaticNotificationsPage(props) {

    const useStyles = makeStyles((theme) => ({
        root: {
          flexGrow: 1,
          textAlign : 'center',
          backgroundColor: theme.palette.background.paper,
        },
        tabpanel : {
            backgroundColor : PRIMARY_BLUE
        }
      }));
    
    const classes = useStyles();
    const [chosenTabId, setChosenTabId] = useState(0);

    const handleChange = (event, newValue) => {
        setChosenTabId(newValue);
    };

    return (
        <div className={classes.root}>

            <AppBar position="static" color="default">
                <Tabs 
                variant="fullWidth" 
                value={chosenTabId} 
                onChange={handleChange} 
                indicatorColor="primary"
                textColor="primary"
                aria-label="simple tabs example">
                    <Tab label="Мейли" icon={<EmailIcon />} {...a11yProps(0)} />
                    <Tab label="СМС-и" icon={<SmsIcon />} {...a11yProps(1)} />
                    <Tab label="Системни статуси" icon={<NotificationIcon />}  {...a11yProps(2)} />
                </Tabs>
            </AppBar>
            <TabPanel className={classes.tabpanel} value={chosenTabId} index={0}>
                <AutomaticEmailsPage></AutomaticEmailsPage>
            </TabPanel>
            <TabPanel className={classes.tabpanel} value={chosenTabId} index={1}>
                <AutomaticSMSPage></AutomaticSMSPage>
            </TabPanel>
            <TabPanel className={classes.tabpanel} value={chosenTabId} index={2}>
                <AutomaticStatusesPage></AutomaticStatusesPage>
            </TabPanel>
        </div>
    )
}

export default AutomaticNotificationsPage;