import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from '../../util/Axios/Axios';
import { CircularProgress, Paper } from '@material-ui/core';

function InboxPage(props) {

    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
        },
        paper: {
          padding: theme.spacing(4),
          textAlign: 'center',
          color: theme.palette.text.secondприary,
        },
    }));

    useEffect(()=>{
        getUserProfile()}, 
        //eslint-disable-next-line
    [])

    const classes = useStyles();
    const userId = props.userId;

    const getUserProfile = () => {
        axios.get('/users/'+userId)
        .then(response => {
            // const data = response.data.data.user;
        })
        .catch(error=>{
        });
    }

    return (
        <div className={classes.root}>
            {
                true ? (
                <Paper className={classes.paper}>
                    <h3>Нямате нови съобщения</h3>
                </Paper>
            ) :
            <CircularProgress />}
        </div>
    );

}

export default InboxPage;