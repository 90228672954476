import axios from '../../../util/Axios/Axios'
import { logIn } from '../login/loginActions';
import ReactPixel from 'react-facebook-pixel';


export const register = (email, password, password2, fullName, phoneNumber, dateOfBirth, onMobile) => {


    return dispatch =>{
      if(email && password && password2 && fullName && phoneNumber && dateOfBirth){
            axios.post('/users', {
                email : email,
                password : password,
                confirm_password : password2,
                full_name : fullName,
                date_of_birth : dateOfBirth,
                phone_number : phoneNumber,
            })
            .then(response => {
                ReactPixel.track('CompleteRegistration', email); 
                dispatch(logIn(email, password, onMobile));
            })
        }
    }
}