import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from '../../util/Axios/Axios';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, FormControl, InputLabel, Select, MenuItem, Typography, Grid, TextField, Button, TablePagination, Avatar, Chip } from '@material-ui/core';
import InterviewPage from './InterviewPage';
import { GreenButton } from '../../components/buttons/GreenButton';


function InterviewsListPage(props) {

    const filteredSeason = props.filteredSeason;

    const useStyles = makeStyles((theme) => ({
        root: {
          flexGrow: 1,
          textAlign : 'center',
          backgroundColor: theme.palette.background.paper,
        },
        filterInput : {
          width: "80%",
          margin: "auto",
          marginTop : '16px',
          marginBottom : '16px',
          height: "45px",
          textAlign : 'center',
        },
        interviewed : {
            backgroundColor : 'lightgreen'
        },
        chip : {
            margin : '8px',
        }
      }));

    const classes = useStyles();
    
    useEffect(() => {
        axios.get('/users/interviewers').then(response => setInterviewers(response.data.data));
        filterInterviews(filteredName, filteredPhone, filteredInterviewerId, filteredDate, filteredScore);
        //eslint-disable-next-line
    }, [props.filteredSeason]);
    
    const filterInterviews = (name, phone, interviewer_id, date, score) => {
        axios.post('/seasons/'+filteredSeason+'/interviews', {
            name,
            phone,
            interviewer_id,
            date,
            score
        })
        .then(response => {
            const interviews = response.data.data;
            setInterviews(interviews);
            let score_yes = 0;
            let score_no = 0;
            let score_maybe = 0;
            let score_drop_out = 0;
            interviews.forEach(interview => {
                switch(interview.score){
                    case 'ДА' :         score_yes++; break;
                    case 'НЕ' :         score_no++; break;
                    case 'МОЖЕ БИ' :    score_maybe++; break;
                    case 'ОТКАЗАЛ СЕ' : score_drop_out++; break;
                    default : 
                }
            });
            setScoreYes(score_yes);
            setScoreNo(score_no);
            setScoreMaybe(score_maybe);
            setScoreDropOut(score_drop_out);
            if(dates.length === 0){
                let dates_arr = [];
                interviews.forEach(interview => {
                    dates_arr.push(interview.start_time.split(' ')[0]);
                });
                dates_arr = dates_arr.filter((v, i, a) => a.indexOf(v) === i);
                setDates(dates_arr);
            }
            setPage(0);
        });
    }

    const [filteredName, setFilteredName] = useState("");
    const [filteredPhone, setFilteredPhone] = useState("");
    const [filteredInterviewerId, setFilteredInterviewerId] = useState("");
    const [filteredDate, setFilteredDate] = useState("");
    const [viewInterview, setViewInterview] = useState(false);
    const [viewedInterview, setViewedInterview] = useState(null);
    const [filteredScore, setFilteredScore] = useState("");
    const [scoreYes, setScoreYes] = useState(0);
    const [scoreNo, setScoreNo] = useState(0);
    const [scoreMaybe, setScoreMaybe] = useState(0);
    const [scoreDropOut, setScoreDropOut] = useState(0);

    const [dates, setDates] = useState([]);
    const [interviewers, setInterviewers] = useState([]);
    const [interviews, setInterviews] = useState([]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 50));
      setPage(0);
    };

    return (
        <div className={classes.root}>
            <Typography variant="h5">
                График на интервюта за подбор
            </Typography>
            <Grid container>
                <Grid item xs={4}>
                <TextField
                    label="Име"
                    className = {classes.filterInput}
                    value={filteredName}
                    onChange={(event)=> {setFilteredName(event.target.value); filterInterviews(event.target.value, filteredPhone, filteredInterviewerId, filteredDate, filteredScore)}}
                    />
                </Grid>
                <Grid item xs={4}>
                <TextField
                    label="Телефон"
                    className = {classes.filterInput}
                    value={filteredPhone}
                    onChange={(event)=> {setFilteredPhone(event.target.value); filterInterviews(filteredName, event.target.value, filteredInterviewerId, filteredDate, filteredScore)}}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControl className = {classes.filterInput}>
                        <InputLabel>Интервюиращ</InputLabel>
                        <Select
                            value={filteredInterviewerId}
                            onChange={(event)=> {setFilteredInterviewerId(event.target.value); filterInterviews(filteredName, filteredPhone, event.target.value, filteredDate, filteredScore)}}
                    
                        >
                            <MenuItem key={""} value={""}>Всички</MenuItem>
                        {
                            interviewers.map(interviewer => (
                                    <MenuItem key={interviewer.id} value={interviewer.id}>{interviewer.full_name}</MenuItem>
                                )
                            )   
                        }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className = {classes.filterInput}>
                            <InputLabel>Дата</InputLabel>
                            <Select
                                value={filteredDate}
                                onChange={(event)=> {setFilteredDate(event.target.value); filterInterviews(filteredName, filteredPhone, filteredInterviewerId, event.target.value, filteredScore)}}
                        
                            >
                                <MenuItem key={""} value={""}>Всички</MenuItem>
                            {
                                dates.map(date => (
                                        <MenuItem key={date} value={date}>{date}</MenuItem>
                                    )
                                )   
                            }
                            </Select>
                        </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className = {classes.filterInput}>
                        <InputLabel>Резултат</InputLabel>
                        <Select
                            value={filteredScore}
                            onChange={(event)=> {setFilteredScore(event.target.value); filterInterviews(filteredName, filteredPhone, filteredInterviewerId, filteredDate, event.target.value)}}
                    
                        >
                            <MenuItem key={""} value={""}>Всички</MenuItem>
                            <MenuItem key={"ДА"} value={"ДА"}>ДА</MenuItem>
                            <MenuItem key={"НЕ"} value={"НЕ"}>НЕ</MenuItem>
                            <MenuItem key={"МОЖЕ БИ"} value={"МОЖЕ БИ"}>МОЖЕ БИ</MenuItem>
                            <MenuItem key={"ОТКАЗАЛ СЕ"} value={"ОТКАЗАЛ СЕ"}>ОТКАЗАЛ СЕ</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} >
                    <Chip className={classes.chip} avatar={<Avatar>{interviews.length}</Avatar>} label="Общо"/>
                    <Chip className={classes.chip} avatar={<Avatar>{scoreYes}</Avatar>} label="ДА" color="primary"/>
                    <Chip className={classes.chip} avatar={<Avatar>{scoreNo}</Avatar>} label="НЕ" color="secondary"/>
                    <Chip className={classes.chip} avatar={<Avatar>{scoreMaybe}</Avatar>} label="МОЖЕ БИ"/>
                    <Chip className={classes.chip} avatar={<Avatar>{scoreDropOut}</Avatar>} label="ОТКАЗАЛ СЕ" color="secondary"/>
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table aria-label="simple table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Слот</TableCell>
                            <TableCell>Имена</TableCell>
                            <TableCell>Телефон</TableCell>
                            <TableCell>Скайп</TableCell>
                            <TableCell>Интервюиращ</TableCell>
                            <TableCell>Резултат</TableCell>
                            <TableCell  align="center">Интервю</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {interviews.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(interview => (
                        <TableRow key={interview.candidate_id} className={interview.score !== null ? classes.interviewed : ""}>
                            <TableCell >{interview.start_time} - {interview.end_time.split(' ')[1]}</TableCell>
                            <TableCell >{interview.full_name}</TableCell>
                            <TableCell >{interview.phone_number}</TableCell>
                            <TableCell >{interview.skype_username}</TableCell>
                            <TableCell >{interview.interviewer}</TableCell>
                            <TableCell >{interview.score ? interview.score : "N/A"}</TableCell>
                            <TableCell align="center">
                                {
                                    interview.score !== null ? (
                                        <Button 
                                            variant="contained" 
                                            color="primary"
                                            component="span" 
                                            onClick={ () =>{
                                                setViewedInterview(interview);
                                                setViewInterview(true);
                                                }} >
                                                Преглед
                                        </Button>
                                    ) : (
                                        <GreenButton
                                        onClick={ () =>{
                                            setViewedInterview(interview);
                                            setViewInterview(true);
                                            }} >
                                            Започни
                                        </GreenButton>
                                    )
                                }
                            
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={interviews.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            {
                viewedInterview && (
                    <InterviewPage
                        interview={viewedInterview}
                        season={filteredSeason}
                        open={viewInterview} 
                        onClose={(refresh)=> {
                            setViewInterview(false); 
                            setViewedInterview(null);
                            if(refresh){ 
                                filterInterviews(filteredName, filteredPhone, filteredInterviewerId, filteredDate, filteredScore);
                            }
                        }}
                    >
                    </InterviewPage>
                )
            }
        </div>
    )
}

export default InterviewsListPage;