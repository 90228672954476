import React, { useEffect, useState } from 'react';
import { Paper, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, makeStyles, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import axios from '../../util/Axios/Axios'
import SimpleModal from '../../components/modals/SimpleModal';
import { GreenButton } from '../../components/buttons/GreenButton';
import { GreenCheckbox } from '../../components/checkboxes/GreenCheckBox';
import { useSelector } from 'react-redux';
import { USER_ROLE_ADMINISTRATOR_ID } from '../../util/Constants';
import { RedButton } from '../../components/buttons/RedButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

function ChooseInterviewSlotModal(props) {

    const useStyles = makeStyles(theme => ({
        root: {
          width: "100%",
          margin: "auto",
          display: "flex",
          textAlign : 'center',
          "flex-direction": "column",
          "justify-content" : "flex-start",
    
        },
        formInput: {
          width: "100%",
          margin: "auto",
          marginTop : '16px',
          marginBottom : '16px',
          height: "45px",
          textAlign : 'center',
        },
        red : {
            color : 'red',
        },
        hidden : {
            display : "none"
        }
    }));
    
    useEffect(()=>{
        axios.get('/seasons/'+props.seasonId+'/interview/slots')
        .then(response => {
            const all_slots = response.data.data;
            let grouped_slots = {};
            all_slots.forEach(slot => {
                const slot_start_date = slot.start_time.split(' ')[0];
                if(!grouped_slots[slot_start_date]){ 
                    grouped_slots[slot_start_date] = []; 
                }
                grouped_slots[slot_start_date].push(slot);
            });
            setSlots(grouped_slots);
        })
    },
        //eslint-disable-next-line
    [props.chosen])

    const classes = useStyles();
    const [slots, setSlots] = useState([]);
    const [selected, setSelected] = useState(props.chosen);
    const [expanded, setExpanded] = useState(false);
    
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const userRoleId = useSelector(state => state.loginReducer.userRoleId);

    const isSelected = (id) => selected && selected.id === id;

    const chooseSlotHandler = () => {
        setSelected(selected);
        props.onChooseSlot(selected);
    }
    
    const clearSlotHandler = () => {
        setSelected(null);
        props.onClearSlot();
    }

    const onCloseHandler = () => {
        setSelected(props.chosen);
        props.onClose();
    }

    return (
        <SimpleModal open={props.open} onClose={onCloseHandler} >
            <div className={classes.root}>
                <Typography variant="h5">
                    Изберете часови интервал за провеждане на интервюто:
                </Typography>
                <p className={classes.red}>
                   ВАЖНО! Веднъж избрана, датата не може да бъде променена!
                </p>
                {
                    Object.keys(slots).map((day, index ) => (
                        <Accordion expanded={expanded === 'panel'+day} onChange={handleChange('panel'+day)} key={day}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography className={classes.heading}><strong>{day}</strong></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                        <TableRow>
                                            <TableCell>Начало</TableCell>
                                            <TableCell>Край</TableCell>
                                            <TableCell className={userRoleId !== USER_ROLE_ADMINISTRATOR_ID ? classes.hidden : ""}>Общо места</TableCell>
                                            <TableCell className={userRoleId !== USER_ROLE_ADMINISTRATOR_ID ? classes.hidden : ""}>Заети места</TableCell>
                                            <TableCell>Свободни места</TableCell>
                                            <TableCell>Избери</TableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {Object.keys(slots[day]).map(index => {
                                            const slot = slots[day][index];
                                            const isItemSelected = isSelected(slot.id);
                                            return (
                                            <TableRow 
                                            key={slot.id}
                                            onClick={() => setSelected(slot)}
                                            selected={isItemSelected}
                                            className={(slot.max_slots - slot.slots_taken <= 0) && (userRoleId !== USER_ROLE_ADMINISTRATOR_ID) ? classes.hidden : ""}
                                            >
                                                <TableCell >{slot.start_time.split(' ')[1]}</TableCell>
                                                <TableCell >{slot.end_time.split(' ')[1]}</TableCell>
                                                <TableCell className={userRoleId !== USER_ROLE_ADMINISTRATOR_ID ? classes.hidden : ""}>{slot.max_slots}</TableCell>
                                                <TableCell className={userRoleId !== USER_ROLE_ADMINISTRATOR_ID ? classes.hidden : ""}>{slot.slots_taken}</TableCell>
                                                <TableCell >{slot.max_slots - slot.slots_taken}</TableCell>
                                                <TableCell >
                                                {
                                                    (slot.max_slots - slot.slots_taken > 0) ? (
                                                        <GreenCheckbox
                                                            checked={isItemSelected}
                                                            onChange={()=>{
                                                                if(!selected || selected.id !== slot.id){
                                                                    setSelected(slot);
                                                                }
                                                                else{
                                                                    setSelected(null);
                                                                }
                                                            }}
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        />
                                                    ) : (
                                                        <Typography className={classes.red}>Няма места</Typography>
                                                    )
                                                }
                                                </TableCell>
                                            </TableRow>
                                            )
                                        })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                ))
                }
                
                <GreenButton
                    className={classes.formInput} 
                    variant="contained" 
                    color="primary"
                    disabled={selected === null}
                    onClick={()=> chooseSlotHandler()}
                >
                    Запази дата за интервю
                </GreenButton>  
                {
                    userRoleId === USER_ROLE_ADMINISTRATOR_ID ? (
                        <RedButton
                            className={classes.formInput} 
                            variant="contained" 
                            color="primary"
                            disabled={selected === null}
                            onClick={()=> clearSlotHandler()}
                        >
                            Отпиши кандидата от слот
                        </RedButton> 
                    ) : ""
                }
            </div>
        </SimpleModal>
    );

}

export default ChooseInterviewSlotModal;