
// export const BASE_URL = 'https://itt-dev1.imperiax.org/api/';
export const BASE_URL = 'https://itt-prod1.imperiax.org/api/';
// export const BASE_URL = 'http://localhost:80';
export const TOAST_SHOW_DURATION = 3000;
export const MENU_WIDTH = 240;
export const HEADER_HEIGHT = 100;
export const FOOTER_HEIGHT = 100;

export const TEST_NOT_TAKEN_SCORE = 'неявил се';

export const USER_ROLE_ADMINISTRATOR_ID = '1';
export const USER_ROLE_HR_ID = '4';

export const COMPANIES_STATUS_GOLD = 'GOLD';
export const COMPANIES_STATUS_GOLD_NAME = 'Основен партньор';
export const COMPANIES_STATUS_SILVER = 'SILVER';
export const COMPANIES_STATUS_SILVER_NAME = 'Партньор';

export const CANDIDATE_STATUS_PENDING  = 'Приета кандидатура';
export const CANDIDATE_STATUS_REJECTED = 'Отхвърлен';
export const CANDIDATE_STATUS_ONLINE_TEST_ADMITTED = 'Допуснат до онлайн тест';
export const CANDIDATE_STATUS_ONLINE_TEST_DROPPED = 'Отпаднал от онлайн тест';
export const CANDIDATE_STATUS_ATTENDANCE_TEST_ADMITTED = 'Допуснат до присъствен тест';
export const CANDIDATE_STATUS_ATTENDANCE_TEST_DROPPED = 'Одпаднал от присъствен тест';
export const CANDIDATE_STATUS_INTERVIEW_ADMITTED = 'Допуснат до интервю';
export const CANDIDATE_STATUS_INTERVIEW_DROPPED = 'Отпаднал от интервю';
export const CANDIDATE_STATUS_CONTRACT_ADMITTED = 'Допуснат до подписване на договор';

export const PRIMARY_BLUE = '#2B3C53';
export const SMS_MAX_LENGTH = 200;

//notifications receiver groups
export const GROUP_ONLINE_TEST_ADMITTED = -1;
export const GROUP_ATTENDANCE_TEST_ADMITTED = -2;
export const GROUP_ATTENDANCE_TEST_ADMITTED_WITH_SLOT = -3;//SLOT ID IS APPENDED TO THIS ID IN QUERIES
export const GROUP_ATTENDANCE_TEST_ADMITTED_WITHOUT_SLOT = -4;
export const GROUP_INTERVIEW_ADMITTED = -5;
export const GROUP_INTERVIEW_ADMITTED_WITH_SLOT = -6;//SLOT ID IS APPENDED TO THIS ID IN QUERIES
export const GROUP_INTERVIEW_ADMITTED_WITHOUT_SLOT = -7;
export const GROUP_CONTRACTS_ADMITTED = -8;
export const GROUP_CONTRACTS_ADMITTED_WITH_SLOT = -9;//SLOT ID IS APPENDED TO THIS ID IN QUERIES
export const GROUP_CONTRACTS_ADMITTED_WITHOUT_SLOT = -10;
export const GROUP_IN_COURSES = -11;
export const GROUP_IN_COURSE_GROUP = -12;//COURSE ID IS APPENDED TO THIS ID IN QUERIES
export const GROUP_IDS_SEPARATOR = "_";